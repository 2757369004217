import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return $props.show ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    style: {
      "position": "fixed",
      "width": "100vw",
      "height": "100vh",
      "z-index": "999",
      "left": "0",
      "top": "0"
    },
    onClick: _cache[0] || (_cache[0] = _withModifiers($event => _ctx.$emit('update:show', false), ["self"]))
  }, [_createElementVNode("div", {
    ref: "floatMenu",
    style: _normalizeStyle({
      position: 'absolute',
      left: $options.left + 'px',
      top: $options.top + 'px'
    }),
    class: "float-menu"
  }, [_renderSlot(_ctx.$slots, "default")], 4)])) : _createCommentVNode("", true);
}