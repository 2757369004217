import templateStatusShow from './templateStatusShow.vue';
export default {
  components: {
    templateStatusShow
  },
  props: {
    isCheckBox: {
      type: Boolean,
      default: false
    },
    listIndex: {
      type: Number,
      default: null
    },
    dpt: {
      type: Object,
      default: {}
    },
    userIsView: {
      type: Boolean,
      default: false
    },
    submitForm: {
      type: Array,
      default: () => {
        return [];
      }
    },
    damageReportDir: {
      type: Array,
      default: () => {
        return [];
      }
    },
    damageRTextL: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },

  data() {
    return {
      //用户是否填写
      select: '',
      selects: [],
      status: '',
      view: false,
      Edit: false,
      answerObj: {
        id: null,
        contentItemId: "",
        url: "",
        content: ""
      },
      idString: "",
      selectIndex: null,
      selectsIndex: [],
      isShow: false,
      dataInfo: {}
    };
  },

  watch: {},
  computed: {},

  created() {
    //保存状态传给状态组件
    // this.status = this.dpt.status
    // console.log(this.status, 'this.status ');
    // console.log(this.dpt.tempItemContentList[this.listIndex - 1].answerVo.id, 'this.d000000');
    this.dataInfo = JSON.parse(JSON.stringify(this.dpt.tempItemContentList[this.listIndex - 1]));

    if (this.dataInfo.isEssential) {
      this.dataInfo.isEssential = true;
    } else {
      this.dataInfo.isEssential = false;
    }

    this.dataInfo.checkList = [];
    this.dataInfo.radio = null;

    if (!this.dataInfo.itemList.length) {
      let firstObj = {
        optionName: ''
      };
      this.dataInfo.itemList.push(firstObj);
    } //判断是否能对页面操作


    this.Edit = (this.dpt.status === '1' || this.dpt.status === '3') && !this.userIsView; //判断是否填写完毕 决定是否回填答案

    if (!this.Edit) {
      if (this.dataInfo.answerVo.contentItemId && this.dataInfo.answerVo.contentItemId != 'null') {
        if (this.isCheckBox) {
          let selList = JSON.parse(this.dataInfo.answerVo.contentItemId).split(';').slice(0, -1);

          for (let i of selList) {
            for (let x in this.dataInfo.itemList) {
              if (Number(i) === this.dataInfo.itemList[x].id) {
                let obj = {
                  optionName: this.dataInfo.itemList[x].optionName
                };
                this.selects.push(obj);
                break;
              }
            }
          } // console.log(this.selectsIndex, 'selList111');

        } else {
          for (let i of this.dataInfo.itemList) {
            if (this.dataInfo.answerVo.contentItemId === String(i.id)) {
              this.select = i.optionName; // console.log(this.selectIndex, 'this.selectIndex');

              return;
            }
          } // console.log(this.selectIndex, 'selList222');
          // this.dpt.tempItemContentList[this.listIndex - 1].answerVo.contentItemId

        } // console.log('23333');

      }
    } else {
      //判断用户是否填写 未填写不必回显
      if (this.dataInfo.answerVo.contentItemId && this.dataInfo.answerVo.contentItemId != 'null') {
        if (this.isCheckBox) {
          let selList = JSON.parse(this.dataInfo.answerVo.contentItemId).split(';').slice(0, -1);

          for (let i of selList) {
            for (let x in this.dataInfo.itemList) {
              if (Number(i) === this.dataInfo.itemList[x].id) {
                this.selectsIndex[x] = true;
                break;
              }
            }
          } // console.log(selectsIndex, 'selList111');

        } else {
          for (let i of this.dataInfo.itemList) {
            if (this.dataInfo.answerVo.contentItemId === String(i.id)) {
              this.selectIndex = i.id; // console.log(this.selectIndex, 'this.selectIndex');

              return;
            }
          } // console.log(selectIndex, 'selList222');
          // this.dpt.tempItemContentList[this.listIndex - 1].answerVo.contentItemId

        }
      }
    }
  },

  beforeUpdate() {// }
  },

  mounted() {
    if (this.dpt.status == '3' || this.dpt.status == '1' && !this.userIsView) {
      this.autoSave();
    } // // console.log(this.userIsView,'wewewe');
    // if(this.isCheckBox){
    // }else{
    //     console.log(selectIndex,'selectIndexselectIndex');
    // }
    //

  },

  methods: {
    //重新填报进来 先回显答案 然后自动存储一次答案到总页面
    autoSave() {
      if (this.isCheckBox) {
        this.idString = '';

        for (let i in this.selectsIndex) {
          if (this.selectsIndex[i]) {
            this.idString += this.dataInfo.itemList[i].id + ';';
          }
        }

        this.answerObj.id = this.dpt.tempItemContentList[this.listIndex - 1].answerVo.id;
        this.answerObj.contentItemId = JSON.stringify(this.idString); // this.$eventBus.emit('saveData', { answerObj: this.answerObj, index: this.listIndex - 1 })

        this.submitForm[this.listIndex - 1] = this.answerObj;
      } else {
        this.answerObj.id = this.dpt.tempItemContentList[this.listIndex - 1].answerVo.id;
        this.answerObj.contentItemId = this.selectIndex; // this.$eventBus.emit('saveData', { answerObj: this.answerObj, index: this.listIndex - 1 })

        this.submitForm[this.listIndex - 1] = this.answerObj;
      } // console.log('emitemitemit');

    },

    //根据状态返回不同的class
    getClassByStatus(status) {
      if (status == '1') {
        return 'col_rred';
      } else if (status == '2') {
        return 'col_ggreen';
      }
    },

    //提交问题
    // submit() {
    //     if (this.isCheckBox) {
    //     } else {
    //     }
    // },
    //点击选择选项
    clickOption(index, item) {
      if (this.Edit) {
        if (this.isCheckBox) {
          this.idString = '';
          this.selectsIndex[index] = !this.selectsIndex[index];

          for (let i in this.selectsIndex) {
            if (this.selectsIndex[i]) {
              this.idString += this.dataInfo.itemList[i].id + ';';
            }
          }

          if (this.idString) {
            this.answerObj.id = this.dpt.tempItemContentList[this.listIndex - 1].answerVo.id;
            this.answerObj.contentItemId = JSON.stringify(this.idString);
            this.submitForm[this.listIndex - 1] = this.answerObj;
          } else {
            //10.9 更改
            // this.submitForm[this.listIndex - 1] = null
            this.answerObj.contentItemId = JSON.stringify(this.idString);
            this.submitForm[this.listIndex - 1] = this.answerObj; //10.9 
          } // console.log(this.submitForm,'this.submitForm');
          // this.$eventBus.emit('saveData', { answerObj: this.answerObj, index: this.listIndex - 1 })

        } else {
          this.selectIndex = item.id;
          this.answerObj.id = this.dpt.tempItemContentList[this.listIndex - 1].answerVo.id;
          this.answerObj.contentItemId = this.selectIndex;
          this.submitForm[this.listIndex - 1] = this.answerObj; // console.log(this.submitForm,'this.submitForm');
          // this.$eventBus.emit('saveData', { answerObj: this.answerObj, index: this.listIndex - 1 })
          // this.$emit('update:submitForm',)
        }
      }
    }

  }
};