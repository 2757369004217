import Catalog from '../components/Catalog2.vue';
import MoblieTicket from '../control/MoblieTicket.vue';
export default {
  mixins: [Catalog],

  data() {
    return {
      itemSelected: {}
    };
  },

  components: {
    MoblieTicket
  },
  computed: {},

  mounted() {},

  methods: {}
};