import { mapState } from 'vuex';
import Home from './Home.vue';
export default {
  data() {
    return {};
  },

  components: {
    Home
  },
  computed: { ...mapState({
      user: 'user',
      userType: 'userType'
    }),

    isAdminLogin() {
      return !_.isEmpty(this.user) && this.userType === 'admin';
    },

    activeComponent() {
      return this.$store.state.component;
    }

  },

  beforeMount() {
    this.$store.commit('changeBase', 'ChatBase');
    this.$store.commit('changeUserType', 'admin');
  },

  mounted() {},

  methods: {}
};