import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  style: {
    "display": "flex",
    "flex-direction": "row",
    "width": "100%"
  }
};
const _hoisted_2 = {
  style: {
    "width": "50%"
  }
};
const _hoisted_3 = {
  style: {
    "display": "flex",
    "justify-content": "space-between",
    "width": "100%",
    "padding": "0 1rem 0 0"
  }
};
const _hoisted_4 = {
  style: {
    "width": "50%"
  }
};
const _hoisted_5 = {
  style: {
    "display": "flex",
    "justify-content": "space-between",
    "width": "100%",
    "padding": "0 1rem 0 0"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_tooltip = _resolveComponent("el-tooltip");

  const _component_el_tree = _resolveComponent("el-tree");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_tree, {
    ref: "treeList",
    "node-key": "id",
    "highlight-current": "",
    accordion: "",
    data: $data.treeListData,
    props: $data.defaultProps,
    onNodeClick: $options.clickColumnData
  }, {
    default: _withCtx(({
      node,
      data
    }) => [_createElementVNode("div", _hoisted_3, [_createVNode(_component_el_tooltip, {
      content: data.name,
      placement: "left",
      effect: "customized",
      "show-after": 500,
      enterable: false
    }, {
      default: _withCtx(() => [_createElementVNode("p", null, _toDisplayString(data.name), 1)]),
      _: 2
    }, 1032, ["content"])])]),
    _: 1
  }, 8, ["data", "props", "onNodeClick"])]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_el_tree, {
    style: {
      "flex": "1"
    },
    ref: "questionListRef",
    "node-key": "id",
    "highlight-current": "",
    data: $options.questionList,
    props: $data.defaultProps,
    onNodeClick: $options.clickQuestionList
  }, {
    default: _withCtx(({
      node,
      data
    }) => [_createElementVNode("div", _hoisted_5, [_createVNode(_component_el_tooltip, {
      content: data.title,
      placement: "left",
      effect: "customized",
      "show-after": 500,
      enterable: false
    }, {
      default: _withCtx(() => [_createElementVNode("p", null, _toDisplayString(data.title), 1)]),
      _: 2
    }, 1032, ["content"])])]),
    _: 1
  }, 8, ["data", "props", "onNodeClick"])])]);
}