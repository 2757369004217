import Home from '../../../components/user/ChatBase/Home2.vue';
import MoblieUpload from '../../../control/MoblieUpload.vue';
import MoblieScorePanel from '../../../control/MoblieScorePanel.vue';
import templateDemoEdit from '../../../control/MoblieTemplateDemo/templateDemoEdit.vue';
import templateTicket from '@/control/templateTicket.vue';
export default {
  mixins: [Home],

  data() {
    return {
      ticketListExpanded: false,
      isToolOpen: false
    };
  },

  components: {
    MoblieUpload,
    MoblieScorePanel,
    templateDemoEdit,
    templateTicket
  },
  computed: {
    questionRecordFilter() {
      // setTimeout(()=>{
      // })
      let questionRecord = this.questionRecordSort;

      if (!this.ticketListExpanded && this.questionRecordId) {
        if (this.questionRecordById[this.questionRecordId]) {
          questionRecord = [this.questionRecordById[this.questionRecordId]];
        }
      }

      return questionRecord;
    },

    chatHeight() {
      let height = 10.5;

      if (this.ticketListExpanded) {
        height = height + 5;
      }

      if (this.isToolOpen) {
        height = height + 3;
      }

      return `calc(100vh - ${height}rem)`;
    }

  },

  mounted() {// var cusDom = document.getElementsByClassName('page-custservi')[0]
    // var originalHeight = cusDom.clientHeight
    // window.onresize = () => {
    //   var curHeight = cusDom.clientHeight
    //   if (curHeight - curHeight > 50) {
    //     cusDom.clientHeight = originalHeight + 'px'
    //   } else {
    //   }
    // }
  },

  methods: {
    clickTicketListExpanded(state) {
      this.ticketListExpanded = !this.ticketListExpanded;

      if (state !== undefined) {
        this.ticketListExpanded = state;
      }

      if (this.ticketListExpanded) {
        this.$nextTick(() => {
          const activeQuestionRecord = document.getElementsByClassName('activity')[0];
          activeQuestionRecord && activeQuestionRecord.scrollIntoView(true);
        });
        this.isToolOpen = false;
      }
    },

    clickToolOpen(state) {
      this.isToolOpen = !this.isToolOpen;

      if (state !== undefined) {
        this.isToolOpen = state;
      }

      if (this.isToolOpen) {
        this.ticketListExpanded = false;
      }
    },

    focusSendContent(e) {
      this.$nextTick(() => {
        e.currentTarget.scrollIntoView(false);
      });
    }

  }
};