import axios from './plugins/axios'
import '@babel/polyfill'
import 'mutationobserver-shim'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import { createApp } from 'vue'
import moment from 'moment'
import 'moment-timezone';
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import VueQuillEditor from './plugins/editor/vue-quill-editor'
import cookie from './plugins/cookie'
import VueClipboard from 'vue-clipboard2'
import ElementPlus from 'element-plus'
import eventBus from 'vue3-eventbus'
import localStorageDirective from '../src/directives/localStorageDirective'
import locale from 'element-plus/lib/locale/lang/zh-cn'

import '../public/index.css'
import * as ElIcon from '@element-plus/icons-vue'
// import 'element-plus/dist/index.css'
import './assets/icon-third/index.css'
import './assets/style/index.css'
// vant(手机端)
import vant from 'vant'
import 'vant/lib/index.css'

// import erp_filter_com from 'erp_filter_com'

VueClipboard.config.autoSetContainer = true

const app = createApp(App).use(cookie)

// 聚焦输入框指令
app.directive('focus', {
  // 当被绑定的元素挂载到 DOM 中时……
  mounted(el) {
    // 聚焦元素
    el.focus()
  }
})
// 封装i18n的$t函数,方便在对应组件调用时减少参数字数。
const _t = function (content) {
  var arr = []
  // var base = this.$store.state.base
  // var component = this.$store.state.component
  // if (base != 'components') {
  //   arr.push(this.$store.state.userType)
  //   arr.push(base)
  // }
  // arr.push(component)
  arr.push(content)
  return this.$t(arr.join('.'))
}

Object.keys(ElIcon).forEach((key) => {
  app.component(`el-icon-${key}`, ElIcon[key])
})

window.STORE_SERVER = process.env.STORE_SERVER;
window.FAQ_WEB = process.env.FAQ_WEB;

app.config.globalProperties._t = _t
app.directive('localStorage', localStorageDirective)
// 时间格式插件
app.config.globalProperties.$moment = moment

async function init() {
  try {
    const config = await fetch('/config.js')

    const data = await config.text();
    eval(data);
    window.VUE_APP_I18N_LOCALE = window.config.VUE_APP_I18N_LOCALE;
    window.VUE_APP_I18N_FALLBACK_LOCALE = window.config.VUE_APP_I18N_FALLBACK_LOCALE;
  } catch (error) {
    window.VUE_APP_I18N_LOCALE = process.env.VUE_APP_I18N_LOCALE;
    window.VUE_APP_I18N_FALLBACK_LOCALE = process.env.VUE_APP_I18N_FALLBACK_LOCALE;
  }
  i18n.global.locale = window.VUE_APP_I18N_LOCALE || 'en';
  i18n.global.fallbackLocale = window.VUE_APP_I18N_FALLBACK_LOCALE || 'en';

  app.use(store).use(eventBus)
  app.use(ElementPlus,{locale}).use(vant).use(i18n).use(router).use(axios).use(VueClipboard).use(VueQuillEditor).mount('#app')
}

init();