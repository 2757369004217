import templateUploadPreview from './templateDemeUploadEdit.vue';
import templateRadioPreview from './templateDemeRadioEdit.vue';
import templateTextAreaPreview from './templateDemeTextAreaEdit.vue';
import { ElNotification, ElMessage, ElMessageBox } from 'element-plus';
import { Toast, Notify } from 'vant';
import { onMounted, onBeforeUnmount, getCurrentInstance } from 'vue';
export default {
  components: {
    templateUploadPreview,
    templateRadioPreview,
    templateTextAreaPreview
  },
  props: {
    dpt: {
      type: Object,
      default: () => {
        return {};
      }
    },
    userView: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    functionTemple: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },

  data() {
    return {
      //预设填报提示信息
      damageRTextL: {},
      damageReportDir: [],
      checkConclusionDir: [],
      purchasingDir: [],
      warrantyTypeDir: [],
      userIsView: false,
      submitForm: []
    };
  },

  watch: {},
  computed: {},
  methods: {
    //时间戳转化为 年月日 时分秒 格式化
    dataTimeFormatter(timeStamp) {
      if (timeStamp === 'null' || !timeStamp) {
        return 'null';
      }

      timeStamp = Number(timeStamp);
      let time = new Date(timeStamp);
      let y = time.getFullYear(); // 年份

      let M = time.getMonth() + 1 >= 10 ? time.getMonth() + 1 : '0' + (time.getMonth() + 1);
      ; // 月份（注意月份是从0开始的，所以需要+1）

      let d = time.getDate() >= 10 ? time.getDate() : '0' + time.getDate(); // 日期

      let h = time.getHours() >= 10 ? time.getHours() : '0' + time.getHours(); // 小时

      let m = time.getMinutes() >= 10 ? time.getMinutes() : '0' + time.getMinutes(); // 分钟

      let s = time.getSeconds() >= 10 ? time.getSeconds() : '0' + time.getSeconds(); // 秒数

      return y + '-' + M + '-' + d + ' ' + h + ':' + m + ':' + s;
    },

    //[FAQ]报损单表单
    async damageR(params) {
      const res = await this.$axios.post('/javaplus/user/form/damage', params);

      if (res.data.code != 200) {
        ElNotification({
          title: '错误',
          message: res.data.message,
          type: 'error'
        });
      }

      return res;
    },

    //获取数据字典
    async getDictListByDictType(params) {
      const res = await this.$axios.post('/api/user/damageReport/getDictListByDictType', params);

      if (res.data.errno != 0) {
        ElNotification({
          title: '错误',
          message: res.data.message,
          type: 'error'
        });
      }

      return res;
    },

    //根据status返回不一样的文本
    getByStatus(status, dir, type) {
      for (let d of dir) {
        if (status == d.dictValue) {
          return d[`${type}`];
        }
      }
    },

    ///[SHOP]填写报损单问题答案
    async editDamageReport(params) {
      const res = await this.$axios.post('/api/user/damageReport/editDamageReport', params); // debugger

      if (res.data.errno != 0) {
        // debugger
        Notify({
          type: 'danger',
          message: res.data.errmsg
        }); // Toast('我是提示文案，建议不超过十五字~');
        // Toast.loading({
        //     message: '加载中...',
        //     forbidClick: true,
        // });
        // ElNotification({
        //     title: '错误',
        //     message:'sdasdas',
        //     type: 'error',
        // });
        // debugger
        // this.$message.error(`1212131`)
        // ElMessage.error(`${res.data.errmsg}`)
      } else {
        this.functionTemple.returnPreView();
      }

      return res;
    },

    //teleport 的剪辑报损单填报项目传送
    teleport() {
      this.$refs.dptEdit.scrollIntoView({
        behavior: 'smooth',
        // 平滑滚动
        block: 'start' // 滚动到元素的顶部

      });
    },

    cancel() {
      // this.$eventBus.emit('returnPreView')
      this.functionTemple.returnPreView();
    },

    nextStep() {
      this.$nextTick(() => {
        let formIndex = 0; // for (let i of this.submitForm) {
        //     if (!this.submitForm[formIndex]) {
        //         if (Number(this.dpt.tempItemContentList[formIndex].questionType) < 20) {
        //             this.$message.error(`${this._t('请选择')}` + this.dpt.tempItemContentList[formIndex].title)
        //             return
        //         } else if (Number(this.dpt.tempItemContentList[formIndex].questionType) < 30) {
        //             this.$message.error(this._t('请输入') + this.dpt.tempItemContentList[formIndex].title)
        //             return
        //         } else if (Number(this.dpt.tempItemContentList[formIndex].questionType) < 40) {
        //             this.$message.error(this._t('请') + this._t(`${this.dpt.tempItemContentList[formIndex].questionTypeName}`) + this.dpt.tempItemContentList[formIndex].itemList[0].name)
        //             return
        //         }
        //     } else {
        //         if (30 << Number(this.dpt.tempItemContentList[formIndex].questionType) < 40) {
        //             let list_1 = JSON.parse(this.submitForm[formIndex].url).split(';').slice(0, -1)
        //             for (let item of this.dpt.tempItemContentList[formIndex].itemList) {
        //                 for (let urlItem of list_1) {
        //                     let urlId = urlItem.split(':')
        //                     if (urlId[1] == item.id) {
        //                         if (urlId[0]) {
        //                         } else {
        //                             this.$message.error(this._t('请') + this._t(`${this.dpt.tempItemContentList[formIndex].questionTypeName}`) + item.name)
        //                             return
        //                         }
        //                     }
        //                 }
        //             }
        //         }
        //     }
        //     formIndex++
        // }
        // console.log(this.submitForm, 'sunform');

        const obj = {
          data: this.submitForm,
          timestamp: Date.now()
        };
        this.editDamageReport(obj).then(res => {}); // this.$eventBus.emit('returnPreView')
      });
    }

  },

  async created() {
    this.userIsView = this.userView;
    this.submitForm = new Array(this.dpt.tempItemContentList?.length); // this.getDictListByDictType({ dictType: 'faq_purchasing_channels' }).then((res) => {
    //     this.purchasingDir = res.data.data
    // })
    // this.getDictListByDictType({ dictType: 'faq_check_conclusion_status' }).then((res) => {
    //     this.checkConclusionDir = res.data.data
    // })
    // this.getDictListByDictType({ dictType: 'faq_damage_report_status' }).then((res) => {
    //     this.damageReportDir = res.data.data
    // })
    // this.getDictListByDictType({ dictType: 'faq_warranty_type' }).then((res) => {
    //     this.warrantyTypeDir = res.data.data
    // })
    // console.log(this.dpt, 'dpttt');
    // console.log('111');
    // this.$eventBus.on('saveData', (data) => {
    //     // console.log(this.submitForm,data, 'this.submitForm');
    //     if (data.clear) {
    //         this.submitForm[data.index] = null
    //         // console.log('nullll');
    //     } else {
    //         this.submitForm[data.index] = data.answerObj
    //         // console.log(this.submitForm, 'this.submitForm');
    //     }
    // })

    await this.damageR({
      languageType: this.dpt.languageType
    }).then(res => {
      this.damageRTextL = res.data.data;
    });
  },

  mounted() {// console.log(this.userView,'9090')
    // debugger
    // this.$eventBus.off('saveData')
    // console.log(this.$eventBus);
    // 
  },

  destroyed() {},

  activated() {// console.log('999');
  } // beforeDestroy(){
  //     console.log('beforeDestroybeforeDestroy');
  //     this.$eventBus.off('saveData')
  // },
  // desotryed(){
  //     console.log('desotryeddesotryeddesotryed');
  // }


};