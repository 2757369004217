export default {
  props: ['show', 'x', 'y'],
  emits: ['update:show'],

  data() {
    return {
      w: 0
    };
  },

  watch: {
    show: function (nv, ov) {
      this.$nextTick(() => {
        this.w = this.$refs.floatMenu && this.$refs.floatMenu.clientWidth || 0;
      });
    }
  },
  components: {},
  computed: {
    left() {
      var left = this.x - this.w;
      return left;
    },

    top() {
      var top = this.y + 16;
      return top;
    }

  },

  mounted() {},

  methods: {}
};