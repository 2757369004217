export default {
  data() {
    const content_config = {
      china: {
        am: 'AM  8:00 - 12:00 （北京时间）',
        pm: 'PM  13:00 - 00:30 （北京时间）'
      },
      jp: {
        am: '9:00 AM - 1:00 PM (JST)',
        pm: '2:00 PM - 1:30 AM (JST)'
      },
      local: {
        am: '8:00 AM - 12:00 PM (UTC+8)',
        pm: '1:00 PM - 12:30 AM (UTC+8)'
      },
      global: {
        am: '8:00 AM - 12:00 PM (UTC+8)',
        pm: '1:00 PM - 12:30 AM (UTC+8)'
      },
      us: {
        am: '8:00 PM - 00:00 AM (EDT / -1 for EST)',
        pm: '1:00 AM - 12:30 PM (EDT / -1 for EST)'
      },
      usw: {
        am: '8:00 PM - 00:00 AM (EDT / -1 for EST)',
        pm: '1:00 AM - 12:30 PM (EDT / -1 for EST)'
      },
      eu: {
        am: '1:00 AM - 5:00 AM (CET / +1 for CEST)',
        pm: '6:00 AM - 5:30 PM (CET / +1 for CEST)'
      },
      au: {
        am: '10:00 AM - 2:00 PM (AEST / +1 for AEDT)',
        pm: '3:00 PM - 2:30 AM (AEST / +1 for AEDT)'
      },
      ca: {
        am: '8:00 PM - 00:00 AM (EDT / -1 for EST)',
        pm: '1:00 AM - 12:30 PM (EDT / -1 for EST)'
      },
      uk: {
        am: '1:00 AM - 5:00 AM (BST / -1 for GMT)',
        pm: '6:00 AM - 5:30 PM (BST / -1 for GMT)'
      }
    };
    const queryString = new URLSearchParams(window.location.search);
    const data = queryString.get('data');
    const shopUrl = queryString.get('shopUrl');

    if (shopUrl) {
      // const shopUrl = queryString.get('shopUrl')
      localStorage.setItem('shopUrl', shopUrl || '');
    }

    localStorage.setItem('area_mark', data || '');
    var timeText = content_config[data];
    return {
      activeNamestwo: [],
      timeText: timeText,
      content_config
    };
  },

  components: {},
  computed: {},

  mounted() {},

  methods: {}
};