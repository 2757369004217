import config from '/config.js'; // import { ref } from 'vue'

import { mapState, mapGetters } from 'vuex';
import Tree from '@/control/Tree.vue';
import FloatMenu from '@/control/FloatMenu.vue';
import Modal from '@/control/Modal.vue';
import Ticket from '@/control/Ticket2.vue';
export default {
  inject: ['identify'],

  data() {
    return {
      questionRecordCount_new: 0,
      questionRecordData: [],
      unreadCount_new: 0,
      unrresolvedCount_new: 0,
      test: [{
        value: 1,
        label: '1'
      }, {
        value: 2,
        label: '2'
      }],
      testOption: '',
      fold: false,
      height: 74,
      nodeObj: {},
      floatMenu: {
        show: false,
        x: 0,
        y: 0,
        questionTypeParent: undefined,
        questionType: undefined,
        question: undefined
      },
      menuModal: {
        show: false,
        title: '',
        newTitle: '',
        submit: () => {}
      },
      ticket: {
        show: false
      },
      itemFilterSelected: [],
      catalogProps: {
        children: 'children',
        label: 'title'
      },
      tree_height: '100px'
    };
  },

  components: {
    Tree,
    FloatMenu,
    Modal,
    Ticket
  },
  watch: {
    isPC(nv, ov) {
      this.fold = !this.isPC;
    }

  },
  computed: { ...mapState({
      isPC: 'isPC',
      questionId: 'questionId',
      nav: 'nav',
      item: 'item',
      user: 'user',
      itemFilter: 'itemFilter',
      userType: 'userType'
    }),
    ...mapGetters({
      catalog: 'getCatalog',
      getItemName: 'getItemName',
      getValidItem: 'getValidItem',
      questionRecordCount: 'getQuestionRecordCount',
      questionRecordUnreadCount: 'getQuestionRecordUnreadCount',
      questionRecordUnsolvedCount: 'getQuestionRecordUnsolvedCount'
    }),

    catalogTitle() {
      let title = '';
      const keys = Object.keys(this.catalog);

      if (!_.isEmpty(keys)) {
        title = this.catalog[keys[0]].title;
      }

      return title;
    },

    catalogContent() {
      // console.log(this.catalog,'catalog');
      let content = [];
      const keys = Object.keys(this.catalog);

      if (!_.isEmpty(keys)) {
        const {
          node
        } = this.toTree(this.catalog[keys[0]].child);
        content = node;
      } // 初始选中状态


      this.$nextTick(() => {
        this.$refs.left_tree && this.$refs.left_tree.setCurrentKey(this.questionId);
      });
      return content;
    },

    ticketTooltipContent() {
      let content = ''; // 9/10 匿名注释

      if (_.isEmpty(this.user)) {
        content = this._t('goToLogin');
      } // 9/10 匿名注释


      if (this.questionRecordCount_new) {
        content = this._t('goToChat');
      } else {
        content = this._t('createTicketFistly');
      }

      content = content + `(${this._t('unread')}/${this._t('unresolved')})`;
      return content;
    },

    ticketStyle() {
      let style = {};

      if (_.isEmpty(this.user)) {
        style = {
          cursor: 'pointer'
        };
      } else if (this.questionRecordCount_new) {
        style = {
          cursor: 'pointer'
        };
      } else {
        style = {
          cursor: 'not-allowed'
        };
      }

      return style;
    }

  },

  created() {},

  mounted() {
    setTimeout(() => {
      // console.log(_.isEmpty(this.user))
      if (!_.isEmpty(this.user)) {
        this.queryRecordQuestionByPersonal();
      }
    }, 3000); // this.$nextTick(()=>{
    // })
    // console.log(this.user);
    // this.$nextTick(()=>{
    //   this.queryRecordQuestionByPersonal()
    // })
    // console.log(this.catalog,'catalog');

    this.autoSetTreeHeight();
  },

  methods: {
    //新的用户ticket 列表
    async queryRecordQuestionByPersonal() {
      const res = await this.$axios.get('/javaplus/user/recordQuestion/queryRecordQuestionByPersonal');

      if (res.data.code != 200) {// ElNotification({
        //   title: '错误',
        //   message: res.data.message,
        //   type: 'error',
        // });
      } // return res


      this.questionRecordData = res.data.data;
      this.questionRecordCount_new = this.questionRecordData.length;
      this.unrresolvedCount_new = this.questionRecordData[0].totalIsSolvedNum;
      this.unreadCount_new = this.questionRecordData[0].totalUserUnreadNum; // },3000)
      // for (let sort of this.questionRecordData) {
      //   if (!sort.isSolved) {
      //     this.unrresolvedCount_new++
      //   }
      //   if (sort.userUnread) {
      //     this.unreadCount_new++
      //   }
      // }
      //未读数量
      // unreadCount_new: null,
      //   //为解决数量
      //   : null,
      // console.log(this.questionRecordSort_new, 'this.questionRecordSort_new');
    },

    clickNode(node) {// this.$store.commit('changeNav', node.nav)
    },

    clickElement(nodeData, node) {
      if (!nodeData.isQuestion) {
        // node.isUnfold = !node.isUnfold
        return;
      }

      this.$store.commit('changeNav', nodeData.nav);
      this.$store.commit('changeQuestionId', nodeData.id);
      this.$store.commit('changeComponent', 'AnswerQuestion');
    },

    clickToAfterSales() {
      // 9/10 匿名注释
      if (this.questionRecordCount_new == 0 && this.user.id && this.userType == 'user') {
        return;
      }

      if (_.isEmpty(this.user)) {
        this.identify(this.clickToAfterSales);
        return; // if(this.identify(this.clickToAfterSales)){
        // }else{
        //   this.$nextTick(()=>{
        //   })
        //   return
        // }
        // return
      } // 9/10 匿名注释


      this.$msgbox({
        title: this._t('successful'),
        message: this._t('createTicketSuccessful'),
        type: 'success'
      });
      this.$store.dispatch('openChat'); // window.open('/user/chat');
    },

    clickTypeSetting(e, type) {
      this.floatMenu.questionType = type;
      this.floatMenu.question = undefined;
      this.floatMenu.x = e.clientX;
      this.floatMenu.y = e.clientY;
      this.floatMenu.show = true;
    },

    clickAddType() {
      this.menuModal.title = this._t('addType');
      this.menuModal.submit = this.clickAddTypeSubmit;
      this.menuModal.newTitle = '';
      this.menuModal.show = true; // 关闭悬浮菜单

      this.floatMenu.show = false;
    },

    clickAddTypeSubmit() {
      this.$axios.post('/api/admin/KnowledgeBase/addQuestionType', {
        parent_id: this.floatMenu.questionType.parent_id,
        title: this.menuModal.newTitle
      }).then(res => {
        if (!res.data.errno) {
          const _id = res.data.data;
          const _nav = this.floatMenu.questionType.nav;
          this.$store.commit('changeNav', _nav);
          this.floatMenu.questionTypeParent.element[_id];
        }

        this.menuModal.show = false;
      }).catch(err => {
        this.menuModal.show = false;
      });
    },

    clickAddChildType() {
      this.menuModal.title = this._t('addChildType');
      this.menuModal.submit = this.clickAddChildTypeSubmit;
      this.menuModal.newTitle = '';
      this.menuModal.show = true; // 关闭悬浮菜单

      this.floatMenu.show = false;
    },

    clickAddChildTypeSubmit() {
      this.$axios.post('/api/admin/KnowledgeBase/addQuestionType', {
        parent_id: this.floatMenu.questionType.id,
        title: this.menuModal.newTitle
      }).then(res => {
        this.menuModal.show = false;
        const _nav = this.floatMenu.questionType.nav;
        this.$store.commit('changeNav', _nav);
      }).catch(err => {
        this.menuModal.show = false;
      });
    },

    clickChangeType() {
      this.menuModal.title = this._t('changeType');
      this.menuModal.submit = this.clickChangeTypeSubmit;
      this.menuModal.newTitle = '';
      this.menuModal.show = true; // 关闭悬浮菜单

      this.floatMenu.show = false;
    },

    clickChangeTypeSubmit() {
      this.$axios.post('/api/admin/KnowledgeBase/changeQuestionType', {
        id: this.floatMenu.questionType.id,
        title: this.menuModal.newTitle
      }).then(res => {
        this.menuModal.show = false;
        const _nav = this.floatMenu.questionType.nav;
        this.$store.commit('changeNav', _nav);
      }).catch(err => {
        this.menuModal.show = false;
      });
    },

    clickDeleteType() {
      this.menuModal.title = this._t('deleteType');
      this.menuModal.submit = this.clickDeleteTypeSubmit;
      this.menuModal.newTitle = '';
      this.menuModal.show = true; // 关闭悬浮菜单

      this.floatMenu.show = false;
    },

    clickDeleteTypeSubmit() {
      this.$axios.post('/api/admin/KnowledgeBase/deleteQuestionType', {
        id: this.floatMenu.questionType.id
      }).then(res => {
        this.menuModal.show = false;

        const _nav = _.cloneDeep(this.floatMenu.questionType.nav);

        _nav.splice(_nav.length - 1, 1);

        this.$store.commit('changeNav', _nav);
      }).catch(err => {
        this.menuModal.show = false;
      });
    },

    clickAddChildQuestion() {
      this.menuModal.title = this._t('addChildQuestion');
      this.menuModal.submit = this.clickAddChildQuestionSubmit;
      this.menuModal.newTitle = '';
      this.menuModal.show = true; // 关闭悬浮菜单

      this.floatMenu.show = false;
    },

    clickAddChildQuestionSubmit() {
      this.$axios.post('/api/admin/KnowledgeBase/addQuestion', {
        question_type_id: this.floatMenu.questionType.id,
        title: this.menuModal.newTitle
      }).then(res => {
        if (!res.data.errno) {
          const _nav = this.floatMenu.questionType.nav;
          this.$store.commit('changeNav', _nav);
          this.$store.commit('changeQuestionId', res.data.data);
        }

        this.menuModal.show = false;
      }).catch(err => {
        this.menuModal.show = false;
      });
    },

    clickElementSetting(e, type, question) {
      this.floatMenu.questionType = type;
      this.floatMenu.question = question;
      this.floatMenu.x = e.clientX;
      this.floatMenu.y = e.clientY;
      this.floatMenu.show = true;
    },

    clickDeleteQuestion() {
      this.menuModal.title = this._t('deleteQuestion');
      this.menuModal.submit = this.clickDeleteQuestionSubmit;
      this.menuModal.newTitle = '';
      this.menuModal.show = true; // 关闭悬浮菜单

      this.floatMenu.show = false;
    },

    clickDeleteQuestionSubmit() {
      this.$axios.post('/api/admin/KnowledgeBase/deleteQuestion', {
        id: this.floatMenu.question.id
      }).then(res => {
        this.menuModal.show = false;
        const _nav = this.floatMenu.question.nav;
        this.$store.commit('changeNav', _nav);
      }).catch(err => {
        this.menuModal.show = false;
      });
    },

    visibleChangeItemFilter(val) {
      this.changeItemFilter(val);
      this.autoSetTreeHeight();
    },

    changeItemFilter(val) {
      if (val) {
        return;
      }

      this.$store.commit('changeItemFilter', this.itemFilterSelected); // 设置下面树形控件的高度

      this.autoSetTreeHeight();
    },

    autoSetTreeHeight() {
      if (this.$refs.left_all == undefined) {
        return;
      } // 不加延时h5的高度拿不到准确的，估计渲染问题


      setTimeout(() => {
        // ticket的高度46，select外框50，【服务】标题的高度68，
        // console.log('left_all=' + this.$refs.left_all.offsetHeight + ',left_ticket=' + this.$refs.left_ticket.offsetHeight + ',left_selector=' + this.$refs.left_selector.offsetHeight + ',left_title=' + this.$refs.left_title.offsetHeight)
        var tree_h = this.$refs.left_all.offsetHeight - this.$refs.left_ticket.offsetHeight - this.$refs.left_selector.offsetHeight - this.$refs.left_title.offsetHeight - 50; // console.log(tree_h);

        this.tree_height = tree_h + 'px';
      }, 500);
    },

    clickUnsolved(e, type, question) {
      if (_.isEmpty(this.user)) {
        this.identify(this.clickUnsolved, e, type, question);
        return;
      }

      this.floatMenu.questionType = type;
      this.floatMenu.question = undefined;
      this.menuModal.title = this._t('addQuestion');
      this.menuModal.submit = this.clickUnsolvedSubmit;
      this.menuModal.newTitle = '';
      this.menuModal.show = true;
    },

    clickUnsolvedSubmit() {
      this.$axios.post('/api/user/KnowledgeBase/addQuestionRecord', {
        question_type_id: this.floatMenu.questionType.id,
        title: this.menuModal.newTitle
      }).then(res => {
        if (!res.data.errno) {
          this.$store.dispatch('openChat');
          this.menuModal.show = false;
        }
      }).catch(err => {
        console.log(err);
      });
    },

    getItemsName(item) {
      var nameArr = [];

      if (_.isEmpty(item)) {
        return '';
      }

      for (const _item of item) {
        nameArr.push(this.getItemName(_item));
      }

      return nameArr.join();
    },

    clickCopyQuestionLink() {
      // var url=window.location.host
      var url = config.helpUrl(this.isPC) || window.location.host;
      var nav = JSON.stringify(this.floatMenu.question.nav);
      var id = 'wwt' + this.floatMenu.question.id;
      var params = 'nav=' + Buffer.from(nav).toString('base64') + '&id=' + Buffer.from(id).toString('base64');
      url = url + '?' + params;
      this.$copyText(url).then(e => {
        alert('Copy successful:' + url);
      }).catch(err => {
        alert('Copy error:' + err);
      });
    },

    clickTicket(e, questionType) {
      //匿名注释
      if (_.isEmpty(this.user)) {
        this.identify(this.clickTicket, e, questionType);
        return;
      } //匿名注释


      this.ticket.questionType = questionType;
      this.ticket.show = true;
    },

    clickFoldPanel() {
      this.fold = !this.fold;
    },

    toTree(data, node, isQuestion, isUnfold) {
      node = node || [];
      isUnfold = isUnfold || false;

      for (const id in data) {
        const _data = data[id];
        const _node = {
          id: _data.id,
          title: _data.title,
          nav: _data.nav,
          children: undefined,
          isQuestion,
          isUnfold: _data.id === this.questionId
        };

        if (_data.child !== undefined) {
          const ret = this.toTree(_data.child, _node.children, false);
          _node.children = ret.node;
          _node.isUnfold = ret.isUnfold;
        }

        if (_data.question !== undefined) {
          const ret = this.toTree(_data.question, _node.children, true);
          _node.children = ret.node;
          _node.isUnfold = ret.isUnfold;
        }

        if (_node.isUnfold) {
          isUnfold = true;
        }

        node.push(_node);
      }

      return {
        node,
        isUnfold
      };
    },

    // expandElement (nodeData, node, comp) {
    //   node.isUnfold = true
    // },
    // collapseElement (nodeData, node, comp) {
    //   node.isUnfold = false
    // },
    foldAllTree() {
      const treeRef = this.$refs.left_tree;

      if (treeRef === undefined) {
        return;
      }

      const nodesMap = treeRef.store._getAllNodes();

      for (const nodeId in nodesMap) {
        const node = nodesMap[nodeId];

        if (node.parent) {
          node.parent.expanded = false;
        }
      }
    }

  }
};