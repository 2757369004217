import "core-js/modules/es.error.cause.js";
import templateStatusShow from './templateStatusShow.vue';
export default {
  components: {
    templateStatusShow
  },
  props: {
    listIndex: {
      type: Number,
      default: null
    },
    dpt: {
      type: Object,
      default: {}
    },
    userIsView: {
      type: Boolean,
      default: false
    },
    submitForm: {
      type: Array,
      default: () => {
        return [];
      }
    },
    damageReportDir: {
      type: Array,
      default: () => {
        return [];
      }
    },
    damageRTextL: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },

  data() {
    var validatorAaa = (rule, value, callback) => {
      if (!value) {
        this.submitForm[this.listIndex - 1] = null;
        return callback(new Error(`${this.damageRTextL.pleaseCompleteTheEdits}`));
      }

      if (this.dpt.languageType == 'en') {
        // const regex = /^[a-zA-Z0-9]*[\w\d]*$/;
        // const regex = /^(?=.*\S)[^\u4e00-\u9fa5]*$/;
        const regex = /^(?=.*[A-Za-z0-9!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~])[A-Za-z0-9 !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]*$/; // const regex = /^[\u4e00-\u9fa5A-Za-z0-9\.\,\"\'\?\!\-\_\s]+$/;

        if (!regex.test(value)) {
          // this.$nextTick(() => {
          //     
          // })
          this.submitForm[this.listIndex - 1] = null;
          return callback(new Error('Please enter english'));
        } else {
          callback();
        }
      }

      this.answerObj.id = this.dpt.tempItemContentList[this.listIndex - 1].answerVo.id;
      this.answerObj.content = this.dataInfo.content;
      this.submitForm[this.listIndex - 1] = this.answerObj; // console.log(this.submitForm, 'this.submitForm');
      // return callback(true)
    };

    return {
      rules: {
        content: [// { required: false, validator: validatorAaa, trigger: ['change', 'blur'] },
        ]
      },
      setNull: false,
      view: false,
      Edit: false,
      answerObj: {
        id: null,
        contentItemId: "",
        url: "",
        content: ""
      },
      dataInfo: {}
    };
  },

  watch: {},
  computed: {},
  methods: {
    //根据状态返回不同的class
    getClassByStatus(status) {
      if (status == '1') {
        return 'col_rred';
      } else if (status == '2') {
        return 'col_ggreen';
      }
    },

    //保存数据
    saveData(reView) {
      if (reView) {
        this.answerObj.id = this.dpt.tempItemContentList[this.listIndex - 1].answerVo.id;
        this.answerObj.content = this.dataInfo.content;
        this.submitForm[this.listIndex - 1] = this.answerObj;
      } else {
        this.answerObj.id = this.dpt.tempItemContentList[this.listIndex - 1].answerVo.id;
        this.answerObj.content = this.dataInfo.content;
        this.submitForm[this.listIndex - 1] = this.answerObj; // console.log('67676');
        // this.$refs.ruleForm.validate((valid) => {
        //     console.log(valid, 'valid');
        //     if (valid) {
        //         console.log('231312');
        //         this.answerObj.id = this.dpt.tempItemContentList[this.listIndex - 1].answerVo.id
        //         this.answerObj.content = this.dataInfo.content
        //         this.submitForm[this.listIndex - 1] = this.answerObj
        //         console.log(this.submitForm, 'this.submitForm');
        //         // this.$eventBus.emit('saveData', { answerObj: this.answerObj, index: this.listIndex - 1 })
        //     } else {
        //     }
        // })
        //10.9
        // this.$refs.ruleForm.validate()
        //10.9
      }
    }

  },

  created() {
    this.dataInfo = JSON.parse(JSON.stringify(this.dpt.tempItemContentList[this.listIndex - 1]));

    if (this.dataInfo.isEssential) {
      this.dataInfo.isEssential = true;
    } else {
      this.dataInfo.isEssential = false;
    } //判断是否能对页面操作


    this.Edit = this.dpt.status === '1';

    if (this.Edit) {} else {
      //判断用户是否填写 未填写不必回显
      if (this.dataInfo.answerVo.content && this.dataInfo.answerVo.content != 'null') {
        this.dataInfo.content = this.dataInfo.answerVo.content;
      }
    }
  },

  mounted() {
    //从新填报的时候
    if (this.dpt.status == '3' || this.dpt.status == '1' && !this.userIsView) {
      this.saveData(true);
    } // 判断是否有数据 并且 导入更新页面
    // console.log('radio页面更新') 
    // if (Object.keys(this.dpt.selectList[this.listIndex - 1].dataInfo).length) {
    //     let dataInfoCopy = JSON.parse(JSON.stringify(this.dpt.selectList[this.listIndex - 1].dataInfo))
    //     this.dataInfo = dataInfoCopy
    // }

  }

};