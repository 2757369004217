export default {
  name: 'FilterPanel2',
  props: {
    options: {
      type: Array,
      default: []
    },
    title: {
      type: String,
      default: 'tittle'
    },
    multiple: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: 'label'
    },
    defaultCheckAll: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: Array,

      default() {
        return [];
      }

    }
  },
  emits: ['update:modelValue'],

  data() {
    return {
      checkAll: false,
      checkedOptions: [],
      optionData: [],
      isIndeterminate: true
    };
  },

  watch: {
    options: {
      handler(nv, ov) {
        // console.log('options', nv);
        this.optionData = this.options;
      },

      immediate: true
    },
    optionData: {
      handler(nv, ov) {
        this.init();
      },

      immediate: true
    },
    checkedOptions: {
      handler(nv, ov) {// console.log('checkedOptions', nv);
      },

      immediate: true
    },
    modelValue: {
      handler(nv, ov) {
        this.checkedOptions = nv;
        this.init();
      },

      immediate: true
    }
  },

  mounted() {},

  methods: {
    init() {
      if (this.checkedOptions.length == 0) {
        this.checkAll = false;
        this.isIndeterminate = false;
      } else if (this.optionData.length == this.modelValue.length) {
        this.checkAll = true;
        this.isIndeterminate = false;
      } else {
        this.checkAll = false;
        this.isIndeterminate = true;
      }
    },

    handleCheckAllChange(val) {
      this.checkedOptions = val ? this.optionData.map(item => item.id) : [];
      this.isIndeterminate = false;
      this.emitChecked();
    },

    handlecheckedOptionsChange(data) {
      let checkedCount = data.length;
      this.checkAll = checkedCount === this.optionData.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.optionData.length;
      this.emitChecked();
    },

    emitChecked() {
      let checked = this.checkedOptions;
      this.$emit('update:modelValue', checked);
    }

  }
};