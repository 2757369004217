import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "page-aftersale",
  style: {
    "background": "#f4f4f4",
    "padding-bottom": "20px"
  }
};
const _hoisted_2 = {
  class: "section section-banner-2"
};
const _hoisted_3 = {
  class: "container"
};
const _hoisted_4 = {
  class: "sale-container"
};
const _hoisted_5 = {
  class: "sale-left",
  style: {
    "max-height": "710px"
  }
};
const _hoisted_6 = {
  class: "sale-right",
  style: {
    "min-height": "710px"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Catalog2 = _resolveComponent("Catalog2");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("section", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createVNode(_component_Catalog2)]), _createElementVNode("div", _hoisted_6, [(_openBlock(), _createBlock(_KeepAlive, null, [(_openBlock(), _createBlock(_resolveDynamicComponent($options.activeComponent)))], 1024))])])])])]);
}