import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
  class: "popip-section-content"
};
const _hoisted_2 = {
  class: "transverse"
};
const _hoisted_3 = {
  class: "category"
};
const _hoisted_4 = {
  class: "transverse transverse-m"
};
const _hoisted_5 = {
  class: "category"
};
const _hoisted_6 = {
  class: "submit-btn-c"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_option = _resolveComponent("el-option");

  const _component_el_select = _resolveComponent("el-select");

  const _component_el_input = _resolveComponent("el-input");

  const _component_el_button = _resolveComponent("el-button");

  const _component_el_alert = _resolveComponent("el-alert");

  const _component_el_dialog = _resolveComponent("el-dialog");

  return _openBlock(), _createBlock(_component_el_dialog, {
    class: "ticket-dialog",
    modelValue: $data.modalShow,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => $data.modalShow = $event),
    title: _ctx._t('ticket'),
    "close-on-click-modal": false
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString(_ctx._t('questionType')), 1), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.nav, (_nav, _navIndex) => {
      return _openBlock(), _createBlock(_component_el_select, {
        key: _navIndex,
        class: "select-m",
        modelValue: $data.nav[_navIndex],
        "onUpdate:modelValue": $event => $data.nav[_navIndex] = $event,
        disabled: $options.disabled,
        "value-key": "id"
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.questionTypeArrayByParentId[$data.nav[_navIndex - 1] && $data.nav[_navIndex - 1].id || 0], _type => {
          return _openBlock(), _createBlock(_component_el_option, {
            key: _type.id,
            label: _type.title,
            value: _type
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(_type.title), 1)]),
            _: 2
          }, 1032, ["label", "value"]);
        }), 128))]),
        _: 2
      }, 1032, ["modelValue", "onUpdate:modelValue", "disabled"]);
    }), 128)), _ctx.questionTypeArrayByParentId[$data.nav[$data.nav.length - 1] && $data.nav[$data.nav.length - 1].id || 0] ? (_openBlock(), _createBlock(_component_el_select, {
      key: 0,
      class: "select-m",
      modelValue: $data.newQuestionType,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.newQuestionType = $event),
      onChange: _cache[1] || (_cache[1] = $event => $options.changeAddNav()),
      "value-key": "id",
      disabled: $options.disabled
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.questionTypeArrayByParentId[$data.nav[$data.nav.length - 1] && $data.nav[$data.nav.length - 1].id || 0], _type => {
        return _openBlock(), _createBlock(_component_el_option, {
          key: _type.id,
          value: _type,
          label: _type.title
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_type.title), 1)]),
          _: 2
        }, 1032, ["value", "label"]);
      }), 128))]),
      _: 1
    }, 8, ["modelValue", "disabled"])) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, _toDisplayString(_ctx._t('question')), 1), _createVNode(_component_el_input, {
      maxlength: "100",
      "show-word-limit": "",
      class: "textarea",
      type: "textarea",
      resize: "none",
      rows: 2,
      placeholder: _ctx._t('inputQuestionHere'),
      disabled: $options.disabled,
      modelValue: $data.questionTitle,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $data.questionTitle = $event)
    }, null, 8, ["placeholder", "disabled", "modelValue"])]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_el_button, {
      class: "submit-btn",
      onClick: _cache[3] || (_cache[3] = $event => $options.clickAddQuestionRecord())
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx._t('submit')), 1)]),
      _: 1
    })]), _createElementVNode("div", null, [_withDirectives(_createVNode(_component_el_alert, {
      type: "warning",
      description: $data.hint,
      onClose: _cache[4] || (_cache[4] = $event => $data.hint = ''),
      effect: "dark"
    }, null, 8, ["description"]), [[_vShow, $data.hint != '']])])])]),
    _: 1
  }, 8, ["modelValue", "title"]);
}