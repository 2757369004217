import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "page-custservi",
  style: {
    "background": "#f4f4f4",
    "padding-bottom": "20px"
  }
};
const _hoisted_2 = {
  class: "section section-banner-2"
};
const _hoisted_3 = {
  class: "container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("section", _hoisted_2, [_createElementVNode("div", _hoisted_3, [(_openBlock(), _createBlock(_KeepAlive, null, [(_openBlock(), _createBlock(_resolveDynamicComponent($options.activeComponent)))], 1024))])])]);
}