import { ref, reactive } from "vue";
export default {
  components: {},
  props: {
    dataInfo: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },

  data() {
    return {
      user: {},
      highLight: null,
      userInfo: {},
      chatRecord: []
    };
  },

  watch: {},
  computed: {},
  methods: {
    //点击问题查询聊天记录 用id查询
    clickQuestion(item, index) {
      this.highLight = index;
      this.getUserChatRecordListByQuestionRecordId({
        questionRecordId: item.id
      });
    },

    ///通过问题id查询聊天记录
    async getUserChatRecordListByQuestionRecordId(params) {
      const res = await this.$axios.post(`/java/front/getUserChatRecordListByQuestionRecordId?questionRecordId=${params.questionRecordId}`);

      if (res.data.code != 200) {
        ElNotification({
          title: '错误',
          message: res.data.message,
          type: 'error'
        });
      }

      this.$eventBus.emit('getChatRecord', res.data.data);
      this.$eventBus.emit('getChatRecordId', params.questionRecordId);
      return res;
    }

  },

  created() {
    // console.log(this.dataInfo,'DATAINFO');
    this.user = JSON.parse(this.dataInfo.user); // console.log(this.dataInfo.item[0], 'datainfo');
    // this.userInfo = JSON.parse(this.dataInfo.item[0].user)
  },

  mounted() {}

};