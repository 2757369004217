export default {
  data() {
    return {};
  },

  components: {},
  computed: {},

  mounted() {},

  methods: {}
};