import Upload from "@/control/Upload.vue";
export default {
  components: {
    Upload
  },
  props: {},

  data() {
    return {
      sendContent: '',
      data: [{
        label: '一级 1',
        children: [{
          label: '二级 1-1',
          children: [{
            label: '三级 1-1-1'
          }]
        }]
      }, {
        label: '一级 2',
        children: [{
          label: '二级 2-1',
          children: [{
            label: '三级 2-1-1'
          }]
        }, {
          label: '二级 2-2',
          children: [{
            label: '三级 2-2-1'
          }]
        }]
      }, {
        label: '一级 3',
        children: [{
          label: '二级 3-1',
          children: [{
            label: '三级 3-1-1'
          }]
        }, {
          label: '二级 3-2',
          children: [{
            label: '三级 3-2-1'
          }]
        }]
      }],
      defaultProps: {
        children: 'children',
        label: 'label'
      }
    };
  },

  watch: {},
  computed: {},
  methods: {
    //上传图片后进行回调
    onSuccessUpload(embed) {
      const params = {
        question_record_id: 6,
        content: embed
      };
      this.sendContentInfo(params);
    },

    //点击发送消息
    clickSendMsg() {
      var sendContent = this.sendContent;
      sendContent = this.appendHtmlContent(sendContent);
      sendContent = this.removeUnvalidContent(sendContent);

      if (sendContent.trim() == "") {
        return;
      }

      const params = {
        question_record_id: 6,
        content: sendContent
      };
      this.sendContentInfo(params);
    },

    //处理文本消息
    appendHtmlContent(content) {
      return "<p>" + content + "</p>";
    },

    removeUnvalidContent(content) {
      const unvaild = "<p></p>";

      if (content.endsWith(unvaild)) {
        content = content.slice(0, -unvaild.length);
        content = this.removeUnvalidContent(content);
      }

      return content;
    },

    //发送文件的请求
    async sendContentInfo(params) {
      const res = await this.$axios.post('/api/user/ChatBase/sendContent', params);

      if (res.data.code != 200) {
        ElNotification({
          title: '错误',
          message: res.data.message,
          type: 'error'
        });
      }

      return res;
    } // sendEmbed(embed) {
    //     const sendContent = embed;
    //     this.$axios
    //         .post("/api/user/ChatBase/sendContent", {
    //             question_record_id: this.questionRecordId,
    //             content: sendContent,
    //         })
    //         .then((res) => {
    //             if (res.data.errno) {
    //             } else {
    //             }
    //         }).catch((err) => {
    //             console.log(err);
    //         });
    // },


  },

  created() {},

  mounted() {}

};