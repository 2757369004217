import FilterPanel from '../FilterPanel.vue';
import { mapGetters, mapState } from 'vuex';
export default {
  components: {
    FilterPanel
  },
  props: {},

  data() {
    return {
      questionTypeSelectedByLevel: []
    };
  },

  computed: { ...mapState({
      user: 'user',
      userType: 'userType',
      questionType: 'questionType'
    }),
    ...mapGetters({
      questionTypeById: 'questionTypeById',
      getFilterQuestionRecordByUser: 'getFilterQuestionRecordByUser'
    }),

    questionTypeOptionsByLevel() {
      //层级选项
      var optionsByLevel = []; //默认添加第一级的所有选项

      for (var type of this.questionType) {
        if (type.parent_id == 0) {
          optionsByLevel[0] = optionsByLevel[0] || [];
          optionsByLevel[0].push(type);
        }
      } //根据已选项，获取下一级的所有选项


      for (var level = 0; level < this.questionTypeSelectedByLevel.length; level++) {
        if (_.isEmpty(this.questionTypeSelectedByLevel[level])) {
          continue;
        }

        for (var typeSelected of this.questionTypeSelectedByLevel[level]) {
          for (var type of this.questionType) {
            if (typeSelected.id == type.parent_id) {
              optionsByLevel[level + 1] = optionsByLevel[level + 1] || [];
              optionsByLevel[level + 1].push(type);
            }
          }
        }
      }

      return optionsByLevel;
    },

    changeValue() {
      var value = {};
      value.questionTypeSelectedByLevel = _.cloneDeep(this.questionTypeSelectedByLevel);
      return value;
    }

  },
  watch: {
    getFilterQuestionRecordByUser: {
      handler(nv, ov) {
        this.questionTypeSelectedByLevel = []; //获取数据库中已选项

        var questionTypeByLevel = this.getQuestionTypeByLevel();

        for (var level = 0; level < questionTypeByLevel.length; level++) {
          var questionTypeArray = questionTypeByLevel[level];

          if (_.isEmpty(questionTypeArray)) {
            continue;
          }

          for (var type of questionTypeArray) {
            if (nv.question_type_id_array != null) {
              if (nv.question_type_id_array.indexOf(type.id) >= 0) {
                this.questionTypeSelectedByLevel[level] = this.questionTypeSelectedByLevel[level] || [];
                this.questionTypeSelectedByLevel[level].push(type);
              }
            } else {
              this.questionTypeSelectedByLevel[level] = this.questionTypeSelectedByLevel[level] || [];
              this.questionTypeSelectedByLevel[level].push(type);
            }
          }
        }
      }

    },
    questionTypeSelectedByLevel: {
      handler(nv, ov) {
        this.$emit('change', this.changeValue);
      },

      deep: true
    }
  },

  mounted() {
    //默认将Service加入
    for (var type of this.questionType) {
      if (type.parent_id == 0) {
        this.questionTypeSelectedByLevel[0] = this.questionTypeSelectedByLevel[0] || [];
        this.questionTypeSelectedByLevel[0].push(type);
      }
    }
  },

  methods: {
    getQuestionTypeByLevel(questionTypeByLevel, questionTypeId, level) {
      questionTypeId = questionTypeId || 0;
      level = level || 0;
      questionTypeByLevel = questionTypeByLevel || [];

      for (var type of this.questionType) {
        if (type.parent_id != questionTypeId) {
          continue;
        }

        questionTypeByLevel[level] = questionTypeByLevel[level] || [];
        questionTypeByLevel[level].push(type);
        questionTypeByLevel = this.getQuestionTypeByLevel(questionTypeByLevel, type.id, level + 1);
      }

      return questionTypeByLevel;
    },

    updateSelected(checked) {
      var questionTypeIdArray = [];

      for (var level = 0; level < this.questionTypeSelectedByLevel.length; level++) {
        var typeSelectedArray = this.questionTypeSelectedByLevel[level];

        if (_.isEmpty(typeSelectedArray)) {
          break;
        }

        for (var type of typeSelectedArray) {
          questionTypeIdArray.push(type.id);
        }
      }

      var addArray = [];
      var changeArray = [];

      if (this.getFilterQuestionRecordByUser && this.getFilterQuestionRecordByUser.id) {
        changeArray.push({
          id: this.getFilterQuestionRecordByUser.id,
          question_type_id_array: JSON.stringify(questionTypeIdArray)
        });
      } else {
        var add = {};
        add.person_id = this.user.id;
        add.question_type_id_array = JSON.stringify(questionTypeIdArray);
        addArray.push(add);
      }

      this.$store.dispatch('updateRemoteTablePart', {
        table: 'filterQuestionRecord',
        addArray,
        changeArray
      });
    }

  }
};