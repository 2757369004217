import VueQuillEditor, { Quill } from './index'
import ImageResize from 'quill-image-resize-module'
import { ImageDrop } from 'quill-image-drop-module'
import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css'
Quill.debug('error');
Quill.register('modules/imageResize', ImageResize)
Quill.register('modules/imageDrop', ImageDrop) // for bubble theme
// 新增video-link
import VideoLink from './video-link.js'
Quill.register(VideoLink,true);
//覆盖video
import Video from './video.js'
Quill.register(Video,true);
//新增file
import File from './file.js'
Quill.register(File,true);
export default VueQuillEditor
