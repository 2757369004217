import Catalog from '@/components/Catalog2.vue';
import Home from './Home.vue';
import AnswerQuestion from './AnswerQuestion2.vue'; // import { mapState } from 'vuex'

export default {
  inject: ['send'],

  data() {
    return {
      webSocketUrl: `${window.location.protocol == 'https:' ? 'wss' : 'ws'}://${window.location.host}/javaplus/user/webSocketServer`
    };
  },

  components: {
    Home,
    Catalog,
    AnswerQuestion
  },
  computed: {
    // ...mapState({
    //   isPC:'isPC'
    // }),
    activeComponent() {
      return this.$store.state.component;
    }

  },

  beforeMount() {
    this.$store.commit('changeBase', 'KnowledgeBase');
    this.$store.commit('changeUserType', 'user');
  },

  mounted() {
    this.init_newSocket();
  },

  unmounted() {},

  methods: {
    clickHome() {
      this.$store.commit('changeComponent', 'Home');
    },

    init_newSocket() {
      this.newSocket = new WebSocket(this.webSocketUrl);
      this.newSocket.onopen = this.new_Open;
      this.newSocket.onerror = this.new_Error;
      this.newSocket.onmessage = this.new_Get;
      this.newSocket.onclose = this.new_Close;
    },

    new_Open() {
      console.log('ws open_new', event);
    },

    new_Error(event) {
      console.log('ws error_new', event);
    },

    new_Close() {
      console.log('ws close_new');
      this.newSocket.close();
    },

    new_Get(msg) {
      if (this.newSocket.readyState != 1) {
        return;
      }
    }

  }
};