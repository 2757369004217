import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-16fd644a"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  key: 0,
  style: {
    "width": "90%",
    "margin-left": "5%"
  }
};
const _hoisted_2 = {
  style: {
    "margin-bottom": "20px"
  }
};
const _hoisted_3 = {
  key: 0,
  style: {
    "margin": "20px 0 0 10px",
    "color": "#D9001B",
    "display": "flex"
  }
};
const _hoisted_4 = {
  style: {
    "width": "16%"
  }
};
const _hoisted_5 = {
  style: {
    "margin-left": "10px",
    "width": "80%",
    "word-wrap": "break-word"
  }
};
const _hoisted_6 = {
  key: 1,
  style: {
    "width": "90%",
    "margin-left": "5%"
  }
};
const _hoisted_7 = {
  style: {
    "display": "flex",
    "justify-content": "space-between"
  }
};
const _hoisted_8 = {
  key: 0
};
const _hoisted_9 = {
  key: 0,
  style: {
    "margin-bottom": "20px"
  }
};
const _hoisted_10 = {
  key: 1,
  style: {
    "color": "rgb(126,127,134)",
    "margin-left": "30px"
  }
};
const _hoisted_11 = {
  key: 1
};
const _hoisted_12 = {
  style: {
    "margin-bottom": "20px"
  }
};
const _hoisted_13 = {
  key: 2,
  style: {
    "margin": "20px 0 0 10px",
    "color": "#D9001B",
    "display": "flex"
  }
};
const _hoisted_14 = {
  style: {
    "width": "60px"
  }
};
const _hoisted_15 = {
  style: {
    "width": "80%",
    "word-wrap": "break-word"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input");

  const _component_el_form_item = _resolveComponent("el-form-item");

  const _component_el_form = _resolveComponent("el-form");

  const _component_templateStatusShow = _resolveComponent("templateStatusShow");

  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", null, [($props.dpt.status == '1' || $props.dpt.status == '3') && !$props.userIsView ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: _normalizeClass($data.dataInfo.isEssential ? 'star' : ''),
    style: {
      "font-weight": "bold",
      "margin-bottom": "20px",
      "font-size": "14px",
      "word-wrap": "break-word"
    }
  }, _toDisplayString($props.listIndex) + "." + _toDisplayString($data.dataInfo.title || '请输入标题'), 3), _createElementVNode("div", _hoisted_2, [_createVNode(_component_el_form, {
    model: $data.dataInfo,
    rules: $data.rules,
    ref: "ruleForm",
    class: "demo-ruleForm"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_form_item, {
      prop: "content",
      style: {
        "width": "100%"
      }
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: $data.dataInfo.content,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.dataInfo.content = $event),
        placeholder: $props.damageRTextL.pleaseEnterContent,
        type: "textarea",
        onChange: _cache[1] || (_cache[1] = $event => $options.saveData(false))
      }, null, 8, ["modelValue", "placeholder"])]),
      _: 1
    })]),
    _: 1
  }, 8, ["model", "rules"])]), $data.dataInfo.answerVo?.checkStatus === '1' && $props.dpt.status != '2' ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString($props.damageRTextL.additionalRemarks) + ": ", 1), _createElementVNode("div", _hoisted_5, _toDisplayString($data.dataInfo.answerVo.checkRemark), 1)])) : _createCommentVNode("", true)])) : (_openBlock(), _createElementBlock("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createElementVNode("div", {
    class: _normalizeClass($data.dataInfo.isEssential ? 'star' : ''),
    style: {
      "font-weight": "bold",
      "margin-bottom": "20px",
      "font-size": "14px",
      "word-wrap": "break-word"
    }
  }, _toDisplayString($props.listIndex) + "." + _toDisplayString($data.dataInfo.title || '请输入标题'), 3), _createVNode(_component_templateStatusShow, {
    status: $props.dpt.status,
    dataInfo: $data.dataInfo,
    languageType: $props.dpt.languageType,
    dpt: $props.dpt
  }, null, 8, ["status", "dataInfo", "languageType", "dpt"])]), $props.dpt.status ? (_openBlock(), _createElementBlock("div", _hoisted_8, [$data.dataInfo.answerVo.content && $data.dataInfo.answerVo.content != 'null' ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createVNode(_component_el_input, {
    modelValue: $data.dataInfo.content,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $data.dataInfo.content = $event),
    placeholder: $props.damageRTextL.pleaseEnterContent,
    type: "textarea",
    disabled: ""
  }, null, 8, ["modelValue", "placeholder"])])) : (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString($props.damageRTextL.userHasNotFilledOut), 1))])) : (_openBlock(), _createElementBlock("div", _hoisted_11, [_createElementVNode("div", _hoisted_12, [_createVNode(_component_el_input, {
    modelValue: $data.dataInfo.content,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => $data.dataInfo.content = $event),
    placeholder: $props.damageRTextL.pleaseEnterContent,
    type: "textarea",
    disabled: ""
  }, null, 8, ["modelValue", "placeholder"])])])), $data.dataInfo.answerVo?.checkStatus === '1' && $props.dpt.status != '2' ? (_openBlock(), _createElementBlock("div", _hoisted_13, [_createElementVNode("div", _hoisted_14, _toDisplayString($props.damageRTextL.additionalRemarks) + ": ", 1), _createElementVNode("div", _hoisted_15, _toDisplayString($data.dataInfo.answerVo.checkRemark), 1)])) : _createCommentVNode("", true)]))])]);
}