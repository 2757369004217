import Catalog from '@/components/Catalog.vue';
import Home from './Home.vue';
import AnswerQuestion from './AnswerQuestion.vue';
import { mapState } from 'vuex';
export default {
  inject: ['send'],

  data() {
    return {};
  },

  components: {
    Home,
    Catalog,
    AnswerQuestion
  },
  computed: { ...mapState({
      isPC: 'isPC',
      user: 'user',
      userType: 'userType'
    }),

    isAdminLogin() {
      return !_.isEmpty(this.user) && this.userType === 'admin';
    },

    activeComponent() {
      return this.$store.state.component;
    }

  },

  beforeMount() {
    this.$store.commit('changeBase', 'KnowledgeBase');
    this.$store.commit('changeUserType', 'admin');
  },

  mounted() {},

  unmounted() {},

  methods: {
    clickHome() {
      this.$store.commit('changeComponent', 'Home');
    }

  }
};