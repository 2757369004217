import { mapState, mapGetters } from 'vuex';
import MoblieTicket from '@/control/MoblieTicket.vue';
export default {
  inject: ['identify', 'uploadSuccess', 'uploadFailed'],
  props: {
    question: {
      type: Object,

      default() {
        return {};
      }

    },
    step: {
      type: Object,

      default() {
        return {};
      }

    },
    disabled: {
      type: [Boolean, Number],

      default() {
        return false;
      }

    }
  },
  emits: {},

  data() {
    return {
      ticket: {
        show: false,
        questionType: undefined,
        question: undefined,
        questionRecord: undefined
      },
      unsolvedDisabled: false
    };
  },

  components: {
    MoblieTicket
  },
  computed: { ...mapState({
      user: 'user',
      userType: 'userType',
      stepTemp: 'stepTemp',
      nav: 'nav'
    }),
    ...mapGetters({})
  },
  watch: {
    user: function (nv, ov) {
      if (this.step && this.step.type == 'login' && this.userType == 'user' && !_.isEmpty(this.user)) {
        if (this.step == this.stepTemp[this.stepTemp.length - 1]) {
          this.clickNextQuestionStep(this.step.answerStep[0]);
        }
      }
    }
  },

  mounted() {},

  methods: {
    changeQuestionStep(id, change) {
      this.$axios.post('/api/admin/KnowledgeBase/changeQuestionStep', {
        id: id,
        change: change
      }).then(res => {
        this.uploadSuccess();
      }).catch(err => {
        this.uploadFailed(this.changeQuestionStep, id, change);
      });
    },

    changeQuestionStepContent(e, questionStep) {
      this.changeQuestionStep(questionStep.id, {
        content: questionStep.content
      });
    },

    changeAnswerStep(id, change) {
      this.$axios.post('/api/admin/KnowledgeBase/changeAnswerStep', {
        id: id,
        change: change
      }).then(res => {
        this.uploadSuccess();
      }).catch(err => {
        this.uploadFailed(this.changeAnswerStep, id, change);
      });
    },

    changeAnswerStepContent(answerStep) {
      this.changeAnswerStep(answerStep.id, {
        content: answerStep.content
      });
    },

    changeAnswerStepNextQuestionStep(answerStep) {
      this.changeAnswerStep(answerStep.id, {
        next_question_step_id: answerStep.next_question_step_id
      });
    },

    clickDeleteAnswerStep(answerStep) {
      this.$axios.post('/api/admin/KnowledgeBase/deleteAnswerStep', {
        id: answerStep.id
      });
    },

    clickAddAnswerStep(questionStep) {
      this.$axios.post('/api/admin/KnowledgeBase/addAnswerStep', {
        question_step_id: questionStep.id,
        index: Object.keys(questionStep.answerStep).length,
        content: questionStep.newAnswerStep.content,
        next_question_step_id: questionStep.newAnswerStep.next_question_step_id
      });
      questionStep.newAnswerStep = {};
    },

    clickNextQuestionStep(answerStep) {
      if (this.disabled) {
        return;
      }

      if (_.isEmpty(answerStep)) {
        this.stepTemp.push(null);
        this.$store.commit('changeStepTemp', this.stepTemp);
        return;
      }

      for (const _answerStep of this.step.answerStep) {
        _answerStep.selected = false;
      }

      answerStep.selected = true;

      if (answerStep.next_question_step_id == 0) {
        this.stepTemp.push(null);
        this.$store.commit('changeStepTemp', this.stepTemp);
        return;
      }

      for (const _questionStep of this.question.questionStep) {
        if (_questionStep.id == answerStep.next_question_step_id) {
          this.stepTemp.push(_questionStep);
        }
      }

      this.$store.commit('changeStepTemp', this.stepTemp);
    },

    loadIdentify() {
      if (_.isEmpty(this.user)) {
        this.identify();
      } else {
        if (this.step == this.stepTemp[this.stepTemp.length - 1]) {
          this.clickNextQuestionStep(this.step.answerStep[0]);
        }
      }
    },

    clickChatNow(question) {
      // if (this.unsolvedDisabled) {
      //   return
      // }
      if (_.isEmpty(this.user)) {
        this.identify(this.clickChatNow, question);
        return;
      }

      this.unsolvedDisabled = true;
      question.nav = this.nav;
      this.ticket.question = question;
      this.ticket.show = true;
    },

    backPreStep(step) {
      if (_.isEmpty(step)) {
        return;
      }

      step.pop();

      if (_.isEmpty(step[step.length - 1].answerStep)) {
        this.backPreStep(step);
      }
    },

    clickBackPreStep() {
      if (this.disabled) {
        return;
      }

      this.backPreStep(this.stepTemp);

      for (const answer of this.stepTemp[this.stepTemp.length - 1].answerStep) {
        answer.selected = false;
      }

      this.$store.commit('changeStepTemp', this.stepTemp);
    },

    btnClass(disabled, selected) {
      let className = 'btnactivity';

      if (selected == undefined) {
        if (disabled) {
          className = className + 2;
        } else {
          className = className + 3;
        }

        return className;
      }

      if (selected) {
        className = className + 1;
      } else {
        if (disabled) {
          className = className + 2;
        } else {
          className = className + 3;
        }
      }

      return className;
    }

  }
};