import { mapState, mapGetters } from 'vuex';
import Editor from '@/control/Editor.vue';
export default {
  inject: ['identify', 'uploadSuccess', 'uploadFailed'],
  props: {
    question: {
      type: Object,

      default() {
        return {};
      }

    },
    step: {
      type: Object,

      default() {
        return {};
      }

    },
    disabled: {
      type: [Boolean, Number],

      default() {
        return false;
      }

    }
  },
  emits: {},

  data() {
    return {};
  },

  components: {
    Editor
  },
  computed: { ...mapState({
      user: 'user',
      userType: 'userType',
      stepTemp: 'stepTemp'
    }),
    ...mapGetters({})
  },
  watch: {
    user: function (nv, ov) {
      if (this.step.type == 'login' && this.userType == 'user' && !_.isEmpty(this.user)) {
        if (this.step == this.stepTemp[this.stepTemp.length - 1]) {
          this.clickNextQuestionStep(this.step.answerStep[0]);
        }
      }
    }
  },

  mounted() {},

  methods: {
    changeQuestionStep(id, change) {
      this.$axios.post('/api/admin/KnowledgeBase/changeQuestionStep', {
        id: id,
        change: change
      }).then(res => {
        this.uploadSuccess();
      }).catch(err => {
        this.uploadFailed(this.changeQuestionStep, id, change);
      });
    },

    changeQuestionStepContent(e, questionStep) {
      let {
        nv
      } = e;
      this.changeQuestionStep(questionStep.id, {
        content: nv
      });
    },

    changeAnswerStep(id, change) {
      this.$axios.post('/api/admin/KnowledgeBase/changeAnswerStep', {
        id: id,
        change: change
      }).then(res => {
        this.uploadSuccess();
      }).catch(err => {
        this.uploadFailed(this.changeAnswerStep, id, change);
      });
    },

    changeAnswerStepContent(answerStep) {
      this.changeAnswerStep(answerStep.id, {
        content: answerStep.content
      });
    },

    changeAnswerStepNextQuestionStep(answerStep) {
      this.changeAnswerStep(answerStep.id, {
        next_question_step_id: answerStep.next_question_step_id
      });
    },

    clickDeleteAnswerStep(answerStep) {
      this.$axios.post('/api/admin/KnowledgeBase/deleteAnswerStep', {
        id: answerStep.id
      });
    },

    clickAddAnswerStep(questionStep) {
      this.$axios.post('/api/admin/KnowledgeBase/addAnswerStep', {
        question_step_id: questionStep.id,
        index: Object.keys(questionStep.answerStep).length,
        content: questionStep.newAnswerStep.content,
        next_question_step_id: questionStep.newAnswerStep.next_question_step_id
      });
      questionStep.newAnswerStep = {};
    },

    clickNextQuestionStep(answerStep) {
      if (answerStep.next_question_step_id == 0) {
        this.stepTemp.push('end');
        return;
      }

      for (const _questionStep of this.question.questionStep) {
        if (_questionStep.id == answerStep.next_question_step_id) {
          this.stepTemp.push(_questionStep);
        }
      }

      this.$store.commit('changeStepTemp', this.stepTemp);
    },

    loadIdentify() {
      if (_.isEmpty(this.user)) {
        this.identify();
      } else {
        if (this.step == this.stepTemp[this.stepTemp.length - 1]) {
          this.clickNextQuestionStep(this.step.answerStep[0]);
        }
      }
    },

    clickChatNow(question) {
      if (this.unsolvedDisabled) {
        return;
      }

      if (_.isEmpty(this.user)) {
        this.identify(this.clickChatNow, question);
        return;
      }

      this.unsolvedDisabled = true;
      this.$axios.post('/api/user/KnowledgeBase/addQuestionRecord', {
        question_type_id: question.question_type_id,
        question_id: question.id,
        step_record: this.$parent.$refs.question.innerHTML
      }).then(res => {
        if (!res.data.errno) {
          window.open(config.chatUrl, '_blank');
        }
      }).catch(err => {
        console.log(err);
      });
    }

  }
};