export default {
  components: {},
  props: {
    listIndex: {
      type: Number,
      default: null
    },
    dpt: {
      type: Object,
      default: {}
    }
  },

  data() {
    return {
      dataInfo: {}
    };
  },

  watch: {},
  computed: {},
  methods: {
    //根据状态返回不同的class
    getClassByStatus(status) {
      if (status == '1') {
        return 'col_rred';
      } else if (status == '2') {
        return 'col_ggreen';
      }
    }

  },

  created() {
    this.dataInfo = JSON.parse(JSON.stringify(this.dpt.tempItemContentList[this.listIndex - 1]));

    if (this.dataInfo.isEssential) {
      this.dataInfo.isEssential = true;
    } else {
      this.dataInfo.isEssential = false;
    }

    if (this.dpt.status) {
      //判断用户是否填写 未填写不必回显
      if (this.dataInfo.answerVo.content && this.dataInfo.answerVo.content != 'null') {
        this.dataInfo.content = this.dataInfo.answerVo.content;
      }
    }
  },

  mounted() {// 判断是否有数据 并且 导入更新页面
    // console.log('radio页面更新')
    // if (Object.keys(this.dpt.selectList[this.listIndex - 1].dataInfo).length) {
    //     let dataInfoCopy = JSON.parse(JSON.stringify(this.dpt.selectList[this.listIndex - 1].dataInfo))
    //     this.dataInfo = dataInfoCopy
    // }
  }

};