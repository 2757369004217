import { mapState, mapGetters } from 'vuex';
import MultipleSelect from '@/control/MultipleSelect.vue';
import MultipleOption from '@/control/MultipleOption.vue';
export default {
  data() {
    return {};
  },

  components: {
    MultipleSelect,
    MultipleOption
  },
  computed: { ...mapState({
      user: 'user',
      person: 'person'
    }),
    ...mapGetters({
      catalog: 'getCatalog',
      getItemName: 'getItemName',
      getPersonName: 'getPersonName'
    })
  },

  mounted() {},

  activated() {
    this.$store.commit('changeNav', []);
    this.$store.commit('changeQuestionId', 0);
  },

  deactivated() {},

  methods: {
    clickViewAll(nav) {
      this.$store.commit('changeNav', nav);
      this.$store.commit('changeComponent', 'AnswerQuestion');
    },

    clickQuestion(question) {
      this.$store.commit('changeNav', question.nav);
      this.$store.commit('changeQuestionId', question.id);
      this.$store.commit('changeComponent', 'AnswerQuestion');
    },

    clickToAfterSales() {
      // this.$router.push({path:'/admin/chat'});
      window.open('/admin/chat');
    },

    changePerson(type, {
      nv,
      ov
    }) {
      // 新增负责人
      var add = _.difference(nv, ov); // 移除负责人


      var del = _.difference(ov, nv);

      this.$axios.post('/api/admin/KnowledgeBase/changeQuestionTypePerson', {
        question_type_id: type.id,
        add: add,
        del: del
      }).then(res => {
        if (res.data.errno) {
          console.log(res.data.errmsg);
        } else {}
      }).catch(err => {
        console.log(err);
      });
    },

    hasBottom(index, len) {
      if (len < 1) {
        return false;
      }

      if (len % 2) {
        //奇数
        if (index == len - 1) {
          return true;
        }
      } else {
        //偶数
        if (index == len - 1 || index == len - 2) {
          return true;
        }
      }

      return false;
    }

  }
};