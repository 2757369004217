import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  key: 0,
  style: {
    "width": "100%",
    "height": "100%"
  }
};
const _hoisted_2 = {
  style: {
    "width": "100%",
    "height": "100%"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return $options.isAdminLogin ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(), _createBlock(_KeepAlive, null, [(_openBlock(), _createBlock(_resolveDynamicComponent($options.activeComponent)))], 1024))])])) : _createCommentVNode("", true);
}