export default {
  components: {},
  props: {
    isVideo: {
      type: Boolean,
      default: false
    },
    listIndex: {
      type: Number,
      default: null
    },
    dpt: {
      type: Object,
      default: {}
    }
  },

  data() {
    return {
      imgArrayE: [],
      imgViewerShow: false,
      imgIndex: null,
      imgArray: [],
      dataInfo: [{
        imageUrl: '',
        inputText: '',
        required: false
      }],
      rules: {
        name: [{
          required: true,
          message: '请输入标题',
          trigger: ['change', 'blur']
        }]
      }
    };
  },

  watch: {},
  computed: {},
  methods: {
    //根据状态返回不同的class
    //根据状态返回不同的class
    getClassByStatus(status) {
      if (status == '1') {
        return 'col_rred';
      } else if (status == '2') {
        return 'col_ggreen';
      }
    },

    //点击显示图片
    clickShowImage(index) {
      if (this.dpt.status) {
        console.log(index, 'index');
        this.imgIndex = index;
        this.imgViewerShow = true;
      }
    },

    replacePath(path) {
      return path.replace('/ipfs/', '/static/');
    }

  },

  created() {
    this.dataInfo = JSON.parse(JSON.stringify(this.dpt.tempItemContentList[this.listIndex - 1]));

    if (!this.dataInfo.itemList.length) {
      let firstObj = {
        urls: '',
        name: ''
      };
      this.dataInfo.itemList.push(firstObj);
    } else {
      for (let i of this.dataInfo.itemList) {
        this.imgArrayE.push(i.url.replace('/ipfs/', '/static/'));
      }
    }

    if (this.dataInfo.isEssential) {
      this.dataInfo.isEssential = true;
    } else {
      this.dataInfo.isEssential = false;
    } //再复制一份装用户上传的url


    this.itemListCopy = JSON.parse(JSON.stringify(this.dataInfo.itemList)); //清空里面的url

    for (let i of this.itemListCopy) {
      i.url = '';
    }

    if (this.dpt.status) {
      //判断用户是否填写 未填写不必回显
      if (this.dataInfo.answerVo.url && this.dataInfo.answerVo.url != 'null') {
        //回填里面的url  先拆分url 再拆分id
        let urlList = JSON.parse(this.dataInfo.answerVo.url).split(';').slice(0, -1);

        for (let i of urlList) {
          let urlItem = i.split(':');

          for (let x of this.itemListCopy) {
            if (x.id === Number(urlItem[1])) {
              x.url = urlItem[0];
              this.imgArray.push(urlItem[0]);
            }
          }
        }
      }
    }
  },

  mounted() {// 判断是否有数据 并且 导入更新页面
    // console.log('radio页面更新')
    // if (Object.keys(this.dpt.selectList[this.listIndex - 1].dataInfo).length) {
    //     let dataInfoCopy = JSON.parse(JSON.stringify(this.dpt.selectList[this.listIndex - 1].dataInfo))
    //     this.dataInfo = dataInfoCopy
    // }
  }

};