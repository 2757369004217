import { mapState, mapGetters } from 'vuex';
import MultipleSelect from '@/control/MultipleSelect.vue';
import MultipleOption from '@/control/MultipleOption.vue';
import StepType from '@/control/StepType2.vue';
import Ticket from '@/control/Ticket2.vue';
export default {
  inject: ['identify'],

  data() {
    return {
      ticket: {
        show: false,
        questionType: undefined,
        question: undefined,
        questionRecord: undefined
      },
      solvedDisabled: false,
      unsolvedDisabled: false
    };
  },

  components: {
    MultipleSelect,
    MultipleOption,
    StepType,
    Ticket
  },
  computed: { ...mapState({
      firstWindow: 'firstWindow',
      item: 'item',
      user: 'user',
      userType: 'userType',
      questionTemp: 'questionTemp',
      stepTemp: 'stepTemp',
      stepType: 'stepType',
      questionId: 'questionId',
      nav: 'nav'
    }),
    ...mapGetters({
      question: 'getQuestion',
      getItemName: 'getItemName',
      getItemById: 'getItemById'
    }),

    stepEnd() {
      if (_.isEmpty(this.stepTemp)) {
        return false;
      }

      return _.isEmpty(this.stepTemp[this.stepTemp.length - 1]);
    }

  },
  watch: {
    question: function (nv, ov) {
      if (_.isEmpty(nv) || (_.isEmpty(ov) || nv.id == ov.id) && !_.isEmpty(this.questionTemp)) {
        return;
      }

      this.initAnswerQuestion();
    }
  },

  activated() {
    this.initAnswerQuestion(true);
  },

  methods: {
    initAnswerQuestion(first) {
      if (_.isEmpty(this.question) || first && !_.isEmpty(this.questionTemp)) {
        return;
      } // 镜像，避免后台操作同步


      this.$store.commit('changeQuestionTemp', _.cloneDeep(this.question)); // 清空步骤流程

      this.$store.commit('changeStepTemp', []); // 追加添加步骤一

      this.questionTemp && this.questionTemp.questionStep[0] && this.stepTemp.push(this.questionTemp.questionStep[0]); // 访问量

      this.$store.dispatch('accessStatistics'); // 已解决，未解决失能

      this.solvedDisabled = false;
      this.unsolvedDisabled = false;
    },

    clickToAfterSales() {
      // this.$router.push({path:'/admin/chat'});
      // var route = this.$router
      window.open('/admin/chat');
    },

    clickChatNow(question) {
      if (this.unsolvedDisabled || this.solvedDisabled) {
        return;
      } // 关闭登录验证


      if (_.isEmpty(this.user)) {
        this.identify(this.clickChatNow, question);
        return;
      }

      this.unsolvedDisabled = true;
      question.nav = this.nav;
      this.ticket.question = question;
      this.ticket.show = true;
    },

    changeQuestion(questionId, change) {
      this.$axios.post('/api/user/KnowledgeBase/changeQuestion', {
        id: questionId,
        change: change
      });
    },

    clickSolved(question) {
      if (this.unsolvedDisabled || this.solvedDisabled) {
        return;
      }

      this.solvedDisabled = true;

      if (!question.solved) {
        question.solved_num++;
        this.changeQuestion(question.id, {
          solved_num: question.solved_num
        });
      }

      question.solved = true;
    },

    getItemsName(item) {
      var nameArr = [];

      if (_.isEmpty(item)) {
        return '';
      }

      for (const id of item) {
        if (id == 0) {
          nameArr.push(this._t('allItem'));
        }

        const _item = this.getItemById(id);

        if (_.isEmpty(_item) || _item.delete) {
          continue;
        }

        nameArr.push(_item.name);
      }

      return nameArr.join();
    },

    backPreStep(step) {
      if (_.isEmpty(step)) {
        return;
      }

      step.pop();

      if (_.isEmpty(step[step.length - 1].answerStep)) {
        this.backPreStep(step);
      }
    },

    clickBackPreStep() {
      if (this.disabled) {
        return;
      }

      this.unsolvedDisabled = false;
      this.solvedDisabled = false;
      this.backPreStep(this.stepTemp);

      for (const answer of this.stepTemp[this.stepTemp.length - 1].answerStep) {
        answer.selected = false;
      }

      this.$store.commit('changeStepTemp', this.stepTemp);
    },

    btnClass(disabled, selected) {
      let className = 'btnactivity';

      if (selected == undefined) {
        if (disabled) {
          className = className + 2;
        } else {
          className = className + 3;
        }

        return className;
      }

      if (selected) {
        className = className + 1;
      } else {
        if (disabled) {
          className = className + 2;
        } else {
          className = className + 3;
        }
      }

      return className;
    }

  }
};