import customerInofCard from '../../control/customerInfoCard.vue';
import { ref, reactive, onMounted, getCurrentInstance } from "vue";
import Editor from '@/control/Editor.vue';
export default {
  setup() {},

  components: {
    customerInofCard,
    Editor
  },
  props: {},

  data() {
    return {
      url: "ws://172.16.10.23:8080/webSocketServer/2/22",
      socket: undefined,
      //websocket timer
      userAdminWSTimeOut: null,
      //
      userId: null,
      userChatUrl: `${window.location.protocol == 'https:' ? 'wss' : 'ws'}://${window.location.host}/java/faqwebsocket.ws`,
      socket: undefined,
      question_record_id: null,
      //文本框中要发送的
      sendContent: "",
      //用户还是客服0用户1客服
      user: 1,
      //sop树
      sopTreeData: [],
      //问题列表
      questionRecordList: [],
      //消息记录
      chatRecord: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      }
    };
  },

  watch: {},
  computed: {},
  methods: {
    //websocket测试
    socketTest() {
      this.socket.send();
    },

    //初始化websocket
    initWebSocket() {
      this.socket = new WebSocket(this.url);
      this.socket.onopen = this.socketOnOpen;
      this.socket.onmessage = this.socketOnOpen;
      this.socket.onclose = this.socketOnClose;
      this.socket.onerror = this.socketOnError;
      let msgObject = {
        timestamp: 1724048588095,
        toUserId: "2",
        userId: "11",
        serType: 2,
        webSocketMessageContent: {
          msgContent: "{\"1\":2}",
          msgType: 0
        }
      };
      console.log(this.socket, 'this.socket');
      console.log(this.socket.send, 'this.socketsend');
    },

    //websocket 开启回调
    socketOnOpen(msg) {
      console.log(msg, 'msgggggopen');
    },

    //websocket 消息回调
    socketOnMessage(msg) {
      console.log(msg, 'msgggggmsg');
    },

    //websocket 关闭回调
    socketOnClose(msg) {
      console.log(msg, 'msgggggclose');
    },

    //websocket 错误回调
    socketOnError(msg) {
      console.log(msg, 'msgggggerror');
    },

    ///通过问题id查询聊天记录
    async getUserChatRecordListByQuestionRecordId(params) {
      const res = await this.$axios.post(`/java/front/getUserChatRecordListByQuestionRecordId?questionRecordId=${params.questionRecordId}`);

      if (res.data.code != 200) {
        ElNotification({
          title: '错误',
          message: res.data.message,
          type: 'error'
        });
      }

      this.chatRecord = res.data.data; // this.$refs.Record.scrollContainer.scrollTop = this.$refs.Record.scrollContainer.scrollHeight
      // console.log(params.questionRecordId, 'params.questionRecordId');

      return res;
    },

    sendInfo() {
      const params = {
        question_record_id: this.question_record_id,
        content: this.sendContent,
        wsid: "3220943a-3ae8-4275-833c-fb7fa91544d6"
      };
      console.log(params, 'this.paramsthis.params');
      this.sendContentInfo(params).then(() => {
        this.getUserChatRecordListByQuestionRecordId({
          questionRecordId: this.question_record_id
        });
      });
    },

    //点击问题 显示聊天记录
    clickQuestion() {},

    //点击sop数的回调
    handleNodeClick(item) {
      console.log(item.id, 'item');
      this.questionRecordList = [];
      this.queryQuestionRecordList(item.id);
    },

    //发送问题
    async sendContentInfo(params) {
      const res = await this.$axios.post('/api/admin/ChatBase/sendContent', params);

      if (res.data.code != 200) {
        ElNotification({
          title: '错误',
          message: res.data.message,
          type: 'error'
        });
      }

      return res;
    },

    //点击对应的sop查询问题列表
    async queryQuestionRecordList(id) {
      const params = {
        page: 1,
        questionRecordId: id,
        size: 100,
        urgencyLevel: []
      }; // console.log('2132');

      const res = await this.$axios.post('/java/front/queryQuestionRecordList', params);

      if (res.data.errno != 0) {
        ElNotification({
          title: '错误',
          message: res.data.message,
          type: 'error'
        });
        return res;
      } else {
        this.questionRecordList = res.data.data;
      }
    },

    //请求sop列表
    async getAllDirectoryList() {
      const params = {
        state: [],
        questionType: [],
        personIds: [],
        clientName: ''
      };
      const res = await this.$axios.get(`/java/admin/getAllDirectoryList`, params);

      if (res.data.code != 200) {
        ElNotification({
          title: '失败',
          message: res.data.msg,
          type: 'error'
        });
        return;
      }

      this.sopTreeData = res.data.data.children;
    }

  },

  created() {
    this.initWebSocket();
    this.getAllDirectoryList();
    this.queryQuestionRecordList(5); // this.queryQuestionRecordList()
    // this.initWebSocket()

    console.log(this.$refs, 'this.$refs.Record');
  },

  mounted() {
    //获取消息记录的方法
    // console.log(this.$eventBus,'eventBus');
    this.$eventBus.on('getChatRecord', data => {
      this.chatRecord = data; // this.$refs.Record.scrollContainer.scrollTop = this.$refs.Record.scrollContainer.scrollHeight
    }); //获取问题id

    this.$eventBus.on('getChatRecordId', id => {
      this.question_record_id = id;
      console.log(this.question_record_id, ' this.chatRecord');
    });
  }

};