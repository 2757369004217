import templateUploadPreview from '../control/templateDemeUpload.vue';
import templateRadioPreview from '../control/templateDemeRadio.vue';
import templateTextAreaPreview from '../control/templateDemeTextArea.vue';
import { ElMessage, ElNotification } from 'element-plus'; // import templateDemoViewHeader from '../control/templateDemoViewHeader.vue'

export default {
  components: {
    templateUploadPreview,
    templateRadioPreview,
    templateTextAreaPreview // templateDemoViewHeader

  },
  props: {
    templateDemeData: {
      type: Object,
      default: () => {
        return {};
      }
    },
    dpt: {
      type: Object,
      default: () => {
        return {};
      }
    },
    refresh: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    // damageReportType: {
    //     type: Array,
    //     default: ()=>{
    //         return []
    //     }
    // },
    close: {
      types: Function,
      default: () => {}
    },
    languageDir: {
      type: Array,
      default: () => {
        return [];
      }
    },
    templatePopFunction: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },

  data() {
    return {};
  },

  watch: {},
  computed: {},
  methods: {
    //根据status返回不一样的文本
    getTextByStatus(status) {
      if (status == '1') {
        return '等待用户填报';
      } else if (status == '2') {
        return '审核中';
      } else if (status == '3') {
        return '等待用户补充';
      } else if (status == '4') {
        return '审核完成';
      } else if (status == '5') {
        return '已作废';
      }
    },

    //teleport 的剪辑报损单填报项目传送
    teleport() {
      this.$refs.dptEdit.scrollIntoView({
        behavior: 'smooth' // 平滑滚动
        // block: 'start'       // 滚动到元素的顶部

      });
    },

    //客服查看完报损单的返回事件
    returnPreView() {
      // this.$eventBus.emit('returnPreView')
      this.templatePopFunction.returnPreView();
    },

    // 添加报损单
    async addDamageReport(params) {
      const res = await this.$axios.post('/javaplus/admin/afterSale/damageReport/addDamageReport', params);

      if (res.data.code != 200) {
        ElNotification({
          title: '错误',
          message: res.data.message,
          type: 'error'
        });
      }

      return res;
    },

    cancel() {
      // this.$eventBus.emit('preStep')
      // this.templatePopFunction.returnLastStep()
      this.close();
    },

    preStep() {
      // this.$eventBus.emit('preStep')
      this.templatePopFunction.returnLastStep();
    },

    nextStep() {
      //控制页面加载动画
      // this.$eventBus.emit('loading', true),
      this.$emit('update:refresh', true); // console.log('--start');
      // let ticketData = {
      //     purchasingChannelsName:this.templateDemeData.radio===1?'WINWING官网':'其他渠道',
      //     dptName:this.dpt.tempName,
      //     proName:this.templateDemeData.porName,
      //     orderNum:this.templateDemeData.orderNum,
      //     paymentTime:this.templateDemeData.paymentTime
      // }
      // 
      // console.log('--endd');
      // 添加报损单
      //             {
      //     "msg": "ok",
      //     "code": 200,
      //     "data": {
      //         "id": 38,
      //         "productionId": 370,
      //         "productionName": "R1+DAMPER1",
      //         "status": "1",
      //         "statusName": "等待填报",
      //         "tempItemId": 195,
      //         "tempItemName": "1.产品基础知识培训报损单测试813",
      //         "merchandiseName": "猎户座金属飞行脚舵（阻尼版）",
      //         "purchasingChannels": "1",
      //         "purchasingChannelsName": "WINWING官网",
      //         "orderNum": "125124600042310352",
      //         "paymentTime": "null",
      //         "pictureUrl": "null"
      //     }
      // }
      // this.$eventBus.emit('getTicketData')
      // console.log('333222111');
      // debugger

      this.addDamageReport(this.templateDemeData).then(res => {
        if (res.data.code === 200) {
          res.data.data.languageType = this.dpt.languageType; // this.$eventBus.emit('getTicketData', res.data.data)

          this.templatePopFunction.getTicketDataSend(res.data.data);
        } else {
          this.$emit('update:refresh', false);
        }
      });
    },

    closeDialog() {
      // console.log('chufale')
      this.close(); // this.$event.emit('closeDialog','languageShow')
    }

  },

  created() {
    console.log(this.templatePopFunction, 'templatePopFunction'); // console.log(this.dpt.languageType, 'templatePopFunction')
    // console.log('desotryed');
  },

  mounted() {
    this.$emit('update:refresh', false);
  }

};