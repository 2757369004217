import { mapState, mapActions } from 'vuex';
export default {
  data() {
    return {
      footermailtxt: '',
      activeNames: ['1', '2', '3'] // 默认二级导航展开

    };
  },

  components: {},
  computed: { ...mapState('moblie', {
      navText: 'navText'
    })
  },

  mounted() {},

  methods: { ...mapActions('moblie', ['subscribeToNews']),

    toggleLanguages(language) {},

    toscrollTop() {
      this.$refs.backtop && this.$refs.backtop.$el.click();
    },

    subscribeDo(footermailtxt) {
      this.subscribeToNews(this, {
        email: footermailtxt
      });
    }

  }
};