import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, withCtx as _withCtx, normalizeStyle as _normalizeStyle, withModifiers as _withModifiers, withKeys as _withKeys, vShow as _vShow, withDirectives as _withDirectives, resolveDirective as _resolveDirective, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-29e3518a"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "sale-container"
};
const _hoisted_2 = {
  class: "sale-left",
  style: {
    "padding-bottom": "unset"
  }
};
const _hoisted_3 = ["title"];
const _hoisted_4 = {
  class: "problem-group",
  style: {
    "height": "666px"
  }
};
const _hoisted_5 = ["onClick"];
const _hoisted_6 = {
  class: "time"
};
const _hoisted_7 = {
  class: "dec"
};
const _hoisted_8 = {
  key: 0,
  class: "unread",
  style: {
    "top": "5px"
  }
};
const _hoisted_9 = {
  key: 1,
  class: "solveddiv"
};
const _hoisted_10 = {
  class: "solved"
};
const _hoisted_11 = {
  key: 2,
  class: "unresolve"
};
const _hoisted_12 = {
  key: 3,
  style: {
    "position": "absolute",
    "right": "26px",
    "bottom": "0px",
    "text-align": "right",
    "width": "300px"
  }
};
const _hoisted_13 = {
  class: "score",
  style: {
    "text-align": "right"
  }
};
const _hoisted_14 = {
  class: "sale-right",
  style: {
    "position": "relative",
    "left": "0px"
  }
};
const _hoisted_15 = {
  style: {
    "width": "768px"
  }
};
const _hoisted_16 = {
  key: 0,
  style: {
    "position": "absolute",
    "width": "768px",
    "background-color": "#fff5f5",
    "height": "50px",
    "display": "flex",
    "justify-content": "flex-start",
    "align-items": "center",
    "word-break": "break-all",
    "padding-right": "20px"
  }
};
const _hoisted_17 = ["innerHTML"];
const _hoisted_18 = {
  key: 1
};
const _hoisted_19 = {
  key: 2,
  style: {
    "display": "flex",
    "flex-direction": "column",
    "align-items": "start",
    "width": "100%"
  }
};
const _hoisted_20 = {
  style: {},
  class: "score-fun scored"
};
const _hoisted_21 = {
  key: 0,
  style: {
    "display": "flex",
    "width": "70%",
    "margin": "0 auto"
  }
};
const _hoisted_22 = {
  style: {
    "max-width": "50%",
    "word-break": "break-word"
  }
};
const _hoisted_23 = {
  class: "tool"
};
const _hoisted_24 = {
  class: "tool-uploaddiv"
};
const _hoisted_25 = {
  style: {
    "display": "flex",
    "flex-direction": "column"
  }
};
const _hoisted_26 = {
  style: {
    "display": "flex",
    "flex-direction": "row",
    "align-items": "center"
  }
};
const _hoisted_27 = {
  style: {
    "font-size": "13px"
  }
};
const _hoisted_28 = {
  class: "score-btn"
};
const _hoisted_29 = {
  class: "input-sr"
};
const _hoisted_30 = {
  key: 1
};
const _hoisted_31 = {
  style: {
    "position": "absolute",
    "display": "block",
    "background-color": "black",
    "color": "white",
    "padding": "5px",
    "border-radius": "4px",
    "z-index": "10",
    "left": "500px",
    "top": "500px",
    "word-break": "break-all"
  },
  class: "toolTips"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_rate = _resolveComponent("el-rate");

  const _component_templateDemoEdit = _resolveComponent("templateDemoEdit");

  const _component_el_image_viewer = _resolveComponent("el-image-viewer");

  const _component_el_icon_WarningFilled = _resolveComponent("el-icon-WarningFilled");

  const _component_el_icon = _resolveComponent("el-icon");

  const _component_templateTicket = _resolveComponent("templateTicket");

  const _component_el_divider = _resolveComponent("el-divider");

  const _component_Upload = _resolveComponent("Upload");

  const _component_el_icon_CircleCheckFilled = _resolveComponent("el-icon-CircleCheckFilled");

  const _component_el_icon_CircleCloseFilled = _resolveComponent("el-icon-CircleCloseFilled");

  const _component_el_input = _resolveComponent("el-input");

  const _component_el_button = _resolveComponent("el-button");

  const _component_ScorePanel = _resolveComponent("ScorePanel");

  const _component_el_container = _resolveComponent("el-container");

  const _directive_loading = _resolveDirective("loading");

  return _withDirectives((_openBlock(), _createBlock(_component_el_container, {
    style: {
      "width": "100%",
      "height": "100%"
    },
    "element-loading-text": '拼命加载中...' + $data.loadingTextArray.join(),
    "element-loading-spinner": "el-icon-loading",
    "element-loading-background": "rgba(0, 0, 0, 0.5)"
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
      class: "ticket",
      title: _ctx._t('unread') + '/' + _ctx._t('unresolved')
    }, _toDisplayString(_ctx._t("afterSalesQuestion")) + "(" + _toDisplayString($data.unreadCount_new) + "/" + _toDisplayString($data.unrresolvedCount_new) + ") ", 9, _hoisted_3), _createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.questionRecordSort_new, (_record, _recordIndex) => {
      return _openBlock(), _createElementBlock("div", {
        key: _recordIndex,
        class: _normalizeClass(["problem-div", [_record.id == $data.questionRecordId_new ? 'activity' : '']]),
        style: {
          "word-break": "break-all"
        },
        onClick: $event => $options.clickQuestionRecord(_record)
      }, [_createElementVNode("div", _hoisted_6, _toDisplayString($options.getLocalTime(_record.create_time)), 1), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", null, _toDisplayString($options.getNav(_record).join(">") + ">" + $options.getTitle(_record)), 1)]), _record.user_unread && _record.is_solved == 0 ? (_openBlock(), _createElementBlock("div", _hoisted_8)) : _createCommentVNode("", true), _record.is_solved ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, "[" + _toDisplayString(_ctx._t("solved")) + "]", 1)])) : (_openBlock(), _createElementBlock("div", _hoisted_11, "[" + _toDisplayString(_ctx._t("unsolved")) + "]", 1)), _record.score || _record.score == 0 ? (_openBlock(), _createElementBlock("div", _hoisted_12, [_createElementVNode("div", _hoisted_13, [_createTextVNode(_toDisplayString(_ctx._t('rate')), 1), _createVNode(_component_el_rate, {
        modelValue: _record.score,
        "onUpdate:modelValue": $event => _record.score = $event,
        disabled: "",
        "text-color": "#ff9900"
      }, null, 8, ["modelValue", "onUpdate:modelValue"])])])) : _createCommentVNode("", true)], 10, _hoisted_5);
    }), 128))])]), $data.templateDemoShow ? (_openBlock(), _createElementBlock("div", {
      key: 0,
      class: _normalizeClass(["", $data.params_questionRecordId ? 'innerDiv' : '']),
      style: {
        "overflow-y": "scroll",
        "height": "100vh"
      }
    }, [_createVNode(_component_templateDemoEdit, {
      dpt: $data.damageReportContent,
      userView: $data.userView,
      functionTemple: $data.functionTemple
    }, null, 8, ["dpt", "userView", "functionTemple"])], 2)) : _createCommentVNode("", true), _withDirectives(_createElementVNode("div", _hoisted_14, [$data.imgViewerShow ? (_openBlock(), _createBlock(_component_el_image_viewer, {
      key: 0,
      onClose: _cache[0] || (_cache[0] = $event => $data.imgViewerShow = false),
      infinite: false,
      "url-list": $data.imgArray,
      "initial-index": $data.imgIndex
    }, null, 8, ["url-list", "initial-index"])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_15, [$data.noName ? (_openBlock(), _createElementBlock("div", _hoisted_16, [_createVNode(_component_el_icon, {
      style: {
        "font-size": "20px",
        "color": "rgb(251,197,80)",
        "margin": "0 20px"
      }
    }, {
      default: _withCtx(() => [_createVNode(_component_el_icon_WarningFilled)]),
      _: 1
    }), _createTextVNode(" " + _toDisplayString(_ctx._t('温馨提示：尊敬的顾客，您现在处于匿名模式，建议您登录账户以便查看聊天记录')), 1)])) : _createCommentVNode("", true), _createElementVNode("div", {
      class: "chat",
      ref: "chat",
      style: _normalizeStyle({
        'margin-top': $data.noName ? '50px' : '',
        height: $data.noName ? '482px' : '532px'
      })
    }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.chatReocrd_new, (_chat, _chatIndex) => {
      return _openBlock(), _createElementBlock("div", {
        key: _chatIndex,
        class: _normalizeClass([!_chat.user?.erpPersonalId && _chat.messageType != 6 ? 'chatdiv-right' : '', _chat.messageType != 6 ? 'chatdiv' : '']),
        ref_for: true,
        ref: "chatdiv"
      }, [_chat.messageType == 4 ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [_createElementVNode("div", {
        class: "name",
        style: _normalizeStyle([{
          "flex-basis": "100%"
        }, {
          'text-align': !_chat.user?.erpPersonalId ? 'right' : 'left'
        }])
      }, [_createElementVNode("span", {
        style: _normalizeStyle([{
          'background-color': !_chat.user?.erpPersonalId ? 'none' : 'rgb(127,255,212)'
        }, {
          "padding": "2px 5px"
        }])
      }, _toDisplayString(_chat.user?.name) + " [" + _toDisplayString($options.getLocalTime(_chat.time)) + "]", 5)], 4), _createElementVNode("div", {
        style: _normalizeStyle({
          'background-color': !_chat.user?.erpPersonalId ? 'rgb(28,29,31)' : 'rgb(243,243,243)',
          'padding': '5px',
          'border-radius': '3px',
          'white-space': 'wrap;',
          'word-wrap': 'break-word;',
          'max-width': '100%',
          'overflow-wrap': 'break-word'
        }),
        innerHTML: _chat.new_content
      }, null, 12, _hoisted_17)], 64)) : _createCommentVNode("", true), _chat.messageType == 10 ? (_openBlock(), _createElementBlock("div", _hoisted_18, [_createElementVNode("div", {
        class: "name",
        style: _normalizeStyle([{
          "flex-basis": "100%"
        }, {
          'text-align': !_chat.user?.erpPersonalId ? 'right' : 'left'
        }])
      }, [_createElementVNode("span", {
        style: _normalizeStyle([{
          'background-color': !_chat.user?.erpPersonalId ? 'none' : 'rgb(127,255,212)'
        }, {
          "padding": "2px 5px"
        }])
      }, _toDisplayString(_chat.user?.name) + " [" + _toDisplayString($options.getLocalTime(_chat.time)) + "]", 5)], 4), _createVNode(_component_templateTicket, {
        tempTicketData: JSON.parse(_chat.content),
        ref_for: true,
        ref: "ticket",
        isAdmin: false,
        onChange: $options.callBack
      }, null, 8, ["tempTicketData", "onChange"])])) : _createCommentVNode("", true), _chat.messageType == 6 ? (_openBlock(), _createElementBlock("div", _hoisted_19, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_chat.content.score, (item, index) => {
        return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_20, [_createVNode(_component_el_divider, null, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx._t("inviteScore")), 1)]),
          _: 1
        }), _createElementVNode("span", null, _toDisplayString(item.name), 1), _createVNode(_component_el_rate, {
          modelValue: item.score,
          "onUpdate:modelValue": $event => item.score = $event,
          max: 5,
          disabled: ""
        }, null, 8, ["modelValue", "onUpdate:modelValue"])])]);
      }), 256)), _chat.content.suggest ? (_openBlock(), _createElementBlock("div", _hoisted_21, [_createElementVNode("div", null, _toDisplayString(_ctx._t("yourSuggest")), 1), _createElementVNode("div", _hoisted_22, _toDisplayString(_chat.content.suggest), 1)])) : _createCommentVNode("", true)])) : _createCommentVNode("", true)], 2);
    }), 128))], 4), _createElementVNode("div", _hoisted_23, [_createElementVNode("div", _hoisted_24, [_createVNode(_component_Upload, {
      url: $data.uploadUrl,
      onOnSuccess: $options.onSuccessUpload,
      disabled: $options.userDisabled,
      fileType: "image"
    }, null, 8, ["url", "onOnSuccess", "disabled"]), _createVNode(_component_Upload, {
      url: $data.uploadUrl,
      onOnSuccess: $options.onSuccessUpload,
      disabled: $options.userDisabled,
      fileType: "video"
    }, null, 8, ["url", "onOnSuccess", "disabled"]), _createVNode(_component_Upload, {
      url: $data.uploadUrl,
      onOnSuccess: $options.onSuccessUpload,
      disabled: $options.userDisabled,
      fileType: "file"
    }, null, 8, ["url", "onOnSuccess", "disabled"])]), _createElementVNode("div", _hoisted_25, [_createElementVNode("div", _hoisted_26, [_createElementVNode("p", null, _toDisplayString(_ctx._t('CustomerServiceOnlineStatus')) + "：", 1), $setup.isOnLine ? (_openBlock(), _createBlock(_component_el_icon, {
      key: 0,
      size: 18,
      color: "green"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_icon_CircleCheckFilled)]),
      _: 1
    })) : (_openBlock(), _createBlock(_component_el_icon, {
      key: 1,
      size: 18,
      color: "red"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_icon_CircleCloseFilled)]),
      _: 1
    }))]), _createElementVNode("span", _hoisted_27, _toDisplayString(_ctx._t("Onlinetime")), 1)]), _createElementVNode("div", _hoisted_28, [_createElementVNode("div", {
      onClick: _cache[1] || (_cache[1] = $event => $options.clickSolved($data.questionRecord_new))
    }, " [ " + _toDisplayString($data.questionRecord_new.is_solved ? _ctx._t("unsolved") : _ctx._t("solved")) + " ] ", 1)])]), _createElementVNode("div", _hoisted_29, [_createVNode(_component_el_input, {
      type: "textarea",
      placeholder: $options.userDisabled ? '' : _ctx._t('sendPlaceholder'),
      modelValue: $data.sendContent,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $data.sendContent = $event),
      disabled: $data.questionRecord_new.is_solved,
      onKeydown: _cache[3] || (_cache[3] = _withKeys(_withModifiers($event => $options.clickSendContent($event), ["exact"]), ["enter"])),
      onKeyup: _cache[4] || (_cache[4] = _withKeys($event => $data.sendContent = '', ["enter"])),
      "show-word-limit": true,
      maxlength: "300"
    }, null, 8, ["placeholder", "modelValue", "disabled"]), _createVNode(_component_el_button, {
      class: "input-btn",
      size: "small",
      style: {
        "margin-top": "3px"
      },
      round: "",
      onClick: _cache[5] || (_cache[5] = $event => $options.clickSendContent($event)),
      disabled: $options.userDisabled
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx._t("send")), 1)]),
      _: 1
    }, 8, ["disabled"])]), $data.scorePanel.show ? (_openBlock(), _createElementBlock("div", _hoisted_30, [_createVNode(_component_ScorePanel, {
      show: $data.scorePanel.show,
      "onUpdate:show": _cache[6] || (_cache[6] = $event => $data.scorePanel.show = $event),
      style: {
        "z-index": "2"
      },
      questionRecordSelected: $data.questionRecord_new,
      scoreOption: $data.scoreOption,
      updateTicketList: $options.updateTicketList
    }, null, 8, ["show", "questionRecordSelected", "scoreOption", "updateTicketList"])])) : _createCommentVNode("", true)])], 512), [[_vShow, !$data.templateDemoShow]])]), _withDirectives(_createElementVNode("div", _hoisted_31, "这是提示信息", 512), [[_vShow, $data.toolTipShow]])]),
    _: 1
  }, 8, ["element-loading-text"])), [[_directive_loading, $data.refresh]]);
}