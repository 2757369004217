import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  style: {
    "width": "300px",
    "border": "4px solid #F4F4F4",
    "background-color": "white"
  },
  class: "ticketBody"
};
const _hoisted_2 = {
  style: {
    "margin": "10px"
  }
};
const _hoisted_3 = {
  style: {
    "font-size": "14px",
    "font-weight": "bold",
    "color": "black",
    "text-align": "center",
    "margin-bottom": "12px"
  },
  class: "ticketCard"
};
const _hoisted_4 = {
  style: {
    "font-size": "12px",
    "font-weight": "bold",
    "color": "black",
    "width": "95%",
    "overflow": "hidden",
    "white-space": "nowrap",
    "text-overflow": "ellipsis",
    "height": "18px",
    "line-height": "18px"
  },
  class: "ticketTitle"
};
const _hoisted_5 = {
  class: "status"
};
const _hoisted_6 = {
  style: {
    "margin": "10px 0",
    "display": "flex",
    "align-items": "center"
  }
};
const _hoisted_7 = ["src"];
const _hoisted_8 = {
  style: {
    "margin-left": "10px",
    "font-size": "12px",
    "color": "gray",
    "white-space": "nowrap"
  }
};
const _hoisted_9 = {
  style: {
    "margin-top": "10px"
  }
};
const _hoisted_10 = {
  style: {
    "margin": "6px 0",
    "height": "18px",
    "display": "flex",
    "align-items": "center"
  }
};
const _hoisted_11 = {
  style: {
    "display": "inline-block",
    "font-size": "12px",
    "color": "black",
    "height": "18px",
    "line-height": "18px"
  },
  class: "purchaseChannel"
};
const _hoisted_12 = {
  class: "col_gray channelName",
  style: {
    "margin-left": "8px",
    "color": "gray",
    "font-size": "12px",
    "display": "inline-block",
    "height": "18px",
    "line-height": "18px",
    "width": "40%",
    "overflow": "hidden",
    "white-space": "=nowrap",
    "text-overflow": "ellipsis"
  }
};
const _hoisted_13 = {
  key: 0
};
const _hoisted_14 = {
  style: {
    "margin": "6px 0",
    "height": "18px"
  }
};
const _hoisted_15 = {
  style: {
    "font-size": "12px",
    "color": "black"
  },
  class: "orderNum"
};
const _hoisted_16 = {
  class: "col_gray",
  style: {
    "margin-left": "15px",
    "color": "gray",
    "font-size": "12px"
  }
};
const _hoisted_17 = {
  style: {
    "margin": "6px 0",
    "height": "18px"
  }
};
const _hoisted_18 = {
  style: {
    "font-size": "12px",
    "color": "black"
  },
  class: "paymentTime"
};
const _hoisted_19 = {
  class: "col_gray",
  style: {
    "margin-left": "15px",
    "color": "gray",
    "font-size": "12px"
  }
};
const _hoisted_20 = ["templateId"];
const _hoisted_21 = {
  key: 0,
  class: "chatBtnDiv",
  style: {
    "display": "flex",
    "justify-content": "center",
    "margin": "20px 0 15px 0"
  }
};
const _hoisted_22 = {
  key: 1,
  class: "chatBtnDiv",
  style: {
    "display": "flex",
    "justify-content": "center",
    "margin": "20px 0 15px 0"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button");

  return _openBlock(), _createElementBlock("p", null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString($data.dptData.formTitle), 1), _createElementVNode("div", _hoisted_4, _toDisplayString($data.dptData.tempItemName), 1), _createElementVNode("span", _hoisted_5, [_createElementVNode("div", {
    style: _normalizeStyle([{
      "font-size": "12px",
      "margin-top": "6px"
    }, {
      color: $options.getColorByStatus($props.tempTicketData.status)
    }]),
    class: "ticketTempStatus"
  }, _toDisplayString($options.getStatusNameByStatus($props.tempTicketData.status)), 5)]), _createElementVNode("div", _hoisted_6, [_createElementVNode("img", {
    src: `/static/${$data.dptData.pictureUrl}`,
    alt: "",
    style: {
      "width": "50px",
      "height": "40px",
      "object-fit": "cover"
    }
  }, null, 8, _hoisted_7), _createElementVNode("div", _hoisted_8, _toDisplayString($data.dptData.productionName), 1)]), _createElementVNode("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, [_createElementVNode("span", _hoisted_11, _toDisplayString($data.dptData.formPurchasingChannel), 1), _createElementVNode("span", _hoisted_12, _toDisplayString($data.dptData.purchasingChannelsName), 1)]), $data.dptData.purchasingChannels === '1' ? (_openBlock(), _createElementBlock("div", _hoisted_13, [_createElementVNode("div", _hoisted_14, [_createElementVNode("span", _hoisted_15, _toDisplayString($data.dptData.formOrderNum), 1), _createElementVNode("span", _hoisted_16, _toDisplayString($data.dptData.orderNum), 1)]), _createElementVNode("div", _hoisted_17, [_createElementVNode("span", _hoisted_18, _toDisplayString($data.dptData.formPaymentTime), 1), _createElementVNode("span", _hoisted_19, _toDisplayString($data.dptData.paymentTime != "null" ? $data.dptData.paymentTime : $data.dptData.paymentTime), 1)])])) : _createCommentVNode("", true)]), _createElementVNode("span", {
    style: {
      "display": "none"
    },
    class: "saveId",
    templateId: $data.dptData.id
  }, null, 8, _hoisted_20), $props.isAdmin ? (_openBlock(), _createElementBlock("div", _hoisted_21, [_createVNode(_component_el_button, {
    type: "danger",
    style: {
      "background-color": "rgba(223,48,51)"
    },
    class: "chatBtn",
    id: $data.dptData.id
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString($data.dptData.formBtnView), 1)]),
    _: 1
  }, 8, ["id"])])) : (_openBlock(), _createElementBlock("div", _hoisted_22, [$data.dptData.status == '1' ? (_openBlock(), _createBlock(_component_el_button, {
    key: 0,
    type: "danger",
    style: {
      "background-color": "rgba(223,48,51)",
      "min-width": "90px"
    },
    class: "chatBtn",
    id: $props.tempTicketData.id
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString($data.dptData.formBtnWrite), 1)]),
    _: 1
  }, 8, ["id"])) : _createCommentVNode("", true), $data.dptData.status == '2' || $data.dptData.status == '3' || $data.dptData.status == '4' || $data.dptData.status == '5' ? (_openBlock(), _createBlock(_component_el_button, {
    key: 1,
    type: "danger",
    style: {
      "background-color": "rgba(223,48,51)",
      "min-width": "90px"
    },
    class: "chatBtn",
    id: $props.tempTicketData.id
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString($data.dptData.formBtnView), 1)]),
    _: 1
  }, 8, ["id"])) : _createCommentVNode("", true), $data.dptData.status == '3' ? (_openBlock(), _createBlock(_component_el_button, {
    key: 2,
    type: "danger",
    style: {
      "background-color": "rgba(223,48,51)",
      "min-width": "90px"
    },
    class: "chatBtn",
    id: $props.tempTicketData.id
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString($data.dptData.formBtnAgainWrite), 1)]),
    _: 1
  }, 8, ["id"])) : _createCommentVNode("", true)]))])])]);
}