import { mapState, mapGetters } from 'vuex';
import MultipleSelect from '@/control/MultipleSelect.vue';
import MultipleOption from '@/control/MultipleOption.vue';
import Editor from '@/control/Editor.vue';
import Modal from '@/control/Modal.vue';
import StepType from '@/control/StepType.vue';
export default {
  components: {
    MultipleSelect,
    MultipleOption,
    Editor,
    Modal,
    StepType
  },

  data() {
    return {
      itemSelectedArr: [],
      itemModal: {
        show: false,
        itemArray: [],
        item: {
          name: ''
        }
      }
    };
  },

  computed: { ...mapState({
      user: 'user',
      item: 'item',
      userType: 'userType',
      stepType: 'stepType'
    }),
    ...mapGetters({
      getValidItem: 'getValidItem',
      catalog: 'getCatalog',
      question: 'getQuestion'
    })
  },
  inject: ['uploadSuccess', 'uploadFailed'],
  watch: {
    getValidItem: {
      handler(nv, ov) {
        this.itemModal.itemArray = _.cloneDeep(nv);
      },

      deep: true
    }
  },

  mounted() {},

  methods: {
    clickToAfterSales() {
      // this.$router.push({path:'/admin/chat'});
      // var route = this.$router
      window.open('/admin/chat');
    },

    changeItem(question, {
      nv,
      ov
    }) {
      // 新增关联产品
      var add = _.difference(nv, ov); // 移除关联产品


      var del = _.difference(ov, nv);

      this.$axios.post('/api/admin/KnowledgeBase/changeQuestionItem', {
        question_id: question.id,
        add: add,
        del: del
      });
    },

    changeQuestion(id, change) {
      this.$axios.post('/api/admin/KnowledgeBase/changeQuestion', {
        id: id,
        change: change
      }).then(res => {
        this.uploadSuccess(); // this.uploadFailed(this.changeQuestion,id,change);
      }).catch(err => {
        this.uploadFailed(this.changeQuestion, id, change);
      });
    },

    changeQuestionTitle(question) {
      this.changeQuestion(question.id, {
        title: question.title
      });
    },

    changeQuestionDescription(e, question) {
      this.changeQuestion(question.id, {
        description: question.description
      });
    },

    clickAddQuestionStep(question) {
      this.$axios.post('/api/admin/KnowledgeBase/addQuestionStep', {
        question_id: question.id,
        type: question.newType,
        index: Object.keys(question.questionStep).length
      });
    },

    clickDeleteQuestionStep(questionStep) {
      this.$axios.post('/api/admin/KnowledgeBase/deleteQuestionStep', {
        id: questionStep.id
      });
    },

    changeAnswerStep(id, change) {
      this.$axios.post('/api/admin/KnowledgeBase/changeAnswerStep', {
        id: id,
        change: change
      }).then(res => {
        this.uploadSuccess();
      }).catch(err => {
        this.uploadFailed(this.changeAnswerStep, id, change);
      });
    },

    clickValid(question) {
      if (this.user.is_readonly) {
        return;
      }

      question.valid = !question.valid;
      this.changeQuestion(question.id, {
        valid: question.valid ? 1 : 0
      });
    },

    getEditOptions(question, questionStep) {
      var editorOptions = {
        modules: {
          toolbar: {
            container: [['bold', 'italic', 'underline', 'strike'], ['blockquote', 'code-block'], [{
              header: 1
            }, {
              header: 2
            }], [{
              list: 'ordered'
            }, {
              list: 'bullet'
            }], [{
              script: 'sub'
            }, {
              script: 'super'
            }], [{
              indent: '-1'
            }, {
              indent: '+1'
            }], [{
              direction: 'rtl'
            }], [{
              size: ['small', false, 'large', 'huge']
            }], [{
              header: [1, 2, 3, 4, 5, 6, false]
            }], [{
              color: []
            }, {
              background: []
            }], [{
              font: []
            }], [{
              align: []
            }], ['clean'], ['link', 'image', 'video', 'video-link']],
            handlers: {
              image: this.imgHandler(question, questionStep),
              video: this.videoHandle(question, questionStep),
              'video-link': this.videoLinkHandle(question, questionStep)
            }
          } // imageDrop: true,
          // imageResize: true,

        },
        theme: 'snow'
      };
      return editorOptions;
    },

    clickItemModal() {
      if (this.user.is_readonly) {
        return;
      }

      this.itemModal.itemArray = _.cloneDeep(this.getValidItem);
      this.itemModal.show = true;
    },

    clickChangeAllItem() {
      var changeArray = [];

      for (let _item of this.itemModal.itemArray) {
        if (_item.description != _item._description) {
          changeArray.push({
            id: _item.id,
            description: _item.description
          });
        }
      }

      if (changeArray.length == 0) {
        return;
      }

      this.$axios.post("/api/admin/KnowledgeBase/changeAllItem", {
        changeArray: changeArray
      }).then(res => {
        this.uploadSuccess();
      }).catch(err => {
        this.uploadFailed(this.clickChangeAllItem);
      });
    },

    clickSyncItemInfo() {
      this.$axios.post("/api/crontab/syncItemInfo", {});
    }

  }
};