export default {
  data() {
    return {
      loading: false,
      svg: `
        <path class="path" d="
          M 30 15
          L 28 17
          M 25.61 25.61
          A 15 15, 0, 0, 1, 15 30
          A 15 15, 0, 1, 1, 27.99 7.5
          L 15 15
        " style="stroke-width: 4px; fill: rgba(0, 0, 0, 0)"/>
      `,
      treeListData: [{
        label: '一级标题',
        children: [{
          label: '1-1',
          children: [{
            label: '1-1-1'
          }]
        }]
      }, {
        label: '一级标题',
        children: [{
          label: '1-1',
          children: [{
            label: '1-1-1'
          }]
        }]
      }, {
        label: '一级标题',
        children: [{
          label: '1-1',
          children: [{
            label: '1-1-1'
          }]
        }]
      }],
      language: [{
        id: 0,
        label: '中文'
      }, {
        id: 1,
        label: '英文'
      }],
      languageSelect: 0,
      isSelect: false,
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      questionListData: [],
      directoryId: null,
      pIdByTree: null,
      idInPid: {},
      sopDirectoryId: 0,
      questionObj: {},
      questionOrderNo: null,
      questionTitle: '',
      answerList: [],
      questionId: 1,
      questionData: null,
      updateTimer: {},
      refresh: false,
      questionDefaultProps: {
        label: 'label'
      }
    };
  },

  components: {},
  computed: {
    questionList() {
      const questionList = [];

      for (const question of this.questionListData) {
        question.label = question.title;
        const answerList = question.answerList;

        for (const answer of answerList) {
          this.idInPid[answer.id] = answer.question_id;
          answer.label = answer.content;
        }

        questionList.push(question);
      }

      return questionList;
    }

  },

  mounted() {
    this.getAllDirectoryList();
  },

  methods: {
    // 获取所有目录树形结构
    async getAllDirectoryList() {
      const res = await this.$axios.get('/java/admin/getAllDirectoryList');

      if (res.status !== 200 || res.data.errno) {
        return;
      }

      this.treeListData = res.data.data.children;
    },

    // 通过点击树形目录，根据目录id获取问题列表
    async clickColumnData(data) {
      this.directoryId = data.id;
      this.pIdByTree = data.pid;
      this.sopDirectoryId = this.$refs.treeList.getCurrentKey();
      const res = await this.$axios.get('/java/admin/getAllQuestionListByDirectoryId?sopDirectoryId=' + this.sopDirectoryId);

      if (res.status !== 200 || res.data.errno) {
        return;
      }

      this.questionListData = res.data.data;
      this.clickQuestionList(data);
    },

    // 点击问题列表
    clickQuestionList(data, node, event) {
      this.questionOrderNo = data.order_no;
      this.questionTitle = data.title;
      this.answerList = data.answerList;
      this.questionId = data.id;
      this.questionData = data;
      console.log('=====', data);
    },

    clickReturn() {
      this.loading = true;
      setTimeout(() => {
        this.$router.go(-1);
      }, 100);
    },

    clickSelectLanguage(item) {
      this.languageSelect = item.id;
    }

  }
};