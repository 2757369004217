import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelText as _vModelText, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, vModelSelect as _vModelSelect, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-8a9a5526"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "table-self",
  style: {
    "width": "40vw",
    "max-height": "80vh",
    "overflow": "hidden scroll"
  }
};
const _hoisted_2 = {
  class: "thead-self"
};
const _hoisted_3 = {
  class: "tr-self",
  style: {
    "grid-template-columns": "10% 30% 60%"
  }
};
const _hoisted_4 = {
  class: "th-self"
};
const _hoisted_5 = {
  class: "th-self"
};
const _hoisted_6 = {
  class: "th-self"
};
const _hoisted_7 = {
  class: "tbody-self"
};
const _hoisted_8 = {
  class: "tr-self",
  style: {
    "grid-template-columns": "10% 30% 60%"
  }
};
const _hoisted_9 = {
  class: "td-self"
};
const _hoisted_10 = {
  class: "td-self"
};
const _hoisted_11 = ["set"];
const _hoisted_12 = ["onUpdate:modelValue"];
const _hoisted_13 = {
  style: {
    "width": "100%"
  },
  class: "flex-between"
};
const _hoisted_14 = {
  class: "flex-center",
  style: {
    "width": "100%"
  }
};
const _hoisted_15 = {
  key: 0,
  style: {
    "width": "90%"
  }
};
const _hoisted_16 = ["disabled"];
const _hoisted_17 = {
  style: {
    "width": "100%",
    "display": "flex",
    "justify-content": "space-between",
    "margin-top": "1rem"
  }
};
const _hoisted_18 = {
  style: {
    "width": "40%"
  }
};
const _hoisted_19 = {
  style: {
    "width": "fit-content",
    "font-weight": "bold"
  }
};
const _hoisted_20 = {
  style: {
    "width": "100%"
  }
};
const _hoisted_21 = ["disabled"];
const _hoisted_22 = {
  style: {
    "width": "40%"
  }
};
const _hoisted_23 = {
  style: {
    "width": "fit-content",
    "font-weight": "bold"
  }
};
const _hoisted_24 = ["disabled"];
const _hoisted_25 = {
  style: {
    "width": "100%",
    "height": "2rem"
  }
};
const _hoisted_26 = {
  style: {
    "width": "100%",
    "margin-top": "1rem"
  }
};
const _hoisted_27 = {
  style: {
    "width": "fit-content",
    "font-weight": "bold"
  }
};
const _hoisted_28 = {
  style: {
    "width": "100%"
  }
};
const _hoisted_29 = {
  style: {
    "width": "100%"
  }
};
const _hoisted_30 = {
  style: {
    "width": "100%",
    "margin-top": "1rem"
  }
};
const _hoisted_31 = {
  style: {
    "width": "100%",
    "font-weight": "bold",
    "display": "flex",
    "justify-content": "space-between"
  }
};
const _hoisted_32 = ["onClick", "disabled"];
const _hoisted_33 = {
  style: {
    "width": "100%",
    "border": "1px solid #000"
  }
};
const _hoisted_34 = {
  class: "flex-end",
  style: {
    "width": "100%",
    "margin-top": "1rem",
    "height": "3rem",
    "align-items": "center"
  }
};
const _hoisted_35 = ["set"];
const _hoisted_36 = ["disabled"];
const _hoisted_37 = ["value"];
const _hoisted_38 = ["disabled"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Modal = _resolveComponent("Modal");

  const _component_MultipleOption = _resolveComponent("MultipleOption");

  const _component_MultipleSelect = _resolveComponent("MultipleSelect");

  const _component_Editor = _resolveComponent("Editor");

  const _component_StepType = _resolveComponent("StepType");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_Modal, {
    show: $data.itemModal.show,
    "onUpdate:show": _cache[2] || (_cache[2] = $event => $data.itemModal.show = $event)
  }, {
    title: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx._t('itemManage')), 1)]),
    body: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString(_ctx._t('id')), 1), _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx._t('item')), 1), _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx._t('description')), 1)])]), _createElementVNode("div", _hoisted_7, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.itemModal.itemArray, (_item, _itemIndex) => {
      return _openBlock(), _createElementBlock("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, _toDisplayString(_itemIndex + 1), 1), _createElementVNode("div", _hoisted_10, _toDisplayString(_item.name), 1), _createElementVNode("div", {
        class: "td-self",
        set: _item._description = _item._description == undefined ? _item.description || '' : _item._description
      }, [_withDirectives(_createElementVNode("input", {
        type: "text",
        class: "input-self",
        style: _normalizeStyle([{
          "width": "90%"
        }, {
          color: _item.description != _item._description ? 'tomato' : ''
        }]),
        "onUpdate:modelValue": $event => _item.description = $event
      }, null, 12, _hoisted_12), [[_vModelText, _item.description]])], 8, _hoisted_11)]);
    }), 256))])])]),
    footer: _withCtx(() => [_createElementVNode("div", _hoisted_13, [_createElementVNode("button", {
      class: "btn btn-self",
      style: {},
      onClick: _cache[0] || (_cache[0] = $event => $options.clickSyncItemInfo())
    }, _toDisplayString(_ctx._t('syncItemInfo')), 1), _createElementVNode("button", {
      class: "btn btn-self",
      style: {},
      onClick: _cache[1] || (_cache[1] = $event => $options.clickChangeAllItem())
    }, _toDisplayString(_ctx._t('change')), 1)])]),
    _: 1
  }, 8, ["show"]), _createElementVNode("div", _hoisted_14, [_ctx.question ? (_openBlock(), _createElementBlock("div", _hoisted_15, [_ctx.question.valid ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    disabled: _ctx.user.is_readonly,
    onClick: _cache[3] || (_cache[3] = $event => $options.clickValid(_ctx.question)),
    class: "flex-center",
    style: {
      "width": "100%",
      "height": "2rem",
      "border": "1px solid #000",
      "margin-top": "1rem",
      "background-color": "#999",
      "color": "#fff",
      "cursor": "pointer"
    }
  }, _toDisplayString(_ctx._t('begEdit')), 9, _hoisted_16)) : (_openBlock(), _createElementBlock("div", {
    key: 1,
    onClick: _cache[4] || (_cache[4] = $event => $options.clickValid(_ctx.question)),
    class: "flex-center",
    style: {
      "width": "100%",
      "height": "2rem",
      "border": "1px solid #000",
      "margin-top": "1rem",
      "background-color": "#0c0",
      "color": "#fff",
      "cursor": "pointer"
    }
  }, _toDisplayString(_ctx._t('endEdit')), 1)), _createElementVNode("div", _hoisted_17, [_createElementVNode("div", _hoisted_18, [_createElementVNode("div", _hoisted_19, [_createElementVNode("span", null, _toDisplayString(_ctx._t('title')), 1)]), _createElementVNode("div", _hoisted_20, [_withDirectives(_createElementVNode("input", {
    type: "text",
    class: "input-self",
    style: {
      "width": "100%",
      "height": "2rem"
    },
    "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => _ctx.question.title = $event),
    onChange: _cache[6] || (_cache[6] = $event => $options.changeQuestionTitle(_ctx.question)),
    disabled: _ctx.question.valid || _ctx.user.is_readonly
  }, null, 40, _hoisted_21), [[_vModelText, _ctx.question.title]])])]), _createElementVNode("div", _hoisted_22, [_createElementVNode("div", _hoisted_23, [_createElementVNode("span", null, _toDisplayString(_ctx._t('linkItem')), 1), _createElementVNode("span", {
    class: "bi bi-gear tree-setting",
    disabled: _ctx.user.is_readonly,
    style: {
      "cursor": "pointer"
    },
    onClick: _cache[7] || (_cache[7] = $event => $options.clickItemModal())
  }, null, 8, _hoisted_24)]), _createElementVNode("div", _hoisted_25, [_createVNode(_component_MultipleSelect, {
    modelValue: _ctx.question.item,
    "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => _ctx.question.item = $event),
    onChange: _cache[9] || (_cache[9] = $event => $options.changeItem(_ctx.question, $event)),
    disabled: _ctx.question.valid,
    selectAll: true
  }, {
    default: _withCtx(() => [_createVNode(_component_MultipleOption, {
      value: 0
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx._t('allItem')), 1)]),
      _: 1
    }), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getValidItem, _item => {
      return _openBlock(), _createBlock(_component_MultipleOption, {
        value: _item.id
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_item.name) + _toDisplayString(_item.description ? '(' + _item.description + ')' : ''), 1)]),
        _: 2
      }, 1032, ["value"]);
    }), 256))]),
    _: 1
  }, 8, ["modelValue", "disabled"])])])]), _createElementVNode("div", _hoisted_26, [_createElementVNode("div", _hoisted_27, _toDisplayString(_ctx._t("description")), 1), _createElementVNode("div", _hoisted_28, [_createVNode(_component_Editor, {
    modelValue: _ctx.question.description,
    "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => _ctx.question.description = $event),
    onChange: _cache[11] || (_cache[11] = $event => $options.changeQuestionDescription($event, _ctx.question)),
    disabled: _ctx.question.valid || _ctx.user.is_readonly
  }, null, 8, ["modelValue", "disabled"])])]), _createElementVNode("div", _hoisted_29, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.question.questionStep, _questionStep => {
    return _openBlock(), _createElementBlock("div", _hoisted_30, [_createElementVNode("div", _hoisted_31, [_createElementVNode("div", null, [_createElementVNode("span", null, _toDisplayString(_ctx._t("step")) + _toDisplayString(Number(_questionStep.index) + 1), 1)]), _createElementVNode("div", null, [_createElementVNode("span", null, _toDisplayString(_ctx._t("type")) + ":" + _toDisplayString(_questionStep.type), 1)]), _createElementVNode("div", null, [_createElementVNode("button", {
      class: "btn-close",
      onClick: $event => $options.clickDeleteQuestionStep(_questionStep),
      disabled: _ctx.question.valid || _ctx.user.is_readonly
    }, null, 8, _hoisted_32)])]), _createElementVNode("div", _hoisted_33, [_createVNode(_component_StepType, {
      question: _ctx.question,
      step: _questionStep,
      disabled: _ctx.question.valid || _ctx.user.is_readonly
    }, null, 8, ["question", "step", "disabled"])])]);
  }), 256))]), _createElementVNode("div", _hoisted_34, [_createElementVNode("div", {
    set: _ctx.question.newType = _ctx.question.newType || 'questionAnswer'
  }, [_createElementVNode("span", null, _toDisplayString(_ctx._t("type")) + ":", 1), _withDirectives(_createElementVNode("select", {
    "onUpdate:modelValue": _cache[12] || (_cache[12] = $event => _ctx.question.newType = $event),
    disabled: _ctx.question.valid || _ctx.user.is_readonly,
    style: {
      "height": "2rem",
      "margin-right": "1rem"
    }
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stepType, (_type, _typeIndex) => {
    return _openBlock(), _createElementBlock("option", {
      value: _type
    }, _toDisplayString(_type), 9, _hoisted_37);
  }), 256))], 8, _hoisted_36), [[_vModelSelect, _ctx.question.newType]])], 8, _hoisted_35), _createElementVNode("button", {
    class: "btn btn-self",
    style: {
      "height": "2rem"
    },
    onClick: _cache[13] || (_cache[13] = $event => $options.clickAddQuestionStep(_ctx.question)),
    disabled: _ctx.question.valid || _ctx.user.is_readonly
  }, _toDisplayString(_ctx._t("addStep")), 9, _hoisted_38)])])) : _createCommentVNode("", true)])], 64);
}