import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_FilterPanel = _resolveComponent("FilterPanel");

  const _component_el_row = _resolveComponent("el-row");

  return _openBlock(), _createBlock(_component_el_row, {
    type: "flex",
    justify: "start",
    align: "top",
    style: {
      "padding": "0 8px",
      "flex-wrap": "nowrap"
    }
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.questionTypeOptionsByLevel, (_options, _optionsLevel) => {
      return _openBlock(), _createElementBlock(_Fragment, null, [_optionsLevel > 0 ? (_openBlock(), _createBlock(_component_FilterPanel, {
        key: 0,
        selected: $data.questionTypeSelectedByLevel[_optionsLevel],
        "onUpdate:selected": [$event => $data.questionTypeSelectedByLevel[_optionsLevel] = $event, $options.updateSelected],
        options: _options,
        label: "title",
        title: 'QuestionType' + Number(_optionsLevel)
      }, null, 8, ["selected", "onUpdate:selected", "options", "title"])) : _createCommentVNode("", true)], 64);
    }), 256))]),
    _: 1
  });
}