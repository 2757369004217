import templateStatusShow from './templateStatusShow.vue';
export default {
  components: {
    templateStatusShow
  },
  props: {
    isVideo: {
      type: Boolean,
      default: false
    },
    listIndex: {
      type: Number,
      default: null
    },
    dpt: {
      type: Object,
      default: {}
    },
    userIsView: {
      type: Boolean,
      default: false
    },
    submitForm: {
      type: Array,
      default: () => {
        return [];
      }
    },
    damageReportDir: {
      type: Array,
      default: () => {
        return [];
      }
    },
    damageRTextL: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },

  data() {
    return {
      imgArrayE: [],
      imgViewerShow: false,
      imgIndex: null,
      imgArray: [],
      view: false,
      Edit: false,
      itemListCopy: [],
      answerObj: {
        id: null,
        contentItemId: "",
        url: "",
        content: ""
      },
      dataInfo: [{
        imageUrl: '',
        inputText: '',
        required: false
      }],
      rules: {
        name: [{
          required: true,
          message: '请输入标题',
          trigger: ['change', 'blur']
        }]
      }
    };
  },

  watch: {},
  computed: {},
  methods: {
    //清除图片
    clearImg(index) {
      // console.log(index,);
      this.itemListCopy[index].url = '';
      this.autoSave();
    },

    //重新填报的时候自动回显 自动存储一次
    autoSave() {
      // console.log(this.answerObj,'answerObjanswerObj');
      let newObj = {
        id: this.dpt.tempItemContentList[this.listIndex - 1].answerVo.id,
        contentItemId: "",
        url: '',
        content: ""
      }; //加上id 回显的时候判断回显的位置

      for (let i of this.itemListCopy) {
        //10/9修改
        if (i.url) {
          newObj.url += i.url + ':' + i.id + ';';
        } else {} //10/9修改

      }

      newObj.url = JSON.stringify(newObj.url);
      this.submitForm[this.listIndex - 1] = newObj; // this.$eventBus.emit('saveData', { answerObj: newObj, index: this.listIndex - 1 })
    },

    //
    //根据状态返回不同的class
    getClassByStatus(status) {
      if (status == '1') {
        return 'col_rred';
      } else if (status == '2') {
        return 'col_ggreen';
      }
    },

    //点击显示图片
    clickShowImage(index) {
      if (this.dpt.status) {
        // console.log(index, 'index');
        this.imgIndex = index;
        this.imgViewerShow = true;
      }
    },

    //
    replacePath(path) {
      return path.replace('/ipfs/', '/static/');
    },

    handleAvatarSuccess(res, index) {
      console.log(res, 'resssssss'); // this.answerObj.url = 

      if (res.code != 200) {
        return;
      }

      this.itemListCopy[index].url = '/static/' + res.data; // console.log(this.answerObj,'answerObjanswerObj');

      let newObj = {
        id: this.dpt.tempItemContentList[this.listIndex - 1].answerVo.id,
        contentItemId: "",
        url: '',
        content: ""
      }; //加上id 回显的时候判断回显的位置

      for (let i of this.itemListCopy) {
        newObj.url += i.url + ':' + i.id + ';';
      }

      newObj.url = JSON.stringify(newObj.url);
      this.submitForm[this.listIndex - 1] = newObj; // console.log(this.submitForm, 'this.submitForm');
      // this.$eventBus.emit('saveData', { answerObj: newObj, index: this.listIndex - 1 })
    },

    beforeAvatarUpload(file) {
      console.log('updattte');

      if (this.isVideo) {
        if (file.type === 'video/quicktime' || file.type === 'video/avi' || file.type === 'video/x-matroska' || file.type === 'video/x-ms-wmv' || file.type === 'video/mp4') {} else {
          this.$message.error('请上传MP4, mov, avi, mkv, wmv, flv, m4v视频格式');
          return false;
        }
      } else {
        if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif' || file.type === 'image/bmp' || file.type === 'image/tiff' || file.type === 'image/svg+xml') {} else {
          this.$message.error('请上传jpeg(jpg, jpeg), png, gif, bmp, tiff, svg图片格式');
          return false;
        }
      } // 'image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/tiff', 'image/svg+xml'
      // 'video/quicktime', 'video/avi', 'video/x-matroska', 'video/x-ms-wmv', 'video/mp4'

    }

  },

  created() {
    this.dataInfo = JSON.parse(JSON.stringify(this.dpt.tempItemContentList[this.listIndex - 1]));

    if (!this.dataInfo.itemList.length) {
      let firstObj = {
        urls: '',
        name: ''
      };
      this.dataInfo.itemList.push(firstObj);
    } else {
      for (let i of this.dataInfo.itemList) {
        this.imgArrayE.push(i.url.replace('/ipfs/', '/static/'));
      } // console.log(this.imgArrayE,'this.imgArrayE');

    } //再复制一份装用户上传的url


    this.itemListCopy = JSON.parse(JSON.stringify(this.dataInfo.itemList)); //清空里面的url

    for (let i of this.itemListCopy) {
      i.url = '';
    }

    if (this.dataInfo.isEssential) {
      this.dataInfo.isEssential = true;
    } else {
      this.dataInfo.isEssential = false;
    }

    this.Edit = this.dpt.status === '1';

    if (this.Edit) {} else {
      //判断用户是否填写 未填写不必回显
      if (this.dataInfo.answerVo.url && this.dataInfo.answerVo.url != 'null') {
        //回填里面的url  先拆分url 再拆分id
        let urlList = JSON.parse(this.dataInfo.answerVo.url).split(';').slice(0, -1);

        for (let i of urlList) {
          let urlItem = i.split(':');

          for (let x of this.itemListCopy) {
            if (x.id === Number(urlItem[1])) {
              x.url = urlItem[0];
              this.imgArray.push(urlItem[0]);
            }
          }
        }
      } // for (let i of this.itemListCopy) {
      //     i.url = ''
      // }

    }
  },

  mounted() {
    //从新填报的时候
    if (this.dpt.status == '3' || this.dpt.status == '1' && !this.userIsView) {
      this.autoSave();
    }
  }

};