export default {
  data() {
    return {
      treeListData: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      directoryId: null,
      questionListData: [],
      questionId: null,
      idInPid: {}
    };
  },

  components: {},
  computed: {
    questionList() {
      const questionList = [];

      for (const question of this.questionListData) {
        question.label = question.title;
        const answerList = question.answerList;

        for (const answer of answerList) {
          this.idInPid[answer.id] = answer.question_id;
          answer.label = answer.content;
        }

        questionList.push(question);
      }

      return questionList;
    }

  },

  mounted() {
    this.getAllDirectoryList();
  },

  methods: {
    async getAllDirectoryList() {
      const res = await this.$axios.get('/java/admin/getAllDirectoryList');

      if (res.status !== 200 || res.data.errno) {
        return;
      }

      this.treeListData = res.data.data.children; // console.log('列表', res);
    },

    async clickColumnData(data) {
      console.log(data);
      this.directoryId = data.id;
      this.$emit('getDirectoryIdInChild', this.directoryId, this.questionId);
      const res = await this.$axios.get('/java/admin/getAllQuestionListByDirectoryId?sopDirectoryId=' + this.directoryId);

      if (res.status !== 200 || res.data.errno) {
        return;
      } // this.clickQuestionList(data)


      this.questionListData = res.data.data;
    },

    clickQuestionList(data) {
      if (this.questionId == data.id) {
        this.$refs.questionListRef.setCurrentKey(null, false);
        this.questionId = null;
      } else {
        this.questionId = data.id;
      }

      this.$emit('getDirectoryIdInChild', this.directoryId, this.questionId);
    }

  }
};