import { mapState } from 'vuex';
export default {
  props: {
    show: {
      type: Boolean,

      default() {
        return false;
      }

    },
    btnCloseShow: {
      type: Boolean,

      default() {
        return true;
      }

    }
  },
  emits: {
    'update:show': state => {
      if (typeof state === 'boolean') {
        return true;
      } else {
        console.warn('type of modal show is not boolean!');
        return false;
      }
    }
  },

  data() {
    return {};
  },

  components: {},
  computed: { ...mapState({
      isPC: 'isPC'
    })
  },

  mounted() {},

  methods: {
    closeModal() {
      this.$emit('update:show', false);
    }

  }
};