import { Quill } from 'vue-quill-editor'
import videoLinkIcon from "@/assets/video-link.svg"
const Embed = Quill.import('blots/embed')
// const BlockEmbed = Quill.import('blots/block/embed')
const Link = Quill.import('formats/link')
const Icons = Quill.import('ui/icons')
//加载video-link的图标
Icons['video-link'] = videoLinkIcon

const ATTRIBUTES = [
  'height',
  'width'
];

class VideoLink extends Embed {
  static create(value) {
    let node = super.create(value);
    node.setAttribute('frameborder', '0');
    node.setAttribute('allowfullscreen', true);
    node.setAttribute('src', this.sanitize(value));
    return node;
  }

  static formats(domNode) {
    return ATTRIBUTES.reduce(function(formats, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }

  static sanitize(url) {
    return Link.sanitize(url);
  }

  static value(domNode) {
    return domNode.getAttribute('src');
  }

  format(name, value) {
    if (ATTRIBUTES.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}
VideoLink.className = 'ql-video-link';
VideoLink.blotName = 'video-link';
VideoLink.tagName = 'IFRAME';

export default VideoLink