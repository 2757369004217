import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-8d6a5c74"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "flex-start",
  style: {
    "width": "100%"
  }
};
const _hoisted_2 = {
  class: "flex-start",
  style: {
    "width": "100%",
    "padding": "4px 0"
  }
};
const _hoisted_3 = {
  class: "flex-between",
  style: {
    "width": "100%",
    "align-content": "center",
    "border-bottom": "1px dashed #999",
    "flex-wrap": "nowrap"
  }
};
const _hoisted_4 = ["onClick", "title"];
const _hoisted_5 = {
  style: {
    "width": "100%",
    "padding-left": "1rem"
  }
};
const _hoisted_6 = ["onClick", "title"];
const _hoisted_7 = {
  class: "flex-end",
  style: {
    "align-items": "flex-start"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Tree = _resolveComponent("Tree", true);

  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.nodeObj, _node => {
    return _openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", {
      class: _normalizeClass([$options.getNodeClass(_node), "type-row"]),
      onClick: $event => {
        $options.clickFold(_node);
        $options.clickNode(_node);
      },
      title: _node.title
    }, _toDisplayString(_node.title), 11, _hoisted_4), _createElementVNode("div", null, [_renderSlot(_ctx.$slots, "node", {
      nodeTarget: _node
    }, undefined, true)])]), !$options.getNodeFold(_node) ? (_openBlock(), _createElementBlock(_Fragment, {
      key: 0
    }, [_node.child ? (_openBlock(), _createBlock(_component_Tree, {
      key: 0,
      node: _node.child,
      idName: $props.idName,
      titleName: $props.titleName,
      navName: $props.navName,
      childName: $props.childName,
      elementName: $props.elementName,
      nav: $props.nav,
      unfoldDegree: $props.unfoldDegree,
      elementId: $props.elementId,
      onClickNode: $options.clickNode,
      onClickElement: $options.clickElement,
      style: {
        "padding-left": "1rem"
      }
    }, {
      node: _withCtx(({
        nodeTarget
      }) => [_renderSlot(_ctx.$slots, "node", {
        nodeTarget: nodeTarget
      }, undefined, true)]),
      element: _withCtx(({
        nodeTarget,
        elementTarget
      }) => [_renderSlot(_ctx.$slots, "element", {
        nodeTarget: nodeTarget,
        elementTarget: elementTarget
      }, undefined, true)]),
      _: 2
    }, 1032, ["node", "idName", "titleName", "navName", "childName", "elementName", "nav", "unfoldDegree", "elementId", "onClickNode", "onClickElement"])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_node.element, _el => {
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass([{
          'hightlight': $props.elementId == _el.id
        }, "flex-between"]),
        style: {
          "width": "100%",
          "align-content": "center",
          "flex-wrap": "nowrap",
          "border-bottom": "1px dashed #999"
        }
      }, [_createElementVNode("div", {
        class: _normalizeClass(["bi bi-file-earmark-richtext question-row", {
          'bi-green-self': _el[$props.editingName]
        }]),
        onClick: $event => $options.clickElement(_node, _el),
        title: _el.title
      }, _toDisplayString(_el.title), 11, _hoisted_6), _createElementVNode("div", _hoisted_7, [_renderSlot(_ctx.$slots, "element", {
        nodeTarget: _node,
        elementTarget: _el
      }, undefined, true)])], 2);
    }), 256))])], 64)) : _createCommentVNode("", true)]);
  }), 256))]);
}