import Home from './Home2.vue';
export default {
  data() {
    return {};
  },

  components: {
    Home
  },
  computed: {
    activeComponent() {
      return this.$store.state.component;
    }

  },

  beforeMount() {
    this.$store.commit('changeBase', 'ChatBase');
    this.$store.commit('changeUserType', 'user');
  },

  mounted() {},

  methods: {}
};