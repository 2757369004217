import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-6671bf0a"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  key: 0,
  class: "score-fun"
};
const _hoisted_2 = {
  class: "block",
  style: {
    "align-items": "center"
  }
};
const _hoisted_3 = {
  class: "text"
};
const _hoisted_4 = {
  style: {
    "display": "flex",
    "align-items": "center"
  }
};
const _hoisted_5 = {
  class: "text",
  style: {
    "margin-right": "10px"
  }
};
const _hoisted_6 = {
  class: "input-txt"
};
const _hoisted_7 = {
  class: "btn-group"
};
const _hoisted_8 = {
  key: 1,
  class: "score-fun scored"
};
const _hoisted_9 = {
  class: "text"
};
const _hoisted_10 = {
  style: {
    "width": "80%"
  },
  class: "scorePanel"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_rate = _resolveComponent("el-rate");

  const _component_el_checkbox = _resolveComponent("el-checkbox");

  const _component_el_checkbox_group = _resolveComponent("el-checkbox-group");

  const _component_el_input = _resolveComponent("el-input");

  const _component_el_divider = _resolveComponent("el-divider");

  const _component_el_col = _resolveComponent("el-col");

  const _component_el_row = _resolveComponent("el-row");

  return _withDirectives((_openBlock(), _createElementBlock("div", null, [$props.questionRecordSelected ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("span", null, _toDisplayString(_ctx._t("inviteScore")), 1)])]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.scoreOption, (item, index) => {
    return _openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createElementVNode("span", null, _toDisplayString(_ctx._t(`${item.optionName}`)), 1)]), _createVNode(_component_el_rate, {
      modelValue: item.level,
      "onUpdate:modelValue": $event => item.level = $event,
      max: item.points,
      onClick: $event => $options.clickScore(index)
    }, null, 8, ["modelValue", "onUpdate:modelValue", "max", "onClick"])]);
  }), 256)), _createElementVNode("div", null, [_createVNode(_component_el_checkbox_group, {
    modelValue: $data.scorePanel.optionChecked,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.scorePanel.optionChecked = $event),
    "text-color": "red",
    fill: "green"
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.scoreActive && $options.scoreActive.scoreOption || [], (_option, _optionIndex) => {
      return _openBlock(), _createBlock(_component_el_checkbox, {
        key: _optionIndex,
        label: _option.id
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_option.content), 1)]),
        _: 2
      }, 1032, ["label"]);
    }), 128))]),
    _: 1
  }, 8, ["modelValue"]), _createVNode(_component_el_checkbox, {
    modelValue: $data.scorePanel.suggestChecked,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.scorePanel.suggestChecked = $event)
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx._t("suggestCheckBox")), 1)]),
    _: 1
  }, 8, ["modelValue"])]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_el_input, {
    type: "textarea",
    placeholder: _ctx._t('inputSuggest'),
    modelValue: $data.scorePanel.suggest,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $data.scorePanel.suggest = $event),
    maxlength: "100",
    resize: "none",
    "show-word-limit": "",
    disabled: !$data.scorePanel.suggestChecked
  }, null, 8, ["placeholder", "modelValue", "disabled"])]), _createElementVNode("div", _hoisted_7, [_createElementVNode("a", {
    class: "submit-btn",
    onClick: _cache[3] || (_cache[3] = (...args) => $options.clickSubmitScore && $options.clickSubmitScore(...args))
  }, _toDisplayString(_ctx._t("submit")), 1), _createElementVNode("a", {
    class: "return-btn",
    onClick: _cache[4] || (_cache[4] = (...args) => $options.clickChatAgain && $options.clickChatAgain(...args))
  }, _toDisplayString(_ctx._t("chatAgain")), 1)])])) : $props.scoreSelected ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, [_createVNode(_component_el_divider, null, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx._t("inviteScore")), 1)]),
    _: 1
  })]), _createVNode(_component_el_rate, {
    modelValue: $data.scorePanel.level,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => $data.scorePanel.level = $event),
    disabled: "",
    "show-score": "",
    "text-color": "#ff9900",
    "score-template": ""
  }, null, 8, ["modelValue"]), _createElementVNode("div", _hoisted_10, [$options.scoreOptionActive && $options.scoreOptionActive.trim() != '' ? (_openBlock(), _createBlock(_component_el_row, {
    key: 0
  }, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      span: 6
    }, {
      default: _withCtx(() => [_createElementVNode("div", null, _toDisplayString(_ctx._t("yourComments")), 1)]),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 18
    }, {
      default: _withCtx(() => [_createElementVNode("div", null, _toDisplayString($options.scoreOptionActive), 1)]),
      _: 1
    })]),
    _: 1
  })) : _createCommentVNode("", true), $data.scorePanel.suggest && $data.scorePanel.suggest.trim() != '' ? (_openBlock(), _createBlock(_component_el_row, {
    key: 1
  }, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      span: 6
    }, {
      default: _withCtx(() => [_createElementVNode("div", null, _toDisplayString(_ctx._t("yourSuggest")), 1)]),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 18
    }, {
      default: _withCtx(() => [_createElementVNode("div", null, _toDisplayString($data.scorePanel.suggest), 1)]),
      _: 1
    })]),
    _: 1
  })) : _createCommentVNode("", true)])])) : _createCommentVNode("", true)], 512)), [[_vShow, $data.scorePanel.show]]);
}