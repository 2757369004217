import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
  key: 0,
  style: {
    "position": "fixed",
    "left": "0",
    "top": "0",
    "width": "100vw",
    "height": "100vh",
    "background-color": "rgba(255,255,255,0.7)"
  },
  class: "flex-center"
};
const _hoisted_2 = {
  style: {
    "text-align": "center"
  }
};
const _hoisted_3 = {
  style: {
    "text-align": "center"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_progress = _resolveComponent("el-progress");

  const _component_van_image = _resolveComponent("van-image");

  return _openBlock(), _createElementBlock(_Fragment, null, [(_openBlock(), _createBlock(_Teleport, {
    to: "body"
  }, [$options.uplodingProgress ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_progress, {
    type: "circle",
    percentage: $options.uplodingProgress
  }, null, 8, ["percentage"]), _createElementVNode("div", null, _toDisplayString($options.uplodingText), 1)])])) : _createCommentVNode("", true)])), _createElementVNode("div", {
    style: _normalizeStyle([{
      "padding": "0 2px"
    }, {
      cursor: $props.disabled ? 'not-allowed' : 'pointer'
    }]),
    onClick: _cache[1] || (_cache[1] = (...args) => $options.clickUpload && $options.clickUpload(...args))
  }, [_withDirectives(_createElementVNode("div", null, [_createElementVNode("input", {
    ref: $options.fileId,
    type: "file",
    onChange: _cache[0] || (_cache[0] = $event => $options.changeUpload($event)),
    multiple: ""
  }, null, 544)], 512), [[_vShow, false]]), _createVNode(_component_van_image, {
    width: "48",
    height: "48",
    src: $data.iconUrl
  }, null, 8, ["src"]), _createElementVNode("div", _hoisted_3, _toDisplayString($data.name), 1)], 4)], 64);
}