import Upload from "@/control/Upload.vue";
import ScorePanel from "@/control/ScorePanel.vue";
import { mapGetters, mapState } from "vuex";
import bus from 'vue3-eventbus';
import { ref } from 'vue'; // import templateDemo from "@/control/templateDemo.vue";

import templateDemoEdit from "@/control/templateDemoEdit.vue";
import templateTicket from '@/control/templateTicket.vue';
import { ElMessage } from 'element-plus';
import { ElNotification } from 'element-plus'; // templateDemoEdit

export default {
  setup() {
    const isOnLine = ref(false);
    const userId = ref(''); // bus.on('event-adminOnlineData', (messageData) => {
    //   isOnLine.value = JSON.parse(messageData.data)
    // });
    // bus.on('init', (messageData) => {
    //   console.log('init', messageData);
    //   userId.value = messageData.userId
    // });

    return {
      isOnLine,
      userId
    };
  },

  data() {
    return {
      getOnLineIdListTimer: null,
      wsOpenTimer: null,
      wsOpen: false,
      //
      wsCloseConnectTimes: 0,
      wsCloseConnectTimer: null,
      wsCloseConnect: false,
      reLinkTimer: null,
      reLinkWebSocket: false,
      //pageTotalUnred 控制页面总共的消息未读数量 ws 传递
      pageTotalUnred: 0,
      pageTotalUnredShow: false,
      //判断是否登录(是否是匿名用户)
      noName: false,
      //提示信息
      toolTipShow: false,
      //聊天框照片index
      chatImgIndex: 0,
      scoreDataShow: [],
      //未读数量
      unreadCount_new: 0,
      //为解决数量
      unrresolvedCount_new: 0,
      //控制几项评分
      scoreOption: [],
      //控制聊天页面的scroll
      chatScrollData: {
        scrollHeight_old: null,
        scrollTop_now: null
      },
      //查询消息记录的时候查询的第几周的消息
      weeks: 1,
      //scrollTimer 控制聊天框滚轮timer
      scrollTimer: null,
      //点击ticket其中的用户id
      ticketPersonId: null,
      //chatReocrd_new 新聊天记录 接口获取
      chatReocrd_new: [],
      //questionRecordId 控制是否选中某个ticket
      questionRecordId_new: null,
      //点中某个ticket 的所有信息
      questionRecord_new: {},
      //时间戳信息 用于查询消息记录
      dataRange: {},
      //用户tickt列表新
      questionRecordSort_new: [],
      //新websocket相关
      AdminWSTimeOut_new: null,
      webSocketTimer_new: null,
      webSocketTimeOutTimer_new: null,
      newSocket: undefined,
      isSocketing: false,
      //新websocket
      webSocketUrl: `${window.location.protocol == 'https:' ? 'wss' : 'ws'}://${window.location.host}/javaplus/user/webSocketServer`,
      //个人中心跳转过来的数据
      params_questionRecordId: null,
      params_templateId: null,
      params_isView: null,
      //子页面需要用到的方法集合
      functionTemple: {},
      //判断用户端待补充填报的时候是否是查看还是重新填报
      userView: false,
      //
      loadingTextArray: [],
      refresh: false,
      //点击填报的时候拿到html 填写完之后更改状态发送
      ticketHtml: null,
      //报损单的内容 填报项目
      damageReportContent: {},
      ///存按钮的数组
      btnArray: [],
      //控制报损单是否展示
      templateDemoShow: false,
      url: `${window.location.protocol == 'https:' ? 'wss' : 'ws'}://${window.location.hostname}:28288/faqwebsocket.ws`,
      uploadUrl: "/api/user/ChatBase/uploadFile",
      sendContent: "",
      timer: {},
      turn: false,
      fold: false,
      questionRecordCurrent: {},
      imgArray: [],
      img: "",
      imgIndex: 0,
      imgViewerShow: false,
      scorePanel: {
        show: false,
        level: 0,
        oldLevel: 0,
        suggest: "",
        optionChecked: []
      },
      scrollAjustById: {},
      websocket: undefined,
      messageData: '',
      cookieString: '',
      isUserApply: undefined,
      isApply: null,
      questionId: null
    };
  },

  components: {
    Upload,
    ScorePanel,
    templateTicket,
    templateDemoEdit
  },
  computed: { ...mapState({
      isPC: "isPC",
      isScorePanelShow: "isScorePanelShow",
      questionRecord: "questionRecord",
      questionRecordId: "questionRecordId"
    }),
    ...mapGetters({
      score: "getScore",
      questionById: "getQuestionById",
      questionTypeById: "getQuestionTypeById",
      questionRecordById: "getQuestionRecordById",
      getPersonName: "getPersonName",
      getPersonBackgroundColor: "getPersonBackgroundColor",
      questionRecordUnreadCount: "getQuestionRecordUnreadCount",
      questionRecordUnsolvedCount: "getQuestionRecordUnsolvedCount"
    }),

    questionRecordSelected() {
      return this.questionRecordById[this.questionRecordId] || {};
    },

    // questionRecordSort() {
    //   // console.log(this.questionRecord,'questionRecord0090questionRecord')
    //   var questionRecord = _.cloneDeep(this.questionRecord);
    //   // console.log(questionRecord,'questionRecord0090questionRecord')
    //   questionRecord.sort((a, b) => {
    //     const dateA = new Date(a.change_time).getTime();
    //     const dateB = new Date(b.change_time).getTime();
    //     return dateA < dateB ? 1 : -1;
    //   });
    //   questionRecord.sort((a, b) => {
    //     return a.is_solved - b.is_solved;
    //   });
    //   // 未读消息
    //   var unreadCount = 0;
    //   for (const _record of questionRecord) {
    //     if (this.questionRecordId == 0) {
    //       this.$store.commit("changeQuestionRecordId", _record.id);
    //     }
    //     // 问题关联项目
    //     _record.item = this.questionRecordById[_record.id].item;
    //     // 问题相关评分
    //     _record.score = this.questionRecordById[_record.id].score;
    //     // 顶部消息统计
    //     if (_record.user_unread) {
    //       unreadCount = unreadCount + _record.user_unread;
    //     }
    //     _record.user = JSON.parse(_record.user || "{}");
    //   }
    //   // 闪烁提示未读消息
    //   var title = "wwt-faq";
    //   if (unreadCount) {
    //     clearInterval(this.timer);
    //     var it = this;
    //     this.timer = setInterval(() => {
    //       it.turn = !it.turn;
    //       if (it.turn) {
    //         document.title = title + "[" + unreadCount + "message]";
    //       } else {
    //         document.title = title;
    //       }
    //     }, 1000);
    //   } else {
    //     clearInterval(this.timer);
    //     document.title = title;
    //   }
    //   // console.log(questionRecord, 'questionRecordquestionRecord')
    //   return questionRecord;
    // },
    title() {
      if (this.questionRecordId == 0) {
        return "";
      }

      var record = this.questionRecordById[this.questionRecordId];

      if (record.question_id != 0 && this.questionById[record.question_id]) {
        // 知识库问题
        return this.questionById[record.question_id].title;
      } else {
        // 用户自定义问题
        return record.title;
      }
    },

    chatReocrd() {
      if (this.questionRecordId == 0 || _.isEmpty(this.questionRecordById)) {
        return;
      }

      var record = this.questionRecordById[this.questionRecordId];

      var chatById = _.cloneDeep(record.chat);

      if (_.isEmpty(record)) {
        record = this.questionRecordCurrent;
      } else {
        this.questionRecordCurrent = record;
      } // 将评分插入聊天中


      for (const score of this.questionRecordCurrent.score) {
        chatById['score' + score.id] = {
          id: 0,
          time: score.time,
          user: this.questionRecordCurrent.user,
          person: this.questionRecordCurrent.person,
          type: 'score',
          score,
          new_content: ''
        };
      }

      this.imgArray = [];
      var index = 0; // console.log(chatById, 'chatByIdchatByIdchatById');

      for (var chatId in chatById) {
        var chat = chatById[chatId]; // 搜索图片，替换为element框架<el-image>

        var reg1 = new RegExp(/<img src=['|"]([\s\S]+?)['|"]/, 'g'); // var reg2 = new RegExp(/<button\b[^>]*>([\s\S]*?)<\/button>/gi);

        var regRet1 = reg1.exec(chat.content); // var regRet2 = reg2.exec(chat.content)

        if (_.isEmpty(regRet1) || regRet1.length <= 1) {} else {
          var imgMatch = regRet1.slice(1);

          for (var src of imgMatch || []) {
            this.imgArray.push(src);
            chat.content = chat.content.replace(/<img src=/, "<img class='chatImg' class='_chat_change' data-index=" + index + " src=");
            index++;
          }
        } // if (_.isEmpty(regRet2) || regRet2.length <= 1) {
        // } else {
        //   var btnMatch = regRet2.slice(0, 1);
        //   // this.btn
        //   // console.log(btnMatch,'btnMatchbtnMatch');
        //   this.btnArray.push(btnMatch[0])
        //   chat.new_content = chat.new_content.replace(
        //     /<button\b([^>]*)class=['"]([^'"]*)['"]([^>]*)>/gi,
        //     '<button $1class="$2 chatBtn" $3>'
        //   );
        // }
        // 搜索视频，修改尺寸


        var reg2 = new RegExp(/<video class="ql-video" controls="true" preload="none" src=['|"]([\s\S]+?)['|"]/, 'g');
        var regRet2 = reg2.exec(chat.content);

        if (_.isEmpty(regRet2) || regRet2.length <= 1) {} else {
          var videoMatch = regRet2.slice(1);

          for (var src of videoMatch || []) {
            chat.content = chat.content.replace(/<video class="ql-video" controls="true" preload="none" src=/, '<video width="100%" x5-video-player-type="h5" x5-playsinline="true"  webkit-playsinline="true" x-webkit-airplay="true" playsinline="true" class="ql-video" controls="true" preload="none" src=');
          }
        } //根据填报卡状态改变文字 //找到对应状态的chat
        // var reg3 = new RegExp(/<div\s+[^>]*class\s*=\s*["'][^"']*\bticketTempStatus\b[^"']*["'][^>]*>/gi);
        // var regRet3 = reg3.exec(chat.new_content)
        // // console.log(regRet3);
        // if(_.isEmpty(regRet3) || regRet3.length < 1){
        // }else{
        //   var 
        //   console.log(regRet3.input,'innerhtml');
        // }

      }

      this.initPreviewImg();
      this.$nextTick(() => {
        this.addChatImgClick();
        this.addChatBtnClick();
        this.replaceTicketStatus();
      }); // 排序

      const chatSort = [];

      for (var chatId in chatById) {
        var chat = chatById[chatId];
        chatSort.push(chat);
      }

      chatSort.sort((a, b) => {
        const aMoment = this.$moment(a.time).valueOf();
        const bMoment = this.$moment(b.time).valueOf();
        return aMoment - bMoment;
      }); //将评分放到最后
      // let score =
      //   this.questionRecordCurrent.score[
      //     this.questionRecordCurrent.score.length - 1
      //   ];
      // score &&
      //   chatSort.push({
      //     id: 0,
      //     time: score.time,
      //     user: this.questionRecordCurrent.user,
      //     person: this.questionRecordCurrent.person,
      //     type: "score",
      //     score,
      //     content: "",
      //   });

      return chatSort;
    },

    scoreActive() {
      let scoreActive = {};

      for (const score of this.score || []) {
        if (score.level === this.scorePanel.level) {
          scoreActive = score;
        }
      }

      return scoreActive;
    },

    userDisabled() {
      return !!(this.questionRecordSelected && this.questionRecordSelected.is_solved) || false;
    }

  },
  watch: {
    // chatReocrd: {
    //   handler(nv, ov) {
    //     const chatLength = this.chatReocrd && Object.keys(this.chatReocrd).length || 0;
    //     if (chatLength) {
    //       this.scrollAjust(this.questionRecordId, chatLength);
    //     }
    //   },
    //   flush: "post",
    // },
    isScorePanelShow: {
      handler(nv, ov) {
        this.scorePanel.show = nv;
      },

      immediate: true
    },
    questionRecordId: {
      handler(nv, ov) {
        this.questionId = nv;
        this.getFAQAppraiseStatus();
      },

      immediate: true
    },
    "scorePanel.show": {
      handler(nv, ov) {
        this.$store.commit('changeIsScorePanelShow', nv);
      }

    },
    questionRecordId_new: {
      handler(nv, ov) {
        localStorage.setItem('questionRecord_new', JSON.stringify(this.questionRecord_new));
      }

    },
    "chatScrollData.scrollHeight_old": {
      handler(nv, ov) {
        localStorage.setItem('scrollHeight', JSON.stringify(this.chatScrollData.scrollHeight_old));
      }

    } // isApply: {
    //   handler(nv, ov) {
    //     if(nv === true) {
    //       this.scorePanel.show = true;
    //     }
    //   },
    //   immediate: true
    // }

  },

  async created() {
    await this.init_NoName(); // this.init_params()

    this.functionTemple = {
      returnPreView: this.returnPreView,
      sendTicket: this.sendTicket
    };
    this.getIsEnableList().then(res => {
      this.scoreOption = res.data.data;
    });
    await this.queryRecordQuestionByPersonal().then(res => {
      this.$nextTick(() => {
        this.autoClickFirstQuestion();
      });
    });

    if (localStorage.getItem("questionRecord_new")) {
      this.questionRecord_new = JSON.parse(localStorage.getItem("questionRecord_new"));
    }
  },

  mounted() {
    this.getTotalUnreadNum();
    this.initTitleUnreadMessage();
    this.init_newSocket();
    this.init_params(); // const weekTimestamps = this.getWeekTimestamps(1);
    // console.log(`Start Timestamp: ${weekTimestamps.startTimestamp}`);
    // console.log(`End Timestamp: ${weekTimestamps.endTimestamp}`);
    // //客服查看报损单中返回
    // this.$eventBus.on('returnPreView', () => {
    //   // console.log('returnPreViewreturnPreView');
    //   this.templateDemoShow = false
    //   this.userView = false
    // })
    //如果用户填报选项卡成功 就需要发送一张处理后的填报卡
    // this.$eventBus.on('sendTicket', async () => {
    //   // console.log('sendTicket');
    //   //找到状态修改
    //   this.ticketHtml.querySelector('.ticketTempStatus').innerHTML = '等待审核'
    //   this.ticketHtml.querySelector('.ticketTempStatus').style.color = 'rgb(0, 121, 254)'
    //   this.sendContent = this.ticketHtml.outerHTML
    //   console.log(this.sendContent, 'this.ticketHtml');
    //   // await this.clickSendContent()
    //   this.sendContent = ''
    // })
  },

  beforeUnmount() {
    this.sendUserPageCloseingEvent();
    this.newSocket.close();
  },

  methods: {
    //报损单ticket完成渲染后的回调
    callBack() {
      new Promise(async (resolve, reject) => {
        try {
          await this.addTicketTitleHover();
          this.addChatBtnClick(() => {
            resolve();
          });
        } catch (err) {
          reject(err);
        }
      });
    },

    //首次进入界面默认选择第一个工单
    autoClickFirstQuestion() {
      // if()
      // clickQuestionRecord
      if (this.questionRecordSort_new.length) {
        this.clickQuestionRecord(this.questionRecordSort_new[0]);
      }
    },

    //首次进入获取消息未读
    async getTotalUnreadNum(params) {
      const res = await this.$axios.get('/javaplus/user/recordQuestion/getTotalUnreadNum', params);

      if (res.data.code != 200) {
        // ElNotification({
        //   title: '错误',
        //   message: res.data.message,
        //   type: 'error',
        // });
        return;
      } else {
        this.pageTotalUnred = res.data.data; // this
      }
    },

    initTitleUnreadMessage() {
      //         document.title = title + "[" + unreadCount + "message]";
      setInterval(() => {
        if (this.pageTotalUnredShow) {
          document.title = `wwt-faq`;
        } else {
          document.title = `wwt-faq[${this.pageTotalUnred}message]`;
        }

        this.pageTotalUnredShow = !this.pageTotalUnredShow;
      }, 1500);
    },

    //对用户评价消息进行处理
    initScoreMessage(record) {
      for (let i of record) {
        if (i.messageType == 6) {
          i.content = this.scoreChatRecord(i);
        }
      }
    },

    //匿名用户
    async init_NoName() {
      let params = new URLSearchParams(window.location.search);

      if (params.get('isLogin') == "false") {
        this.noName = true;
        let params = {
          questionTypeId: 9999,
          urgencyLevel: 'P1',
          question_id: 0,
          title: '',
          directoryId: 0
        };
        await this.createRecordQuestion(params);
        this.queryRecordQuestionByPersonal(); // console.log('111')
      } else if (params.get('isLogin') == "true") {
        let params = {
          questionTypeId: 9999,
          urgencyLevel: 'P1',
          question_id: 0,
          title: '',
          directoryId: 0
        };
        await this.createRecordQuestion(params);
        this.queryRecordQuestionByPersonal(); // console.log('222')
      }
    },

    //匿名用户创建工单
    async createRecordQuestion(params) {
      const res = await this.$axios.post('/javaplus/anonymous/user/recordQuestion/createRecordQuestion', params);

      if (res.data.code != 200) {
        ElNotification({
          title: '错误',
          message: res.data.message,
          type: 'error'
        });
      }

      return res;
    },

    //初始化新websocket
    init_newSocket() {
      this.newSocket = new WebSocket(this.webSocketUrl);
      this.newSocket.onopen = this.new_Open;
      this.newSocket.onerror = this.new_Error;
      this.newSocket.onmessage = this.new_Get;
      this.newSocket.onclose = this.new_Close;
    },

    new_Open() {
      // clearTimeout(this.wsOpenTimer)
      // if(this.wsOpen){
      //   return
      // }
      // this.wsOpen = true
      // this.wsOpenTimer = setTimeout(() => {
      console.log('新ws客服端开启完成'); //连接成功查询一次在线列表
      // this.wsCloseConnectTimer = setTimeout(()=>{
      // this.wsCloseConnectTimes = 0,
      // },5000)

      clearTimeout(this.wsCloseConnectTimer);
      this.wsCloseConnectTimer = setTimeout(() => {
        this.wsCloseConnectTimes = 0;
      }, 20000);
      clearTimeout(this.getOnLineIdListTimer);
      this.getOnLineIdListTimer = setTimeout(() => {
        this.getOnLineIdList().then(res => {
          if (res.data.data.length) {
            this.isOnLine = true;
          }
        });
      }, 6000);
      clearTimeout(this.heartMessageTimer);
      this.heartMessageTimer = setInterval(() => {
        this.heartWebSocketOpen();
      }, 10000); // },3000)
    },

    new_Error(event) {
      console.log('ws error_new', event);
    },

    new_Close() {
      if (this.reLinkWebSocket) {
        return;
      }

      console.log('ws close_new', this.wsCloseConnectTimes);

      if (this.wsCloseConnectTimes > 10) {
        this.$router.go(0);
      }

      this.reLinkWebSocket = true;
      this.reLinkTimer = setTimeout(() => {
        this.reLinkWebSocket = false;
        this.init_newSocket();
        this.wsCloseConnectTimes = this.wsCloseConnectTimes + 1;
      }, 3000);
    },

    async new_Get(msg) {
      if (this.newSocket.readyState != 1) {
        return;
      }

      console.log(msg, 'getMMMMsg');
      const data = JSON.parse(msg.data);

      if (data.msgType == 1) {
        this.getOnLineIdList().then(res => {
          if (res.data.data.length) {
            this.isOnLine = true;
          } else {
            this.isOnLine = false;
          }
        });
      } else if (data.msgType == 3) {
        // console.log('3333333');
        //消息变更 变为已解决 查询一次ticket列表就行
        this.queryRecordQuestionByPersonal();
      } else if (data.msgType == 4) {
        //判断是否是正在聊天的聊天框
        if (data.recordQuestionId == this.questionRecordId_new) {
          //清除未读消息
          await this.updateRecordQuestionUnread({
            recordQuestionId: this.questionRecordId_new
          }); //查询工单列表

          this.queryRecordQuestionByPersonal(); //刷新消息列表
          // this.weeks = 1
          // this.dataRange = this.getWeekTimestamps(this.weeks++)
          // const params = {
          //   recordQuestionId: this.questionRecordId_new,
          //   startTimestamp: this.dataRange.startTimestamp,
          //   endTimestamp: this.dataRange.endTimestamp,
          // }
          // this.queryByRecordQuestionIdAndTimestamp(params).then((res) => {
          //   this.chatReocrd_new = this.userNewChatRecordToOld(res.data.data.recordChatList)
          //   this.dealChatRecord()
          //   // localStorage.setItem('chatRecordList_new', JSON.stringify(this.chatRecordList_new))
          //   this.$nextTick(() => {
          //     setTimeout(() => {
          //       const el = this.$refs.chat
          //       this.chatScrollData.scrollHeight_old = el.scrollHeight
          //       localStorage.setItem('scrollHeight', JSON.stringify(this.chatScrollData.scrollHeight_old))
          //       el.scrollTop = el.scrollHeight
          //     }, 100)
          //   })
          // })

          this.queryChatRecord(true);
        } else {
          //不是正在聊天的聊天框那就更新一下工单列表判断是否有未读消息
          this.queryRecordQuestionByPersonal();
        }
      } else if (data.msgType == 5) {
        if (data.recordQuestionId == this.questionRecordId_new) {
          await this.queryRecordQuestionByPersonal();
          this.scorePanel.show = true;
        } else {}
      } else if (data.msgType == 6 || data.msgType == 10) {
        //消息变更 评价之后更新消息记录
        if (data.recordQuestionId == this.questionRecordId_new) {
          //清除未读消息
          await this.updateRecordQuestionUnread({
            recordQuestionId: this.questionRecordId_new
          }); //刷新消息列表
          // this.weeks = 1
          // this.dataRange = this.getWeekTimestamps(this.weeks++)
          // const params = {
          //   recordQuestionId: this.questionRecordId_new,
          //   startTimestamp: this.dataRange.startTimestamp,
          //   endTimestamp: this.dataRange.endTimestamp,
          // }
          // this.queryByRecordQuestionIdAndTimestamp(params).then((res) => {
          //   // console.log(this.chatReocrd_new,'chatReocrd_new121212');
          //   this.chatReocrd_new = this.userNewChatRecordToOld(res.data.data.recordChatList)
          //   // localStorage.setItem('chatRecordList_new', JSON.stringify(this.chatRecordList_new))
          //   this.$nextTick(() => {
          //     setTimeout(() => {
          //       const el = this.$refs.chat
          //       this.chatScrollData.scrollHeight_old = el.scrollHeight
          //       localStorage.setItem('scrollHeight', JSON.stringify(this.chatScrollData.scrollHeight_old))
          //       el.scrollTop = el.scrollHeight
          //     }, 100)
          //   })
          // })

          this.queryChatRecord(true);
        }
      } else if (data.msgType == 8) {
        this.pageTotalUnred = data.msgContent;
      }
    },

    //心跳消息
    heartWebSocketOpen() {
      if (this.newSocket.readyState == 1) {
        this.newSocket.send('ping');
        console.log('HeartMessage send');
      } else {
        clearInterval(this.heartMessageTimer);
        this.init_newSocket();
      }
    },

    //判断url是否传参
    async init_params() {
      //questionRecordId 聊天报损单的id
      let params = new URLSearchParams(window.location.search);

      if (params.get('questionRecordId')) {
        this.params_questionRecordId = params.get('questionRecordId');
        this.params_templateId = params.get('templateId');
        this.params_isView = params.get('isView'); // console.log(this.params_questionRecordId, this.params_templateId, this.params_isView, '2131231000');
        // this.$store.commit("changeQuestionRecordId", this.params_questionRecordId);

        this.questionRecordId_new = this.params_questionRecordId; // localStorage.setItem('questionRecord_new', JSON.stringify(this.questionRecord_new))
        // this.weeks = 1
        // this.dataRange = this.getWeekTimestamps(this.weeks++)
        // const _params = {
        //   recordQuestionId: this.questionRecordId_new,
        //   startTimestamp: this.dataRange.startTimestamp,
        //   endTimestamp: this.dataRange.endTimestamp,
        // }
        // //清除未读消息
        // //刷新消息列表
        // this.queryByRecordQuestionIdAndTimestamp(_params).then((res) => {
        //   this.chatReocrd_new = this.userNewChatRecordToOld(res.data.data.recordChatList)
        //   this.$nextTick(() => {
        //     setTimeout(() => {
        //       const el = this.$refs.chat
        //       this.chatScrollData.scrollHeight_old = el.scrollHeight
        //       localStorage.setItem('scrollHeight', JSON.stringify(this.chatScrollData.scrollHeight_old))
        //       el.scrollTop = el.scrollHeight
        //     }, 100)
        //   })
        // })

        this.queryChatRecord(true);

        if (this.params_templateId && this.params_templateId != 'null') {
          if (this.params_isView) {
            this.userView = true;
          }

          this.getShowDamageReportById({
            id: this.params_templateId
          }).then(res => {
            this.damageReportContent = res.data.data;
            this.$nextTick(() => {
              this.templateDemoShow = true;
              this.refresh = false;
            });
          });
        }
      } // console.log(this.params_questionRecordId, this.params_templateId,'213123123');

    },

    //判断客服是否在线
    async getOnLineIdList(params) {
      const res = await this.$axios.get('/javaplus/user/loginCookie/getOnLineIdList', params);

      if (res.data.code != 200) {// ElNotification({
        //   title: '错误',
        //   message: res.data.message,
        //   type: 'error',
        // });
      }

      return res;
    },

    //新的用户ticket 列表
    async queryRecordQuestionByPersonal() {
      const res = await this.$axios.get('/javaplus/user/recordQuestion/queryRecordQuestionByPersonal');

      if (res.data.code != 200) {
        ElNotification({
          title: '错误',
          message: res.data.message,
          type: 'error'
        });
        return;
      } // return res


      this.questionRecordSort_new = this.userNewDataToOldData(res.data.data); //遍历查找一下id对应的工单

      for (let i of this.questionRecordSort_new) {
        if (i.id == this.questionRecordId_new) {
          this.questionRecord_new = i;
          this.unreadCount_new = i.totalUserUnreadNum;
          this.unrresolvedCount_new = i.totalIsSolvedNum;
        }
      } // this.questionRecordId_new
      // this.questionRecord_new = this.questionRecordSort_new[0]
      // this.unreadCount_new = res.data.data[0].totalUserUnreadNum
      // this.unrresolvedCount_new = res.data.data[0].totalIsSolvedNum
      // for (let sort of this.questionRecordSort_new) {
      //   if (!sort.is_solved) {
      //     this.unrresolvedCount_new++
      //   }
      //   if (sort.user_unread) {
      //     this.unreadCount_new++
      //   }
      // }
      // console.log(this.unreadCount_new,this.unrresolvedCount_new,'this.unrresolvedCount_new+');
      //未读数量
      // unreadCount_new: null,
      //   //为解决数量
      //   : null,
      // console.log(this.questionRecordSort_new, 'this.questionRecordSort_new');

    },

    userNewDataToOldData(array) {
      let newArray = [];

      for (let item of array) {
        let obj = {
          id: item.id,
          question_id: item.questionId,
          question_type_id: item.questionTypeAreaId,
          title: item.title,
          create_time: this.dataTimeFormatter(item.createTimestamp),
          change_time: this.dataTimeFormatter(item.updateTimestamp),
          user: item.personal,
          step_record: '',
          is_solved: item.isSolved,
          user_unread: item.userUnread,
          admin_unread: item.adminUnread,
          is_email: '',
          comment: item.comment,
          customer_id: item.customerId,
          directory_id: item.sopDirectoryId,
          request_score: item.scoreStatus,
          complete_time: this.dataTimeFormatter(item.completeTimestamp),
          area_type_id: 0,
          production_id: item.productionId,
          sop_question_id: item.sopQuestionId,
          urgency_level: item.urgencyLevel,
          item: {},
          score: item.scoreNum,
          person_id: item.personalId,
          totalUserUnreadNum: item.totalUserUnreadNum,
          totalIsSolvedNum: item.totalIsSolvedNum
        };
        newArray.push(obj);
      }

      return newArray;
    },

    userNewChatRecordToOld(array) {
      let newArray = [];

      for (let item of array) {
        let obj = {
          id: item.id,
          question_record_id: item.recordQuestionId,
          time: this.dataTimeFormatter(item.timestamp),
          user: item.personal,
          person_id: item.personalId,
          content: item.content,
          delete: item.isDelete,
          new_content: item.content,
          messageType: item.messageType,
          isDelete: item.isDelete
        };
        newArray.push(obj);
      }

      return newArray;
    },

    //修改聊天记录的视频尺寸
    resizeVide() {
      // 搜索视频，修改尺寸
      var reg2 = new RegExp(/<video class="ql-video" controls="true" preload="none" src=['|"]([\s\S]+?)['|"]/, 'g');
      var regRet2 = reg2.exec(chat.content);

      if (_.isEmpty(regRet2) || regRet2.length <= 1) {} else {
        var videoMatch = regRet2.slice(1);

        for (var src of videoMatch || []) {
          chat.content = chat.content.replace(/<video class="ql-video" controls="true" preload="none" src=/, '<video width="100%" x5-video-player-type="h5" x5-playsinline="true"  webkit-playsinline="true" x-webkit-airplay="true" playsinline="true" class="ql-video" controls="true" preload="none" src=');
        }
      }
    },

    //切换聊天框 用于查询第一页的聊天记录 或 更新
    queryChatRecord(isFirst) {
      if (this.questionRecordId_new == 'null') {
        return;
      }

      const params = {
        recordQuestionId: this.questionRecordId_new // startTimestamp: this.dataRange.startTimestamp,
        // endTimestamp: this.dataRange.endTimestamp,

      };
      this.queryByRecordQuestionIdAndTimestamp(params).then(res => {
        if (!res.data.data.recordChatList.length) {
          this.chatReocrd_new = [];
        } else {
          this.$nextTick(() => {
            //让报损单更新
            this.chatReocrd_new = [];
            this.$nextTick(() => {
              this.chatReocrd_new.unshift(...this.userNewChatRecordToOld(res.data.data.recordChatList));
              this.dealChatRecord(); // console.log(this.chatReocrd_new,'this.chatReocrd_new');

              this.$nextTick(() => {
                // const el =dou  getElementsByClassName('.chatdiv')
                setTimeout(() => {
                  const el = this.$refs.chat;
                  let height = this.chatScrollData.scrollHeight_old;
                  this.chatScrollData.scrollHeight_old = el.scrollHeight; // localStorage.setItem('scrollHeight', JSON.stringify(this.chatScrollData.scrollHeight_old))

                  el.scrollTop = el.scrollHeight;
                }, 50);
              });
            });
          });
        }
      });
    },

    //时间戳转化为 年月日 时分秒 格式化
    dataTimeFormatter(timeStamp) {
      if (timeStamp === 'null' || !timeStamp) {
        return 'null';
      }

      timeStamp = Number(timeStamp);
      let time = new Date(timeStamp);
      let y = time.getFullYear(); // 年份

      let M = time.getMonth() + 1 >= 10 ? time.getMonth() + 1 : '0' + (time.getMonth() + 1);
      ; // 月份（注意月份是从0开始的，所以需要+1）

      let d = time.getDate() >= 10 ? time.getDate() : '0' + time.getDate(); // 日期

      let h = time.getHours() >= 10 ? time.getHours() : '0' + time.getHours(); // 小时

      let m = time.getMinutes() >= 10 ? time.getMinutes() : '0' + time.getMinutes(); // 分钟

      let s = time.getSeconds() >= 10 ? time.getSeconds() : '0' + time.getSeconds(); // 秒数

      return y + '-' + M + '-' + d + ' ' + h + ':' + m + ':' + s;
    },

    //点击ticket 查询消息记录
    async queryByRecordQuestionIdAndTimestamp(params) {
      const res = await this.$axios.post('/javaplus/user/recordChat/queryByRecordQuestionIdAndTimestamp', params);

      if (res.data.code != 200) {
        ElNotification({
          title: '错误',
          message: res.data.message,
          type: 'error'
        });
      }

      return res;
    },

    getWeekTimestamps(weeksAgo) {
      const now = new Date(); // 当前时间

      const startOfWeek = new Date(now); // 起始时间（当前时间）

      const endOfWeek = new Date(now); // 结束时间（当前时间）

      const midTime = new Date(); // 中转时间
      // 获取当前日期是星期几 (0是周日, 1是周一, ..., 6是周六)

      const dayOfWeek = now.getDay(); // 计算到本周一的日期差值
      // const diffToMonday = (dayOfWeek + 6) % 7;
      // 设置 startOfWeek 为本周的周一
      // midTime.setDate();
      // 计算过去的第几周的开始时间

      startOfWeek.setDate(midTime.getDate() - weeksAgo * 30);
      endOfWeek.setDate(midTime.getDate() - (weeksAgo - 1) * 30);
      startOfWeek.setHours(0, 0, 0, 0);
      endOfWeek.setHours(23, 59, 59, 999);
      return {
        startTimestamp: startOfWeek.getTime(),
        endTimestamp: endOfWeek.getTime()
      };
    },

    //根据工单id更新对应未读数为0
    async updateRecordQuestionUnread(params) {
      const res = await this.$axios.post('/javaplus/user/recordQuestion/updateRecordQuestionUnread', params);

      if (res.data.code != 200) {
        ElNotification({
          title: '错误',
          message: res.data.message,
          type: 'error'
        });
      }

      return res;
    },

    //聊天框滚轮滚动事件
    chatScroll() {
      clearTimeout(this.scrollTimer);
      this.scrollTimer = setTimeout(() => {
        const el = this.$refs.chat; // console.log(el.scroll);
        // console.log(el.scrollTop);
        // console.log(el.scrollHeight);

        if (el.scrollTop == 0) {
          // console.log('8888');
          // this.dataRange = this.getWeekTimestamps(this.weeks++)
          // const params = {
          //   recordQuestionId: this.questionRecordId_new,
          //   startTimestamp: this.dataRange.startTimestamp,
          //   endTimestamp: this.dataRange.endTimestamp,
          // }
          // this.queryByRecordQuestionIdAndTimestamp(params).then((res) => {
          //   if (res.data.data.isSurplus) {
          //     this.chatReocrd_new.unshift(...this.userNewChatRecordToOld(res.data.data.recordChatLis))
          //     this.dealChatRecord()
          //     // console.log(this.chatReocrd_new,'this.chatReocrd_new');
          //     this.$nextTick(() => {
          //       // const el =dou  getElementsByClassName('.chatdiv')
          //       setTimeout(() => {
          //         const el = this.$refs.chat
          //         let height = this.chatScrollData.scrollHeight_old
          //         this.chatScrollData.scrollHeight_old = el.scrollHeight
          //         localStorage.setItem('scrollHeight', JSON.stringify(this.chatScrollData.scrollHeight_old))
          //         el.scrollTop = el.scrollHeight - height
          //       }, 50)
          //     })
          //   } else {
          //     ElMessage('已经到底了')
          //   }
          // })
          this.queryChatRecord(false);
        } else {// console.log('22223');
        }
      }, 100); // console
    },

    //新websocket发消息
    async sendMessage_new(params) {
      const res = await this.$axios.post('/javaplus/user/webSocket/sendMessage', params);

      if (res.data.code != 200) {
        ElNotification({
          title: '错误',
          message: res.data.message,
          type: 'error'
        });
        return;
      } else {}

      return res;
    },

    //取消工单评分
    async cancelScore(params) {
      const res = await this.$axios.get('/javaplus/user/scoreQuestionRecord/cancelScore/' + params);

      if (res.data.code != 200) {
        ElNotification({
          title: '错误',
          message: res.data.message,
          type: 'error'
        });
      }

      return res;
    },

    //查询启用的得分选项数据
    async getIsEnableList() {
      const res = await this.$axios.get('/javaplus/user/scoreQuestionOption/getIsEnableList');

      if (res.data.code != 200) {
        ElNotification({
          title: '错误',
          message: res.data.message,
          type: 'error'
        });
      }

      return res;
    },

    //提交完评分要更新ticket 列表
    updateTicketList() {
      this.queryRecordQuestionByPersonal();
    },

    initPreviewImg(chatReocrd_new) {
      // this.imgArray = [];
      // var index = 0;
      // console.log(chatById, 'chatByIdchatByIdchatById');
      // console.log('1231231231')
      for (var chatId of chatReocrd_new) {
        var chat = chatId; // 搜索图片，替换为element框架<el-image>

        var reg1 = new RegExp(/<img src=['|"]([\s\S]+?)['|"]/, 'g'); // var reg2 = new RegExp(/<button\b[^>]*>([\s\S]*?)<\/button>/gi);

        var regRet1 = reg1.exec(chat.new_content); // console.log(regRet1, '7777777777');

        if (_.isEmpty(regRet1) || regRet1.length <= 1) {} else {
          var imgMatch = regRet1.slice(1); // console.log(imgMatch, '6666666666666');

          for (var src of imgMatch || []) {
            this.imgArray.push(src); // console.log(this.imgArray, 'this.imgArray');
            // console.log(chat, 'this.chat');

            chat.new_content = chat.new_content.replace(/<img src=/, "<img class='chatImg' class='_chat_change' data-index=" + this.chatImgIndex + " src=");
            this.chatImgIndex++;
          }
        }

        var reg2 = new RegExp(/<video class="ql-video" controls="true" preload="none" src=['|"]([\s\S]+?)['|"]/, 'g');
        var regRet2 = reg2.exec(chat.new_content); // console.log(regRet2, 'regRet2regRet2')

        if (_.isEmpty(regRet2) || regRet2.length <= 1) {} else {
          console.log(videoMatch, 'videoMatch');
          var videoMatch = regRet2.slice(1);

          for (var src of videoMatch || []) {
            chat.new_content = chat.new_content.replace(/<video class="ql-video" controls="true" preload="none" src=/, '<video width="100%" x5-video-player-type="h5" x5-playsinline="true"  webkit-playsinline="true" x-webkit-airplay="true" playsinline="true" class="ql-video" controls="true" preload="none" src=');
          }
        }
      }
    },

    //处理评分消息
    scoreChatRecord(scoreRecord) {
      let scoreData = {}; // console.log(scoreRecord,'this.scoreRecord');  

      scoreData = JSON.parse(JSON.parse(scoreRecord.content).score);
      let suggest = JSON.parse(scoreRecord.content).suggest; // for (let record of this.chatReocrd_new) {
      //   if (record.messageType == 6) {
      //     // console.log(scoreData,'scoreDatascoreData');  
      //   }
      // }
      // for()
      // scoreDataShow

      this.scoreDataShow = {
        score: [],
        suggest: suggest
      };

      for (let i of this.scoreOption) {
        for (let x in scoreData) {
          // console.log(x,'xxx');
          if (i.id == x) {
            let obj = {
              name: i.optionName,
              score: scoreData[x]
            };
            this.scoreDataShow.score.push(obj);
          }
        }
      } // console.log(this.scoreDataShow,'this.scoreDataShow')
      // scoreOption


      return this.scoreDataShow;
    },

    //对消息记录进行处理
    dealChatRecord() {
      this.initPreviewImg(this.chatReocrd_new);
      this.initScoreMessage(this.chatReocrd_new); // this.initPreviewImg(this.chatReocrd_new)

      this.$nextTick(() => {
        this.addChatImgClick(); // this.addChatBtnClick()
        // this.addTicketTitleHover()
        // this.replaceTicketStatus()
      });
    },

    // /      this.initPreviewImg()
    //   this.$nextTick(() => {
    //     this.addChatImgClick();
    //     this.addChatBtnClick()
    //     this.replaceTicketStatus()
    //   });
    //替换填报卡状态文本
    replaceTicketStatus() {// let ticketStatus = document.getElementsByClassName("ticketTempStatus");
      // let channelNames = document.getElementsByClassName("channelName");
      // let purchaseChannel = document.getElementsByClassName("purchaseChannel");
      // let orderNum = document.getElementsByClassName("orderNum");
      // let paymentTime = document.getElementsByClassName("paymentTime");
      // let ticketCard = document.getElementsByClassName("ticketCard");
      // for (let p of purchaseChannel) {
      //   p.innerHTML = this._t(`${p.innerHTML}`)
      // }
      // for (let o of orderNum) {
      //   o.innerHTML = this._t(`${o.innerHTML}`)
      // }
      // for (let pm of paymentTime) {
      //   pm.innerHTML = this._t(`${pm.innerHTML}`)
      // }
      // for (let t of ticketCard) {
      //   t.innerHTML = this._t(`${t.innerHTML}`)
      // }
      // for (let channel of channelNames) {
      //   channel.innerHTML = this._t(`${channel.innerHTML}`)
      // }
      // for (let status of ticketStatus) {
      //   status.innerHTML = this._t(`${status.innerHTML}`)
      // }
    },

    //如果用户填报选项卡成功 就需要发送一张处理后的填报卡
    // sendTicket() {
    //   //找到状态修改
    //   this.ticketHtml.querySelector('.ticketTempStatus').innerHTML = '等待审核'
    //   this.ticketHtml.querySelector('.ticketTempStatus').style.color = 'rgb(0, 121, 254)'
    //   this.sendContent = this.ticketHtml.outerHTML
    //   console.log(this.sendContent, 'this.ticketHtml');
    //   // await this.clickSendContent()
    //   this.sendContent = ''
    // },
    //returnPreView 取消或者关闭页面回调
    returnPreView() {
      this.templateDemoShow = false;
      this.userView = false;
    },

    //给每一个报损单的标题增加悬浮窗
    addTicketTitleHover() {
      const toolTips = document.querySelector('.toolTips');
      var ticketTitleH = document.getElementsByClassName("ticketTitle"); // console.log(ticketTitleH, 'ticketTitleH')

      for (let title of ticketTitleH) {
        title.addEventListener('mouseover', e => {
          // console.log(e, '2323232')
          this.toolTipShow = true;
          const rect = title.getBoundingClientRect();
          toolTips.innerText = e.target.innerText;
          toolTips.style.left = `${e.clientX - 100}px`;
          toolTips.style.top = `${e.clientY - 35}px`; // tooltip.style.display = 'block'
        });
        title.addEventListener('mouseleave', e => {
          this.toolTipShow = false;
        });
      }
    },

    //给每个按钮绑定点击方法 并且 根据状态改变label
    addChatBtnClick(fn) {
      const it = this;
      var chatBtnArray = document.getElementsByClassName("chatBtn");

      for (var Btn of chatBtnArray) {
        //10.9
        // Btn.children[0].innerHTML = this._t(`${Btn.children[0].innerHTML}`)
        //10.9
        // console.log(Btn.children[0].innerHTML = '重新填报');
        Btn.onclick = e => {
          this.refresh = true; // this.templateDemoShow = true

          if (e.target.tagName === 'SPAN') {
            console.log(e.target.innerHTML, 'e.target.innerHTML');

            if (e.target.innerHTML == `查看` || e.target.innerHTML == `調べます` || e.target.innerHTML == `View`) {
              this.userView = true;
            }

            this.ticketTempId = e.target.parentElement.parentElement.parentElement.querySelector('[templateid]').getAttribute('templateid');
          } else {
            if (e.target.children[0].innerHTML == `查看` || e.target.children[0].innerHTML == `調べます` || e.target.innerHTML == `View`) {
              this.userView = true;
            }

            this.ticketTempId = e.target.parentElement.parentElement.querySelector('[templateid]').getAttribute('templateid');
          } // console.log(this.ticketHtml, 'this.ticketHtml');


          this.getShowDamageReportById({
            id: this.ticketTempId
          }).then(res => {
            this.damageReportContent = res.data.data; // console.log(this.damageReportContent,'this.damageReportContent');

            this.$nextTick(() => {
              this.templateDemoShow = true;
              this.refresh = false;

              if (fn) {
                fn();
              } // console.log(this.refresh, 'this.refreshthis.refreshthis.refresh');

            });
          });
        };
      }
    },

    //根据工单id更新工单是否已解决
    async updateIsSolvedById(params) {
      const res = await this.$axios.post('/javaplus/user/recordQuestion/updateIsSolvedById', params);

      if (res.data.code != 200) {
        ElNotification({
          title: '错误',
          message: res.data.message,
          type: 'error'
        });
      } else {
        ElNotification({
          title: '成功',
          message: res.data.message,
          type: 'success'
        });
      }

      return res;
    },

    //根据报损单ID查询报损单详情
    async getShowDamageReportById(params) {
      const res = await this.$axios.post('/api/user/damageReport/getShowDamageReportById', params);

      if (res.data.errno != 0) {
        ElNotification({
          title: '错误',
          message: res.data.message,
          type: 'error'
        });
      } else {}

      return res;
    },

    // 客户关闭页面事件
    async sendUserPageCloseingEvent() {
      const res = await this.$axios.get(`/java/front/sendUserPageCloseingEvent?uid=${this.userId}`);
    },

    async getFAQAppraiseStatus() {
      if (this.questionId == 0) {
        return;
      }

      const res = await this.$axios.get('/java/front/getFAQAppraiseStatus?questionRescordId=' + this.questionId);
      this.isApply = res.data.data;

      if (res.data.data == 1) {
        this.scorePanel.show = true;
      } else if (res.data.data != 1) {
        this.scorePanel.show = false;
      }
    },

    scrollAjust(questionRecordId, chatLength) {
      if (this.scrollAjustById[0] !== questionRecordId || this.scrollAjustById[questionRecordId] !== chatLength) {
        if (this.$refs.chat) {
          this.$refs.chat.scrollTop = this.$refs.chat.scrollHeight;
        }
      }

      this.scrollAjustById[0] = questionRecordId;
      this.scrollAjustById[questionRecordId] = chatLength;
    },

    getNav(_record) {
      // var record = this.questionRecordById[id];
      var record = _record; // console.log(this.questionRecordById,'recordrecordrecord');\
      // console.log(record,'typetypetypetype123');

      var type = this.questionTypeById[record.question_type_id]; // console.log(this.questionTypeById,'typetypetypetype345');
      // console.log(record.question_type_id,'question_type_id');
      // console.log(this.questionTypeById,'this.questionTypeById');
      // console.log(type,'typetypetypetype');

      var nav = [];

      if (type) {
        for (const _nav of type.nav) {
          nav.push(_nav.title);
        }
      } // console.log(nav,'adasda')


      return nav;
    },

    getTitle(_record) {
      // var record = this.questionRecordById[id];
      var record = _record;

      if (record.question_id != 0 && this.questionById[record.question_id]) {
        // 知识库问题
        return this.questionById[record.question_id].title;
      } else {
        // 用户自定义问题
        return record.title;
      }
    },

    getItemName(item) {
      var nameArray = [];

      for (const _id in item) {
        const _item = item[_id];
        nameArray.push(_item.name);
      }

      return nameArray.join();
    },

    getItemNameAndDescription(item) {
      var nameArray = [];

      for (const _id in item) {
        const _item = item[_id];

        if (_item.description) {
          nameArray.push(_item.name + "(" + _item.description + ")");
        } else {
          nameArray.push(_item.name);
        }
      }

      return nameArray.join();
      dataRange;
    },

    clickReturnKnowledge() {
      this.$router.push({
        path: "/user/knowledge"
      });
    },

    async clickQuestionRecord(record) {
      // console.log('111');
      //重置聊天框照片相关
      this.chatImgIndex = 0, this.imgArray = []; // var index = 0;
      //先关闭一次

      this.scorePanel.show = false;
      this.questionRecordId_new = record.id;
      this.ticketPersonId = record.user.id;
      this.questionRecord_new = record; // localStorage.setItem('questionRecord_new', JSON.stringify(this.questionRecord_new))
      //判断是否需要评价

      if (this.questionRecord_new.request_score == 1) {
        this.scorePanel.show = true;
      } // else {
      //   this.scorePanel.show = false
      // }
      // console.log(this.questionRecord_new, 'this.ticketPersonId');
      // console.log('8888');
      //清除未读消息


      await this.updateRecordQuestionUnread({
        recordQuestionId: this.questionRecordId_new
      }); //查询工单列表

      this.queryRecordQuestionByPersonal(); //查询消息记录
      // this.weeks = 1
      // this.dataRange = this.getWeekTimestamps(this.weeks++)
      // const params = {
      //   recordQuestionId: record.id,
      //   startTimestamp: this.dataRange.startTimestamp,
      //   endTimestamp: this.dataRange.endTimestamp,
      // }
      // this.queryByRecordQuestionIdAndTimestamp(params).then((res) => {
      //   this.chatReocrd_new = this.userNewChatRecordToOld(res.data.data.recordChatList)
      //   this.dealChatRecord()
      //   // console.log(this.chatReocrd_new,'this.chatReocrd_new');
      //   this.$nextTick(() => {
      //     // const el =dou  getElementsByClassName('.chatdiv')
      //     setTimeout(() => {
      //       const el = this.$refs.chat
      //       // console.log(el.scrollTop, 'elelelel');
      //       // console.log(el.scrollHeight, 'elelelel');
      //       this.chatScrollData.scrollHeight_old = el.scrollHeight
      //       localStorage.setItem('scrollHeight', JSON.stringify(this.chatScrollData.scrollHeight_old))
      //       el.scrollTop = el.scrollHeight
      //     }, 100)
      //   })
      // })

      this.queryChatRecord(true); // console.log(record,'recordrecord');
      // this.$store.commit("changeQuestionRecordId", record.id);
      // console.log('222');
    },

    removeUnvalidContent(content) {
      const unvaild = "<p></p>";

      if (content.endsWith(unvaild)) {
        content = content.slice(0, -unvaild.length);
        content = this.removeUnvalidContent(content);
      }

      return content;
    },

    appendHtmlContent(content) {
      return "<p>" + content + "</p>";
    },

    clickSendContent() {
      if (this.questionRecord_new.is_solved) {
        ElMessage.error(`${this._t('工单已解决')}`);
        this.sendContent = '';
        return;
      }

      if (this.questionRecordId_new == 'null' || !this.questionRecordId_new) {
        ElMessage.error(`${this._t('请先选择左侧ticket，再点击发送。')}`); // 请先选择服务

        return;
      }

      console.log(this.questionRecord_new, 'questionRecord_new');
      var sendContent = this.sendContent;
      sendContent = this.appendHtmlContent(sendContent);
      sendContent = this.removeUnvalidContent(sendContent);

      if (sendContent.trim() == "") {
        return;
      }

      const params = {
        msgType: 4,
        recordQuestionId: this.questionRecordId_new,
        msgContent: sendContent,
        timestamp: Date.now()
      };
      this.sendMessage_new(params).then(res => {
        this.sendContent = '';
      });
    },

    changeQuestionRecord(questionId, questionRecordId, change) {
      this.$axios.post("/api/user/KnowledgeBase/changeQuestionRecord", {
        id: questionRecordId,
        question_id: questionId,
        change: change
      });
    },

    async changingProblemsState() {
      if (this.questionId == 0) {
        return;
      }

      const res = await this.$axios.get('/java/front/changingProblemsState?questionRescordId=' + this.questionId);
    },

    async clickSolved(questionRecord) {
      // questionRecord.is_solved  = questionRecord.is_solved ? 0 : 1
      // console.log(questionRecord,'questionRecord');
      if (!questionRecord.is_solved) {
        this.scorePanel.show = !this.scorePanel.show;
      } else {
        questionRecord.is_solved = questionRecord.is_solved ? 0 : 1; // this.changeQuestionRecord(questionRecord.question_id, questionRecord.id, {
        //   is_solved: questionRecord.is_solved
        // })

        const params = {
          id: questionRecord.id,
          isSolved: questionRecord.is_solved
        };
        await this.updateIsSolvedById(params);
        this.cancelScore(questionRecord.id);
      } //更新一下ticket列表

    },

    getLocalTime(time) {
      var localTime = this.$moment.utc(time).local().format("YYYY-MM-DD HH:mm:ss");
      return localTime;
    },

    clickFoldPanel() {
      this.fold = !this.fold;
    },

    onSuccessUpload(embed) {
      this.sendEmbed(embed);
    },

    sendEmbed(embed) {
      if (this.questionRecord_new.is_solved) {
        ElMessage.error(`${this._t('工单已解决')}`);
        this.sendContent = '';
        return;
      }

      if (!this.questionRecordId_new) {
        ElMessage.error(`${this._t('请先选择左侧ticket，再点击发送。')}`);
        return;
      }

      const sendContent = embed;
      const params = {
        msgType: 4,
        recordQuestionId: this.questionRecordId_new,
        msgContent: sendContent,
        timestamp: Date.now()
      };
      this.sendMessage_new(params);
    },

    addChatImgClick() {
      const it = this;
      var chatImgArray = document.getElementsByClassName("chatImg");

      for (var img of chatImgArray) {
        img.onclick = e => {
          var index = e.target.dataset.index;
          it.imgIndex = Number(index);
          it.imgViewerShow = true;
        };
      }
    }

  }
};