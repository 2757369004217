import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-020360c2"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  key: 0,
  style: {
    "width": "90%",
    "margin-left": "5%"
  }
};
const _hoisted_2 = {
  key: 0,
  style: {
    "display": "flex",
    "flex-wrap": "wrap",
    "cursor": "pointer"
  }
};
const _hoisted_3 = ["onClick"];
const _hoisted_4 = {
  key: 1,
  style: {
    "display": "flex",
    "flex-wrap": "wrap",
    "cursor": "pointer"
  }
};
const _hoisted_5 = ["onClick"];
const _hoisted_6 = {
  key: 1,
  style: {
    "width": "90%",
    "margin-left": "5%"
  }
};
const _hoisted_7 = {
  style: {
    "display": "flex",
    "justify-content": "flex-end"
  }
};
const _hoisted_8 = {
  key: 0
};
const _hoisted_9 = {
  key: 0
};
const _hoisted_10 = {
  key: 0,
  style: {
    "display": "flex",
    "flex-wrap": "wrap"
  }
};
const _hoisted_11 = {
  class: "option"
};
const _hoisted_12 = {
  key: 1,
  style: {
    "display": "flex",
    "flex-wrap": "wrap"
  }
};
const _hoisted_13 = {
  class: "option"
};
const _hoisted_14 = {
  key: 1,
  style: {
    "color": "rgb(126,127,134)",
    "margin-left": "30px"
  }
};
const _hoisted_15 = {
  key: 1,
  style: {
    "color": "#D9001B",
    "display": "flex",
    "margin-top": "10px",
    "margin-left": "10px"
  }
};
const _hoisted_16 = {
  style: {
    "margin-left": "10px",
    "width": "60vw",
    "word-wrap": "break-word"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_templateStatusShow = _resolveComponent("templateStatusShow");

  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", null, [($props.dpt.status == '1' || $props.dpt.status == '3') && !$props.userIsView ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: _normalizeClass($data.dataInfo.isEssential ? 'star' : ''),
    style: {
      "font-weight": "bold",
      "margin-bottom": "10px",
      "font-size": "14px",
      "word-wrap": "break-word"
    }
  }, _toDisplayString($props.listIndex) + "." + _toDisplayString($data.dataInfo.title || '标题'), 3), $props.isCheckBox ? (_openBlock(), _createElementBlock("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.dataInfo.itemList, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      class: _normalizeClass([this.selectsIndex[index] ? 'optionSelect' : 'option']),
      onClick: $event => $options.clickOption(index, item)
    }, _toDisplayString(item.optionName), 11, _hoisted_3);
  }), 256))])) : _createCommentVNode("", true), !$props.isCheckBox ? (_openBlock(), _createElementBlock("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.dataInfo.itemList, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      class: _normalizeClass([this.selectIndex === item.id ? 'optionSelect' : 'option']),
      onClick: $event => $options.clickOption(index, item)
    }, _toDisplayString(item.optionName), 11, _hoisted_5);
  }), 256))])) : _createCommentVNode("", true)])) : (_openBlock(), _createElementBlock("div", _hoisted_6, [_createElementVNode("div", {
    class: _normalizeClass($data.dataInfo.isEssential ? 'star' : ''),
    style: {
      "font-weight": "bold",
      "margin-bottom": "10px",
      "font-size": "14px",
      "word-wrap": "break-word",
      "max-width": "90vw"
    }
  }, _toDisplayString($props.listIndex) + "." + _toDisplayString($data.dataInfo.title || '标题'), 3), _createElementVNode("div", _hoisted_7, [_createVNode(_component_templateStatusShow, {
    status: $props.dpt.status,
    dataInfo: $data.dataInfo,
    languageType: $props.dpt.languageType,
    dpt: $props.dpt
  }, null, 8, ["status", "dataInfo", "languageType", "dpt"])]), $props.dpt.status ? (_openBlock(), _createElementBlock("div", _hoisted_8, [$data.dataInfo.answerVo.contentItemId && $data.dataInfo.answerVo.contentItemId != 'null' ? (_openBlock(), _createElementBlock("div", _hoisted_9, [$props.isCheckBox ? (_openBlock(), _createElementBlock("div", _hoisted_10, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.selects, (item, index) => {
    return _openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(item.optionName), 1);
  }), 256))])) : (_openBlock(), _createElementBlock("div", _hoisted_12, [_createElementVNode("div", _hoisted_13, _toDisplayString($data.select), 1)]))])) : (_openBlock(), _createElementBlock("div", _hoisted_14, _toDisplayString($props.damageRTextL.userHasNotFilledOut), 1))])) : _createCommentVNode("", true), $data.dataInfo.answerVo?.checkStatus === '1' && $props.dpt.status != '2' ? (_openBlock(), _createElementBlock("div", _hoisted_15, [_createElementVNode("div", null, _toDisplayString($props.damageRTextL.additionalRemarks) + ": ", 1), _createElementVNode("div", _hoisted_16, _toDisplayString($data.dataInfo.answerVo.checkRemark), 1)])) : _createCommentVNode("", true)]))])]);
}