import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: "option unselected",
    style: {
      "height": "100%",
      "background-color": "#fff",
      "display": "flex",
      "justify-content": "space-between",
      "align-items": "center",
      "border-left": "1px solid #000",
      "border-right": "1px solid #000",
      "border-bottom": "1px solid #000",
      "padding": "0.5rem"
    },
    onClick: _cache[0] || (_cache[0] = _withModifiers($event => $options.clickOption(), ["stop"]))
  }, [_renderSlot(_ctx.$slots, "default", {}, undefined, true), _createElementVNode("div", {
    style: _normalizeStyle([{
      visibility: $data.optionSelected ? 'visible' : 'hidden'
    }, {
      "margin-left": "1rem"
    }]),
    class: "bi bi-check"
  }, null, 4)]);
}