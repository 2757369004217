const localStorageDirective = {
  mounted(el, binding, vnode, prevVnode) {
    const getKeyOnly = (key) => {
      const keyOnly = `${key}`;
      return keyOnly;
    };
    if (Array.isArray(binding.value)) {
      console.error('本地缓存无法缓存数组');
    } else if (typeof binding.value === 'object') {
      for (const key in binding.value) {
        const val = binding.value[key];
        const keyOnly = getKeyOnly(key);
        let value = localStorage.getItem(keyOnly);
        if (value !== null) {
          value = JSON.parse(value);
        } else {
          value = val;
        }
        binding.instance[key] = value;
      }
    } else if (typeof binding.value === 'function') {
      console.error('本地缓存无法缓存函数');
    } else {
      console.error('本地缓存无法缓存除对象类型外其他数据类型参数');
    }
  },
  updated(el, binding, vnode, prevVnode) {
    if (JSON.stringify(binding.value) === JSON.stringify(binding.oldValue)) {
      return;
    }

    const getKeyOnly = (key) => {
      const keyOnly = `${key}`;
      return keyOnly;
    };
    if (Array.isArray(binding.value)) {
      console.error('本地缓存无法缓存数组');
    } else if (typeof binding.value === 'object') {
      for (const key in binding.value) {
        const val = binding.value[key];
        const keyOnly = getKeyOnly(key);
        const value = JSON.stringify(val);
        localStorage.setItem(keyOnly, value);
      }
    } else if (typeof binding.value === 'function') {
      console.log('无法缓存函数');
    } else {
      console.error('本地缓存无法缓存除对象类型外其他数据类型参数');
    }
  }
};

export default localStorageDirective;