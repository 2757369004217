// import templateInfo_1 from "../Control/templateInfo_1.vue";
import { ElNotification, ElMessage, ElMessageBox } from 'element-plus';
export default {
  components: {},
  props: {
    tempTicketData: {
      type: Object,
      default: () => {
        return {};
      }
    },
    isAdmin: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    callBack: {
      type: Function,
      default: () => {}
    } // damageRTextL: {
    //     type: Object,
    //     default: () => {
    //         return {}
    //     }
    // },

  },

  data() {
    return {
      dptData: {} // updateTimer: 0,
      // damageRTextL: {},
      // purchasingDir: [],
      // color: '',
      // tempTicketData:
      // {
      //     id: 38,
      //     productionId: 370,
      //     productionName: "R1+DAMPER1",
      //     status: "1",
      //     statusName: "Waiting to Fill Out",
      //     tempItemId: 195,
      //     tempItemName: "1.产品基础知识培训报损单测试813",
      //     merchandiseName: "猎户座金属飞行脚舵（阻尼版）",
      //     purchasingChannels: "1",
      //     purchasingChannelsName: "WINWING官网",
      //     orderNum: "125124600042310352",
      //     paymentTime: 1716260299051,
      //     pictureUrl: "null",
      //     languageType: 'en'
      // }

    };
  },

  watch: {},
  computed: {},
  methods: {
    //通过不同的id返回不一样的颜色
    getColorByStatus(status) {
      if (status == '1') {
        return '#FA8C16';
      } else if (status == '2') {
        return '#0079FE';
      } else if (status == '3') {
        return '#DF3033';
      } else if (status == '4') {
        return '#02B700';
      } else if (status == '5') {
        return '#DF3033';
      }
    },

    //通过不同的id返回不一样的颜色
    getStatusNameByStatus(status) {
      if (status == '1') {
        return this.dptData.warrantyTypeOne;
      } else if (status == '2') {
        return this.dptData.warrantyTypeTwo;
      } else if (status == '3') {
        return this.dptData.warrantyTypeThree;
      } else if (status == '4') {
        return this.dptData.warrantyTypeFour;
      } else if (status == '5') {
        return this.dptData.warrantyTypeFive;
      }
    },

    // getDamageReportCardById
    //根据报损单ID查询报损单详情
    async getDamageReportCardById(params) {
      const res = await this.$axios.post(`/javaplus${this.isAdmin ? "/admin/afterSale/damageReport/" : "/user/"}getDamageReportCardById`, params);

      if (res.data.code != 200) {
        ElNotification({
          title: '错误',
          message: res.data.message,
          type: 'error'
        });
      }

      return res;
    },

    //时间戳转化为 年月日 时分秒 格式化
    dataTimeFormatter(timeStamp) {
      if (timeStamp === 'null' || !timeStamp) {
        return 'null';
      }

      timeStamp = Number(timeStamp);
      let time = new Date(timeStamp);
      let y = time.getFullYear(); // 年份

      let M = time.getMonth() + 1 >= 10 ? time.getMonth() + 1 : '0' + (time.getMonth() + 1);
      ; // 月份（注意月份是从0开始的，所以需要+1）

      let d = time.getDate() >= 10 ? time.getDate() : '0' + time.getDate(); // 日期

      let h = time.getHours() >= 10 ? time.getHours() : '0' + time.getHours(); // 小时

      let m = time.getMinutes() >= 10 ? time.getMinutes() : '0' + time.getMinutes(); // 分钟

      let s = time.getSeconds() >= 10 ? time.getSeconds() : '0' + time.getSeconds(); // 秒数

      return y + '-' + M + '-' + d + ' ' + h + ':' + m + ':' + s;
    },

    //[FAQ]报损单表单
    async damageR(params) {
      const res = await this.$axios.post('/javaplus/user/form/damage', params);

      if (res.data.code != 200) {
        ElNotification({
          title: '错误',
          message: res.data.message,
          type: 'error'
        });
      }

      return res;
    },

    returnRecord(content) {
      // console.log(`${content}`);
      // console.log(content);
      return `${content}`;
    },

    //根据status返回不一样的文本
    getByStatus(status, dir, type) {
      for (let d of dir) {
        if (status == d.dictValue) {
          return d[`${type}`];
        }
      }
    } //获取数据字典
    // async getDictListByDictType(params) {
    //     const res = await this.$axios.post('/java/afterSale/damageReport/getDictListByDictType', params)
    //     if (res.data.code != 200) {
    //         ElNotification({
    //             title: '错误',
    //             message: res.data.message,
    //             type: 'error',
    //         });
    //     }
    //     return res
    // },
    //             {
    //     "msg": "ok",
    //     "code": 200,
    //     "data": {
    //         "id": 38,
    //         "productionId": 370,
    //         "productionName": "R1+DAMPER1",
    //         "status": "1",
    //         "statusName": "等待填报",
    //         "tempItemId": 195,
    //         "tempItemName": "1.产品基础知识培训报损单测试813",
    //         "merchandiseName": "猎户座金属飞行脚舵（阻尼版）",
    //         "purchasingChannels": "1",
    //         "purchasingChannelsName": "WINWING官网",
    //         "orderNum": "125124600042310352",
    //         "paymentTime": "null",
    //         "pictureUrl": "null"
    //     }
    // }
    //showPop
    // showPop() {
    //     this.PopupVue("选择基础信息", templateInfo_1, {
    //     })
    // }


  },

  async created() {
    // console.log(this.tempTicketData, 'tempTicketData')
    this.getDamageReportCardById({
      id: this.tempTicketData.id
    }).then(res => {
      this.dptData = res.data.data;
      this.$nextTick(() => {
        // callBack
        this.$emit('change');
      });
    });
  },

  // async updated() {
  //     // debugger
  //     if (this.updateTimer) {
  //         return
  //     }
  //     this.updateTimer = setTimeout( async () => {
  //         if (this.tempTicketData.languageType) {
  //             await this.damageR({ languageType: this.tempTicketData.languageType }).then((res) => {
  //                 this.damageRTextL = res.data.data
  //             })
  //         }
  //     }, 500)
  // },
  mounted() {}

};