import Tree from './Tree.vue';
export default {
  props: {
    node: {
      // 节点
      type: [Object, Array],

      default() {
        return [{
          id: 1,
          editing: false,
          //是否正在编辑
          isFold: false,
          // 是否折叠
          title: '标题',
          // 标题
          nav: [],
          // 当前节点的导航
          child: {},
          // 子节点
          element: {
            1: {
              id: 1,
              title: '标题'
            }
          } // 子元素

        }];
      }

    },
    nav: {
      // 当前
      type: Array,

      default() {
        return [{
          id: 1,
          // 唯一id
          title: '标题' // 标题

        }];
      }

    },
    unfoldDegree: {
      // 展开深度
      type: Number,
      default: 1
    },
    elementId: {
      // 选择的元素
      type: Number,
      default: 0
    },
    idName: {
      type: String,

      default() {
        return 'id';
      }

    },
    titleName: {
      type: String,

      default() {
        return 'title';
      }

    },
    navName: {
      type: String,

      default() {
        return 'nav';
      }

    },
    childName: {
      type: String,

      default() {
        return 'child';
      }

    },
    elementName: {
      type: String,

      default() {
        return 'element';
      }

    },
    editingName: {
      type: String,

      default() {
        return 'editing';
      }

    }
  },
  emit: ['clickNode', 'clickElement'],

  data() {
    return {
      nodeObj: undefined
    };
  },

  components: {
    Tree
  },
  computed: {},

  mounted() {
    // 监听节点
    this.$watch('node', (nv, ov) => {
      if (_.isEqual(nv, ov)) {
        return;
      }

      this.nodeObj = this.initNode(this.node, this.nodeObj);
    }, {
      deep: true,
      immediate: true
    });
  },

  beforeUpdate() {
    this.initNav(this.nav);
  },

  methods: {
    initNode(nv, ov) {
      var obj = {};

      for (const _id in nv) {
        const _node = nv[_id];

        let _isFold = ov && ov[_id] ? ov[_id].isFold : true;

        obj[_id] = {
          isFold: _isFold,
          // 是否折叠
          editing: _node[this.editingName],
          // 是否正在编辑
          id: _node[this.idName],
          // 唯一编号
          title: _node[this.titleName],
          // 标题
          nav: _node[this.navName],
          // 当前节点的导航
          child: _node[this.childName],
          // 子节点
          element: _node[this.elementName] // 子元素

        };
      }

      return obj;
    },

    initNav(nav) {
      if (nav.length == 0) {
        return;
      }

      for (const _id in this.nodeObj) {
        const _node = this.nodeObj[_id];

        if (_node.nav.length <= nav.length && _.isEqual(_node.nav[_node.nav.length - 1], nav[_node.nav.length - 1])) {
          _node.isFold = false;
        }
      }
    },

    getNodeClass(node) {
      var nodeClass = ""; //是否在编辑

      if (node.editing) {
        nodeClass = nodeClass + 'bi bi-green-self ';
      } // 展开深度


      if (node.nav.length < this.unfoldDegree) {
        nodeClass = nodeClass + 'bi-folder';
        return nodeClass;
      } // 没有子集


      if (_.isEmpty(node.child) && _.isEmpty(node.element)) {
        nodeClass = nodeClass + 'bi-folder';
        return nodeClass;
      }

      if (node.isFold) {
        nodeClass = nodeClass + 'bi-folder-plus';
        return nodeClass;
      } else {
        nodeClass = nodeClass + 'bi-folder-minus';
        return nodeClass;
      }
    },

    getNodeFold(node) {
      // 展开深度
      if (node.nav.length < this.unfoldDegree) {
        node.isFold = false;
        return false;
      } // 没有子集


      if (_.isEmpty(node.child) && _.isEmpty(node.element)) {
        node.isFold = false;
        return false;
      }

      return node.isFold;
    },

    clickNode(node) {
      this.$emit('clickNode', node);
    },

    clickElement(node, element) {
      this.$emit('clickElement', node, element);
    },

    clickFold(node) {
      node.isFold = !node.isFold;
    }

  }
};