export default {
  components: {},
  props: {
    status: {
      type: String,
      default: () => {
        return '';
      }
    },
    dataInfo: {
      type: Object,
      default: () => {
        return {};
      }
    },
    languageType: {
      type: String,
      default: () => {
        return '';
      }
    },
    dpt: {
      type: Object,
      default: () => {
        return [];
      }
    }
  },

  data() {
    return {
      dirTimer: null
    };
  },

  watch: {},
  computed: {},
  methods: {
    getByStatus(status, dir, type) {
      for (let d of dir) {
        if (status == d.dictValue) {
          return d[`${type}`];
        }
      }
    },

    getClassByStatus(status) {
      if (status == '1') {
        return 'col_rred';
      } else if (status == '2') {
        return 'col_ggreen';
      }
    } // getTextByStatus(status){
    //     if (status == '1') {
    //         return 'Pending Additional Information'
    //     } else if (status == '2') {
    //         return 'Review Completed'
    //     }
    //     // if()
    // }


  },

  created() {
    setTimeout(() => {
      console.log(this.dpt, 'dataInfodataInfo');
    }, 2000); // this.dirTimer = setInterval(()=>{
    //     if(!this.damageReportDir.length){
    //     }
    // },500)
    // this.$nextTick(()=>{
    //     setTimeout(()=>{
    //         console.log(this.damageReportDir, 'statusstatusstatus');
    //     },9000)
    // })
    // console.log(this.languageType, 'statusstatusstatus');
  },

  mounted() {}

};