import { ElNotification } from 'element-plus';
export default {
  components: {},

  data() {
    return {
      tableData: []
    };
  },

  computed: {},

  mounted() {
    this.getPersonDataList();
  },

  methods: {
    handleSelectionChange(data) {
      this.$emit('change', data);
    },

    async getPersonDataList() {
      const res = await this.$axios.get('/javaplus/admin/personal/getCustomerList');

      if (res.data.code != 200) {
        ElNotification({
          title: '失败',
          message: res.data.msg,
          duration: 1500,
          type: 'error'
        });
        return;
      }

      this.tableData = res.data.data;
    }

  }
};