export default {
  props: {
    modelValue: {
      type: Array,

      default() {
        return [];
      }

    },
    disabled: {
      type: [Boolean, Number],

      default() {
        return false;
      }

    },
    placeholder: {
      type: [String],

      default() {
        return '';
      }

    },
    selectAll: {
      type: Boolean,

      default() {
        return false;
      }

    }
  },
  emits: ['update:modelValue', 'change'],

  data() {
    return {
      fold: true,
      optionArr: [],
      value: [],
      oldValue: [],
      showArr: [],
      checkAll: false,
      checkShow: false,
      optionsLeft: 0,
      optionsTop: 0
    };
  },

  watch: {
    fold: function (nv, ov) {
      if (!nv) {
        this.oldValue = _.cloneDeep(this.value);
        return;
      }

      if (!_.isEqual(this.value, this.oldValue)) {
        this.$emit('change', {
          nv: this.value,
          ov: this.oldValue
        });
        this.$emit('update:modelValue', this.value);
      }
    }
  },
  components: {},
  computed: {
    selectRef() {
      return 'select' + this.$.uid;
    },

    optionsRef() {
      return 'options' + this.$.uid;
    }

  },

  mounted() {
    this.$watch('modelValue', (nv, ov) => {
      if (_.isEqual(nv, this.value)) {
        return;
      }

      this.value = [];
      this.showArr = [];

      for (const _option of this.optionArr) {
        _option.optionSelected = false;
      }

      for (const _val of nv) {
        for (const _option of this.optionArr) {
          if (_.isEqual(_val, _option.value)) {
            _option.optionSelected = true;
            this.value.push(_option.value);
            this.showArr.push(_option.$el.innerText);
          }
        }
      }

      if (this.showArr.length) {
        this.checkShow = true;
      } else {
        this.checkShow = false;
      }
    }, {
      deep: true,
      immediate: true
    });
  },

  provide() {
    return {
      checkAll: this.checkAllRef,
      addOption: this.addOption,
      clickOptionSelect: this.clickOptionSelect
    };
  },

  methods: {
    addOption(it) {
      this.optionArr.push(it);
    },

    clickSelect() {
      if (this.disabled) {
        return;
      }

      this.fold = !this.fold;

      if (!this.fold) {
        this.$nextTick(() => {
          this.optionsPosition();
        });
      }
    },

    clickOptionSelect() {
      this.value.length = 0;
      this.showArr.length = 0;

      for (const _option of this.optionArr) {
        if (_option.optionSelected) {
          this.value.push(_option.value);
          this.showArr.push(_option.$el.innerText);
        }
      }

      if (this.showArr.length) {
        this.checkShow = true;
      } else {
        this.checkShow = false;
      }
    },

    clickCheckAll() {
      this.checkAll = !this.checkShow;
      this.value.length = 0;
      this.showArr.length = 0;

      for (const _option of this.optionArr) {
        _option.optionSelected = this.checkAll;

        if (_option.optionSelected) {
          this.value.push(_option.value);
          this.showArr.push(_option.$el.innerText);
        }
      }
    },

    optionsPosition() {
      var clientW = document.body.offsetWidth;
      var clientH = document.body.offsetHeight;
      var selectDom = this.$refs[this.selectRef];
      var optionsDom = this.$refs[this.optionsRef];
      optionsDom.style.maxHeight = clientH / 2 + 'px';
      var selectRect = selectDom.getBoundingClientRect();
      var optionsRect = optionsDom.getBoundingClientRect();
      var optionsX = 0;
      var optionsY = 0;
      var optionsW = Math.max(selectRect.width, optionsRect.width);
      optionsDom.style.minWidth = optionsW + 'px';

      if (selectRect.x < clientW / 2 && selectRect.y < clientH / 2) {
        //select处于左上，将options放到右下
        optionsX = selectRect.left;
        optionsY = selectRect.bottom;
      } else if (selectRect.x > clientW / 2 && selectRect.y < clientH / 2) {
        //select处于右上，将options放到左下
        optionsX = selectRect.right - optionsW;
        optionsY = selectRect.bottom;
      } else if (selectRect.x < clientW / 2 && selectRect.y > clientH / 2) {
        //select处于左下，将options放到右上
        optionsX = selectRect.left;
        optionsY = selectRect.top - optionsRect.height;
      } else {
        //select处于右下，将options放到左上
        optionsX = selectRect.right - optionsW;
        optionsY = selectRect.top - optionsRect.height;
      }

      this.optionsLeft = optionsX;
      this.optionsTop = optionsY;
    }

  }
};