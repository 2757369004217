import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, withModifiers as _withModifiers, Teleport as _Teleport } from "vue";
const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = {
  class: "text"
};
const _hoisted_3 = {
  class: "form-title"
};
const _hoisted_4 = {
  class: "d-flex"
};
const _hoisted_5 = {
  class: "form-title"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_icon = _resolveComponent("van-icon");

  const _component_el_option = _resolveComponent("el-option");

  const _component_el_select = _resolveComponent("el-select");

  const _component_van_field = _resolveComponent("van-field");

  const _component_van_button = _resolveComponent("van-button");

  const _component_el_alert = _resolveComponent("el-alert");

  return $data.modalShow ? (_openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(), _createBlock(_Teleport, {
    to: "#moblie-ticket"
  }, [_createElementVNode("section", {
    class: "application",
    onTouchmove: _cache[6] || (_cache[6] = _withModifiers(() => {}, ["prevent"])),
    onWheel: _cache[7] || (_cache[7] = _withModifiers(() => {}, ["prevent"]))
  }, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", null, _toDisplayString(_ctx._t('ticket')), 1), _createVNode(_component_van_icon, {
    onClick: _cache[0] || (_cache[0] = $event => $data.modalShow = false),
    name: "cross",
    size: "20"
  })]), _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx._t('questionType')), 1), _createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.nav, (_nav, _navIndex) => {
    return _openBlock(), _createBlock(_component_el_select, {
      key: _navIndex,
      class: "select-m",
      modelValue: $data.nav[_navIndex],
      "onUpdate:modelValue": $event => $data.nav[_navIndex] = $event,
      disabled: $options.disabled,
      "value-key": "id"
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.questionTypeArrayByParentId[$data.nav[_navIndex - 1] && $data.nav[_navIndex - 1].id || 0], _type => {
        return _openBlock(), _createBlock(_component_el_option, {
          key: _type.id,
          label: _type.title,
          value: _type
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_type.title), 1)]),
          _: 2
        }, 1032, ["label", "value"]);
      }), 128))]),
      _: 2
    }, 1032, ["modelValue", "onUpdate:modelValue", "disabled"]);
  }), 128)), _ctx.questionTypeArrayByParentId[$data.nav[$data.nav.length - 1] && $data.nav[$data.nav.length - 1].id || 0] ? (_openBlock(), _createBlock(_component_el_select, {
    key: 0,
    class: "select-m",
    modelValue: $data.newQuestionType,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.newQuestionType = $event),
    onChange: _cache[2] || (_cache[2] = $event => $options.changeAddNav()),
    "value-key": "id",
    disabled: $options.disabled
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.questionTypeArrayByParentId[$data.nav[$data.nav.length - 1] && $data.nav[$data.nav.length - 1].id || 0], _type => {
      return _openBlock(), _createBlock(_component_el_option, {
        key: _type.id,
        value: _type,
        label: _type.title
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_type.title), 1)]),
        _: 2
      }, 1032, ["value", "label"]);
    }), 128))]),
    _: 1
  }, 8, ["modelValue", "disabled"])) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx._t('question')), 1), _createVNode(_component_van_field, {
    class: "form-iput",
    modelValue: $data.questionTitle,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => $data.questionTitle = $event),
    rows: "3",
    autosize: "",
    type: "textarea",
    maxlength: "100",
    disabled: $options.disabled,
    placeholder: _ctx._t('inputQuestionHere'),
    "show-word-limit": ""
  }, null, 8, ["modelValue", "disabled", "placeholder"]), _createVNode(_component_van_button, {
    plain: "",
    class: "long-btn main-btn",
    onClick: _cache[4] || (_cache[4] = $event => $options.clickAddQuestionRecord()),
    loading: $data.btnLoading,
    type: "primary"
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx._t('submit')), 1)]),
    _: 1
  }, 8, ["loading"]), _createElementVNode("div", null, [_withDirectives(_createVNode(_component_el_alert, {
    type: "warning",
    description: $data.hint,
    onClose: _cache[5] || (_cache[5] = $event => $data.hint = ''),
    effect: "dark"
  }, null, 8, ["description"]), [[_vShow, $data.hint != '']])])], 32)]))])) : _createCommentVNode("", true);
}