import MoblieBanner from '../../../control/MoblieBanner.vue';
import MoblieHeader from '../../../control/MoblieHeader.vue';
import MoblieFooter from '../../../control/MoblieFooter.vue';
import Home from './Home.vue';
import AnswerQuestion from './AnswerQuestion.vue';
import MoblieCatalog from '../../Catalog.vue';
export default {
  data() {
    return {
      activeComponent: 'Home'
    };
  },

  components: {
    MoblieHeader,
    MoblieBanner,
    MoblieFooter,
    Home,
    AnswerQuestion,
    MoblieCatalog
  },
  computed: {
    activeComponent() {
      return this.$store.state.component;
    }

  },

  mounted() {
    this.$store.commit('changeBase', 'KnowledgeBase');
    this.$store.commit('changeUserType', 'user');
    const safeAreaInsetTop = window.matchMedia('(env(safe-area-inset-top))').matches ? parseInt(getComputedStyle(document.documentElement).getPropertyValue('--safe-area-inset-top'), 10) : 0;

    if (this.$refs.header) {
      this.$refs.header.style.top = `${safeAreaInsetTop}px`;
    }

    if (this.$refs.banner) {
      this.$refs.banner.style.top = `${safeAreaInsetTop + 3 * 16}px`;
    }
  },

  methods: {}
};