import AnswerQuestion from '../../../components/user/KnowledgeBase/AnswerQuestion2.vue';
import MoblieStepType from '../../../control/MoblieStepType.vue';
import MoblieTicket from '../../../control/MoblieTicket.vue';
export default {
  mixins: [AnswerQuestion],

  data() {
    return {};
  },

  components: {
    MoblieStepType,
    MoblieTicket
  },
  computed: {},

  mounted() {},

  methods: {}
};