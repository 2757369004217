import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  key: 0,
  style: {
    "width": "100%"
  }
};
const _hoisted_2 = {
  style: {
    "background-color": "#000",
    "width": "100%",
    "min-height": "3rem",
    "display": "flex",
    "justify-content": "center",
    "align-items": "center",
    "padding-top": "1rem"
  }
};
const _hoisted_3 = {
  class: "flex-end",
  style: {
    "width": "100%",
    "align-items": "flex-start",
    "position": "relative"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Catalog = _resolveComponent("Catalog");

  return $options.isAdminLogin ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
    style: {
      "color": "#fff",
      "font-size": "4.5vw",
      "font-weight": "bold",
      "cursor": "pointer"
    },
    onClick: _cache[0] || (_cache[0] = (...args) => $options.clickHome && $options.clickHome(...args))
  }, "Knowledge & Support Ticket System")]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", {
    style: _normalizeStyle([{
      "width": "20%",
      "height": "100%",
      "position": "absolute",
      "left": "0"
    }, {
      width: _ctx.isPC ? '20%' : '100%',
      position: _ctx.isPC ? 'absolute' : '',
      left: _ctx.isPC ? '0px' : ''
    }])
  }, [_createVNode(_component_Catalog)], 4), _createElementVNode("div", {
    style: _normalizeStyle([{
      "width": "80%",
      "justify-items": "flex-right"
    }, {
      width: _ctx.isPC ? '80%' : '100%'
    }])
  }, [(_openBlock(), _createBlock(_KeepAlive, null, [(_openBlock(), _createBlock(_resolveDynamicComponent($options.activeComponent)))], 1024))], 4)])])) : _createCommentVNode("", true);
}