import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-2f2a63e7"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  style: {
    "width": "100%",
    "height": "100%",
    "display": "flex",
    "flex-direction": "column",
    "justify-content": "center"
  }
};
const _hoisted_2 = {
  style: {
    "color": "#999",
    "font-size": "1.5rem"
  }
};
const _hoisted_3 = {
  style: {
    "color": "#999",
    "font-size": "1.5rem"
  }
};
const _hoisted_4 = {
  style: {
    "color": "#999",
    "font-size": "1.5rem"
  }
};
const _hoisted_5 = {
  style: {
    "color": "#999",
    "font-size": "1.5rem"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("span", _hoisted_2, _toDisplayString(_ctx._t("homeHintTit")), 1), _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx._t("homeHint1")), 1), _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx._t("homeHint2")), 1), _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx._t("homeHint3")), 1)]);
}