export default {
  props: ['value', 'selected'],
  emits: [],
  inject: ['addOption', 'clickOptionSelect', 'checkAll'],

  data() {
    return {
      optionSelected: false,
      index: -1
    };
  },

  watch: {
    checkAll: function (nv, ov) {
      this.optionSelected = nv;
    }
  },
  components: {},
  computed: {},

  beforeMount() {
    this.optionSelected = this.selected;
    this.addOption && this.addOption(this);
  },

  mounted() {
    this.$watch('optionSelected', (nv, ov) => {
      this.$el.selected = nv;
    }, {
      flush: 'post'
    });
  },

  methods: {
    clickOption() {
      this.optionSelected = !this.optionSelected;
      this.clickOptionSelect();
    }

  }
};