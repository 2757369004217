import { Quill } from 'vue-quill-editor'
import FileIcon from "@/assets/file.svg"
const Embed = Quill.import('blots/embed')
// const BlockEmbed = Quill.import('blots/block/embed')
const Link = Quill.import('formats/link')
const Icons = Quill.import('ui/icons')
//加载video-link的图标
Icons['file'] = FileIcon
import path from 'path'

class File extends Embed {
  static create(value) {
    let node = super.create(value);
    node.className='bi bi-file-earmark'
    node.setAttribute('href', this.sanitize(value));
    node.setAttribute('download', path.basename(value));
    node.setAttribute('contenteditable', false);
    return node;
  }

  static sanitize(url) {
    return Link.sanitize(url);
  }

  static value(domNode) {
    return {
      url:domNode.getAttribute('href'),
      text:domNode.innerText,
      download:domNode.getAttribute('download'),
    };
  }

  static formats(domNode) {
    return {
      url:domNode.getAttribute('href'),
      text:domNode.innerText,
      download:domNode.getAttribute('download'),
    };
  }

  format(name, value) {
    if(name=='fileName'){
      this.domNode.innerText=value
      this.domNode.setAttribute('download', value);
    }else{  
      super.format(name, value);
    }
  }

   update(mutations, context) {
    mutations.forEach((mutation) => {
      if (
        mutation.type === "childList" &&
        (Array.from(mutation.removedNodes).includes(this.leftGuard) ||
            Array.from(mutation.removedNodes).includes(this.rightGuard))
      ) {
        let tag;
        if (mutation.previousSibling) {
            tag = mutation.previousSibling.innerText;
        } else if (mutation.nextSibling) {
            tag = mutation.nextSibling.innerText;
        }
        if (tag) {
            super.replaceWith("text", tag);
        }
      }
    });
    super.update(mutations, context);
  }
}
File.className = 'ql-file';
File.blotName = 'file';
File.tagName = 'a';

export default File