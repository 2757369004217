import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-356a16bf"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = {
  class: "body"
};
const _hoisted_3 = {
  style: {
    "color": "#7e7f86",
    "margin-left": "10px"
  }
};
const _hoisted_4 = {
  style: {
    "display": "flex",
    "align-items": "center",
    "flex-wrap": "wrap"
  }
};
const _hoisted_5 = {
  style: {
    "display": "flex"
  }
};
const _hoisted_6 = {
  style: {
    "widt": "130px",
    "height": "130px",
    "display": "flex",
    "flex-direction": "column",
    "align-items": "center",
    "margin-bottom": "15px"
  }
};
const _hoisted_7 = {
  key: 0
};
const _hoisted_8 = ["src"];
const _hoisted_9 = {
  key: 1
};
const _hoisted_10 = {
  style: {
    "width": "120px",
    "margin-top": "6px",
    "height": "30px",
    "text-align": "center",
    "line-height": "28px",
    "color": "rgb(126,127,134)",
    "overflow": "hidden",
    "text-overflow": "ellipsis"
  }
};
const _hoisted_11 = ["onClick"];
const _hoisted_12 = {
  key: 0
};
const _hoisted_13 = ["src"];
const _hoisted_14 = {
  key: 1,
  class: "el-icon-plus avatar-uploader-icon"
};
const _hoisted_15 = {
  key: 1
};
const _hoisted_16 = ["src"];
const _hoisted_17 = {
  key: 1,
  class: "el-icon-plus avatar-uploader-icon"
};
const _hoisted_18 = {
  key: 1
};
const _hoisted_19 = {
  class: "body"
};
const _hoisted_20 = {
  style: {
    "color": "#7e7f86",
    "margin-left": "10px"
  }
};
const _hoisted_21 = {
  style: {
    "display": "flex",
    "justify-content": "flex-end"
  }
};
const _hoisted_22 = {
  style: {
    "display": "flex",
    "align-items": "center",
    "flex-wrap": "wrap"
  }
};
const _hoisted_23 = {
  style: {
    "display": "flex"
  }
};
const _hoisted_24 = {
  style: {
    "width": "130px",
    "height": "130px",
    "display": "flex",
    "flex-direction": "column",
    "align-items": "center",
    "margin-bottom": "15px"
  }
};
const _hoisted_25 = {
  key: 0
};
const _hoisted_26 = ["src"];
const _hoisted_27 = {
  key: 1
};
const _hoisted_28 = {
  style: {
    "width": "120px",
    "margin-top": "6px",
    "height": "30px",
    "text-align": "center",
    "line-height": "28px",
    "color": "rgb(126,127,134)",
    "overflow": "hidden",
    "text-overflow": "ellipsis",
    "white-space": "nowrap"
  }
};
const _hoisted_29 = ["onClick"];
const _hoisted_30 = {
  key: 0
};
const _hoisted_31 = ["src"];
const _hoisted_32 = {
  key: 1,
  class: "el-icon-plus avatar-uploader-icon"
};
const _hoisted_33 = {
  key: 1
};
const _hoisted_34 = ["src"];
const _hoisted_35 = {
  key: 1,
  class: "el-icon-plus avatar-uploader-icon"
};
const _hoisted_36 = {
  key: 0
};
const _hoisted_37 = {
  key: 0,
  style: {
    "margin-top": "19px"
  }
};
const _hoisted_38 = {
  style: {
    "width": "120px",
    "height": "28px",
    "line-height": "28px",
    "text-align": "center",
    "color": "rgb(126,127,134)"
  }
};
const _hoisted_39 = {
  key: 1,
  style: {
    "margin-top": "16px"
  }
};
const _hoisted_40 = {
  style: {
    "width": "120px",
    "height": "28px",
    "line-height": "28px",
    "text-align": "center",
    "color": "rgb(126,127,134)"
  }
};
const _hoisted_41 = {
  style: {
    "width": "90%",
    "margin": "0 auto"
  }
};
const _hoisted_42 = {
  key: 0,
  style: {
    "margin": "20px 0 0 10px",
    "color": "#D9001B",
    "display": "flex",
    "width": "100%",
    "margin-top": "10px"
  }
};
const _hoisted_43 = {
  style: {
    "margin-left": "10px",
    "width": "60vw",
    "word-wrap": "break-word"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_image = _resolveComponent("el-image");

  const _component_el_tooltip = _resolveComponent("el-tooltip");

  const _component_el_upload = _resolveComponent("el-upload");

  const _component_el_icon_Delete = _resolveComponent("el-icon-Delete");

  const _component_el_icon = _resolveComponent("el-icon");

  const _component_templateStatusShow = _resolveComponent("templateStatusShow");

  const _component_el_form = _resolveComponent("el-form");

  const _component_el_image_viewer = _resolveComponent("el-image-viewer");

  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", null, [($props.dpt.status == '1' || $props.dpt.status == '3') && !$props.userIsView ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
    class: _normalizeClass($data.dataInfo.isEssential ? 'star' : ''),
    style: {
      "font-weight": "bold",
      "margin-bottom": "20px",
      "font-size": "14px"
    }
  }, [_createTextVNode(_toDisplayString($props.listIndex) + "." + _toDisplayString($props.isVideo ? $props.damageRTextL.uploadVideo : $props.damageRTextL.uploadImage) + " ", 1), _createElementVNode("span", _hoisted_3, _toDisplayString($props.isVideo ? $props.damageRTextL.uploadVideoExample : $props.damageRTextL.uploadImageExample), 1)], 2), _createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.dataInfo.itemList, (item, index) => {
    return _openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [$props.isVideo ? (_openBlock(), _createElementBlock("div", _hoisted_7, [item.url ? (_openBlock(), _createElementBlock("video", {
      key: 0,
      src: $options.replacePath(item.url),
      class: "avatar",
      autoplay: "",
      controls: "",
      style: {}
    }, null, 8, _hoisted_8)) : _createCommentVNode("", true)])) : (_openBlock(), _createElementBlock("div", _hoisted_9, [item.url ? (_openBlock(), _createBlock(_component_el_image, {
      key: 0,
      class: "avatar",
      src: $options.replacePath(item.url),
      "preview-src-list": $data.imgArrayE,
      style: {},
      fit: "contain"
    }, null, 8, ["src", "preview-src-list"])) : _createCommentVNode("", true)])), _createVNode(_component_el_tooltip, {
      class: "item",
      effect: "dark",
      content: item.name,
      placement: "top"
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_10, _toDisplayString(item.name), 1)]),
      _: 2
    }, 1032, ["content"])]), _createElementVNode("div", {
      style: {
        "width": "130px",
        "height": "130px",
        "display": "flex",
        "flex-direction": "column",
        "align-items": "center",
        "margin-bottom": "15px"
      },
      onClick: $event => $options.clickShowImage(index)
    }, [_createVNode(_component_el_upload, {
      class: "avatar-uploader",
      action: "/javaplus/user/file/upload",
      "show-file-list": false,
      "on-success": res => {
        $options.handleAvatarSuccess(res, index);
      },
      "before-upload": $options.beforeAvatarUpload
    }, {
      default: _withCtx(() => [$props.isVideo ? (_openBlock(), _createElementBlock("div", _hoisted_12, [$data.itemListCopy[index].url ? (_openBlock(), _createElementBlock("video", {
        key: 0,
        src: $data.itemListCopy[index].url,
        class: "avatar",
        autoplay: "",
        controls: ""
      }, null, 8, _hoisted_13)) : (_openBlock(), _createElementBlock("i", _hoisted_14))])) : (_openBlock(), _createElementBlock("div", _hoisted_15, [$data.itemListCopy[index].url ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        src: $data.itemListCopy[index].url,
        class: "avatar",
        style: {
          "object-fit": "contain"
        }
      }, null, 8, _hoisted_16)) : (_openBlock(), _createElementBlock("i", _hoisted_17))]))]),
      _: 2
    }, 1032, ["on-success", "before-upload"]), $data.itemListCopy[index].url ? (_openBlock(), _createBlock(_component_el_icon, {
      key: 0,
      size: 20,
      class: _normalizeClass($data.itemListCopy[index].url ? 'existImg' : ''),
      style: {
        "cursor": "pointer",
        "position": "relative",
        "bottom": "90px",
        "left": "40px"
      },
      onClick: $event => $options.clearImg(index)
    }, {
      default: _withCtx(() => [_createVNode(_component_el_icon_Delete)]),
      _: 2
    }, 1032, ["class", "onClick"])) : _createCommentVNode("", true)], 8, _hoisted_11)]);
  }), 256))])])])) : (_openBlock(), _createElementBlock("div", _hoisted_18, [_createElementVNode("div", _hoisted_19, [_createElementVNode("div", {
    class: _normalizeClass($data.dataInfo.isEssential ? 'star' : ''),
    style: {
      "font-weight": "bold",
      "margin-bottom": "20px",
      "font-size": "14px",
      "word-wrap": "break-word",
      "max-width": "90vw"
    }
  }, [_createTextVNode(_toDisplayString($props.listIndex) + "." + _toDisplayString($props.isVideo ? $props.damageRTextL.uploadVideo : $props.damageRTextL.uploadImage), 1), _createElementVNode("span", _hoisted_20, _toDisplayString($props.isVideo ? $props.damageRTextL.uploadVideoExample : $props.damageRTextL.uploadImageExample), 1)], 2), _createElementVNode("div", _hoisted_21, [_createVNode(_component_templateStatusShow, {
    status: $props.dpt.status,
    dataInfo: $data.dataInfo,
    languageType: $props.dpt.languageType,
    dpt: $props.dpt
  }, null, 8, ["status", "dataInfo", "languageType", "dpt"])]), _createElementVNode("div", _hoisted_22, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.dataInfo.itemList, (item, index) => {
    return _openBlock(), _createElementBlock("div", _hoisted_23, [_createElementVNode("div", _hoisted_24, [$props.isVideo ? (_openBlock(), _createElementBlock("div", _hoisted_25, [item.url ? (_openBlock(), _createElementBlock("video", {
      key: 0,
      src: $options.replacePath(item.url),
      class: "avatar",
      autoplay: "",
      controls: "",
      style: {}
    }, null, 8, _hoisted_26)) : _createCommentVNode("", true)])) : (_openBlock(), _createElementBlock("div", _hoisted_27, [item.url ? (_openBlock(), _createBlock(_component_el_image, {
      key: 0,
      class: "avatar",
      src: $options.replacePath(item.url),
      "preview-src-list": $data.imgArrayE,
      style: {},
      fit: "contain"
    }, null, 8, ["src", "preview-src-list"])) : _createCommentVNode("", true)])), _createVNode(_component_el_form, {
      style: {
        "margin-top": "13px"
      }
    }, {
      default: _withCtx(() => [_createVNode(_component_el_tooltip, {
        class: "item",
        effect: "dark",
        content: item.name,
        placement: "top"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_28, _toDisplayString(item.name), 1)]),
        _: 2
      }, 1032, ["content"])]),
      _: 2
    }, 1024)]), _createElementVNode("div", {
      style: {
        "width": "130px",
        "height": "130px",
        "display": "flex",
        "flex-direction": "column",
        "align-items": "center",
        "margin-bottom": "15px"
      },
      onClick: $event => $options.clickShowImage(index)
    }, [_createVNode(_component_el_upload, {
      class: "avatar-uploader",
      action: "/javaplus/user/file/upload",
      "show-file-list": false,
      disabled: ""
    }, {
      default: _withCtx(() => [$props.isVideo ? (_openBlock(), _createElementBlock("div", _hoisted_30, [$data.itemListCopy[index].url ? (_openBlock(), _createElementBlock("video", {
        key: 0,
        src: $data.itemListCopy[index].url,
        class: "avatar",
        autoplay: "",
        controls: ""
      }, null, 8, _hoisted_31)) : (_openBlock(), _createElementBlock("i", _hoisted_32))])) : (_openBlock(), _createElementBlock("div", _hoisted_33, [$data.itemListCopy[index].url ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        src: $data.itemListCopy[index].url,
        class: "avatar",
        style: {
          "object-fit": "contain"
        }
      }, null, 8, _hoisted_34)) : (_openBlock(), _createElementBlock("i", _hoisted_35))]))]),
      _: 2
    }, 1024), $props.dpt.status ? (_openBlock(), _createElementBlock("div", _hoisted_36, [!$data.itemListCopy[index].url ? (_openBlock(), _createElementBlock("div", _hoisted_37, [_createElementVNode("div", _hoisted_38, _toDisplayString($props.damageRTextL.userHasNotUploaded), 1)])) : (_openBlock(), _createElementBlock("div", _hoisted_39, [_createElementVNode("div", _hoisted_40, _toDisplayString($props.damageRTextL.uploaded), 1)]))])) : _createCommentVNode("", true)], 8, _hoisted_29)]);
  }), 256))])]), _createElementVNode("div", _hoisted_41, [$data.dataInfo.answerVo?.checkStatus === '1' && $props.dpt.status != '2' ? (_openBlock(), _createElementBlock("div", _hoisted_42, [_createElementVNode("div", null, _toDisplayString($props.damageRTextL.additionalRemarks) + ": ", 1), _createElementVNode("div", _hoisted_43, _toDisplayString($data.dataInfo.answerVo.checkRemark), 1)])) : _createCommentVNode("", true)]), $data.imgViewerShow && $props.dpt.status != "1" ? (_openBlock(), _createBlock(_component_el_image_viewer, {
    key: 0,
    onClose: _cache[0] || (_cache[0] = $event => $data.imgViewerShow = false),
    infinite: false,
    "url-list": $data.imgArray,
    "initial-index": $data.imgIndex
  }, null, 8, ["url-list", "initial-index"])) : _createCommentVNode("", true)]))])]);
}