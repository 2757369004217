import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  style: {
    "width": "100%"
  }
};
const _hoisted_2 = {
  style: {
    "display": "flex",
    "align-items": "center"
  }
};
const _hoisted_3 = {
  style: {
    "margin-left": "10px"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_table_column = _resolveComponent("el-table-column");

  const _component_el_icon_UserFilled = _resolveComponent("el-icon-UserFilled");

  const _component_el_icon = _resolveComponent("el-icon");

  const _component_el_table = _resolveComponent("el-table");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_table, {
    ref: "multipleTableRef",
    stripe: "",
    "highlight-current-row": "",
    "tooltip-effect": "dark",
    data: $data.tableData,
    style: {
      "width": "100%"
    },
    onCurrentChange: $options.handleSelectionChange
  }, {
    default: _withCtx(() => [_createVNode(_component_el_table_column, {
      type: "index",
      width: "40"
    }), _createVNode(_component_el_table_column, {
      property: "name",
      label: "Name"
    }, {
      default: _withCtx(scope => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_icon, null, {
        default: _withCtx(() => [_createVNode(_component_el_icon_UserFilled, {
          color: "#409EFF"
        })]),
        _: 1
      }), _createElementVNode("span", _hoisted_3, _toDisplayString(scope.row.name), 1)])]),
      _: 1
    })]),
    _: 1
  }, 8, ["data", "onCurrentChange"])]);
}