import FilterPanel from '../FilterPanel.vue';
import { mapGetters, mapState } from 'vuex';
export default {
  components: {
    FilterPanel
  },
  props: {},

  data() {
    return {
      options: [{
        key: 'is_solved',
        label: this._t('resolved')
      }, {
        key: 'no_reply',
        label: this._t('noReply')
      }],
      selected: []
    };
  },

  computed: { ...mapState({
      user: 'user',
      userType: 'userType'
    }),
    ...mapGetters({
      getFilterQuestionRecordByUser: 'getFilterQuestionRecordByUser'
    }),

    changeValue() {
      var value = {};
      value.selected = _.cloneDeep(this.selected);
      return value;
    }

  },
  watch: {
    selected: {
      handler(nv, ov) {
        this.$emit('change', this.changeValue);
      },

      immediate: true
    },
    getFilterQuestionRecordByUser: {
      handler(nv, ov) {
        this.selected = [];

        for (var option of this.options) {
          if (this.getFilterQuestionRecordByUser[option.key]) {
            this.selected.push(option);
          }
        }
      }

    }
  },

  mounted() {},

  methods: {
    updateSelected(checked) {
      var addArray = [];
      var changeArray = [];
      var deleteArray = [];
      var filter = {};

      for (var option of this.options) {
        filter[option.key] = 0;
      }

      for (var selected of this.selected) {
        filter[selected.key] = 1;
      }

      if (this.getFilterQuestionRecordByUser && this.getFilterQuestionRecordByUser.id) {
        filter.id = this.getFilterQuestionRecordByUser.id;
        changeArray.push(filter);
      } else {
        filter.person_id = this.user.id;
        addArray.push(filter);
      }

      this.$store.dispatch('updateRemoteTablePart', {
        table: 'filterQuestionRecord',
        addArray,
        changeArray
      });
    }

  }
};