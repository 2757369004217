import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-71ef1524"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  key: 0,
  style: {
    "width": "176px"
  }
};
const _hoisted_2 = {
  key: 0,
  class: "col_bblue",
  style: {
    "text-align": "right"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", null, [$props.status && $props.status != '1' && $props.status != '5' ? (_openBlock(), _createElementBlock("div", _hoisted_1, [$props.status == '2' ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString($props.dpt.statusName), 1)) : (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: _normalizeClass($options.getClassByStatus($props.dataInfo.answerVo.checkStatus)),
    style: {
      "text-align": "right"
    }
  }, _toDisplayString($props.dataInfo.answerVo.checkStatusName), 3))])) : _createCommentVNode("", true)]);
}