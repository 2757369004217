import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_FilterPanel = _resolveComponent("FilterPanel");

  const _component_el_row = _resolveComponent("el-row");

  return _openBlock(), _createBlock(_component_el_row, {
    type: "flex",
    justify: "start",
    align: "top",
    style: {
      "padding": "0 8px"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_FilterPanel, {
      selected: $data.selected,
      "onUpdate:selected": [_cache[0] || (_cache[0] = $event => $data.selected = $event), $options.updateSelected],
      options: $data.options,
      label: "label",
      title: _ctx._t('mixed')
    }, null, 8, ["selected", "options", "title", "onUpdate:selected"])]),
    _: 1
  });
}